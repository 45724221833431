import { Adam, TokenInfoResponse } from '@adam-vault/adam-sdk';
import { useMemo } from 'react';
import useTokenSettings from 'hooks/useTokenSettings';
import { useAdamMemo } from './useAdam';

type ReturnType = {
  depositTokens: TokenInfoResponse[];
  loading: boolean;
};

export default function useDaoDepositTokens(daoAddress: string): ReturnType {
  const { supportedTokens } = useTokenSettings();
  const [depositTokenAddresses, { isLoading }] = useAdamMemo(
    async (adam: Adam) => {
      const dao = adam.loadDao(daoAddress);
      return dao.depositTokens();
    },
    [daoAddress],
    undefined
  );

  const depositTokens = useMemo(() => {
    if (!depositTokenAddresses || !supportedTokens) {
      return [];
    }

    return depositTokenAddresses
      .map((tokenAddress) =>
        supportedTokens.find((token) => token.address.toLowerCase() === tokenAddress.toLowerCase())
      )
      .filter((token) => !!token) as TokenInfoResponse[];
  }, [depositTokenAddresses, supportedTokens]);

  return {
    depositTokens,
    loading: isLoading,
  };
}
