import { DevTool } from '@hookform/devtools';
import { useEffect } from 'react';
import { useNavigate, generatePath } from 'react-router-dom';
import * as yup from 'yup';
import Button from 'components/Button';
import Form from 'components/form/Form';
import TextInput from 'components/form/TextInput';
import Flex from 'components/layout/Flex';
import Subtitle from 'components/layout/SubTitle';
import Modal from 'components/Modal';
import { GlobalModal } from 'constants/globalModal';
import PATHS from 'constants/paths';
import useForm from 'hooks/useForm';
import useGlobalModal from 'hooks/useGlobalModal';
import { ethereumAddress } from 'utils/yup/address';

interface FormData {
  daoAddress: string;
}

export default function JoinDaoModal(): JSX.Element {
  const navigate = useNavigate();

  const { isModalOpen, closeModal } = useGlobalModal(GlobalModal.JOIN_DAO);

  const schema = yup.object({
    daoAddress: ethereumAddress().required(),
  });

  const {
    control,
    resetField,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    schema,
  });

  useEffect(() => {
    // Reset field every times when the modal is open
    if (!isModalOpen) {
      return;
    }

    resetField('daoAddress');
  }, [isModalOpen, resetField]);

  const onSubmit = (data: FormData): void => {
    navigate(generatePath(`${PATHS.DAO.JOIN_DAO}`, { daoAddress: data.daoAddress }));
    closeModal();
  };

  return (
    <Modal isOpen={isModalOpen} onRequestClose={closeModal}>
      <Form flexDirection="column" onSubmit={handleSubmit(onSubmit)}>
        <Subtitle>Please enter the address of Vault you want to join</Subtitle>
        <TextInput control={control} name="daoAddress" error={errors.daoAddress?.message} />
        <Flex justifyContent="center" marginTop={5}>
          <Button type="submit" variant="primary">
            Submit
          </Button>
        </Flex>
        <DevTool control={control} />
      </Form>
    </Modal>
  );
}
