import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useWeb3 from 'hooks/useWeb3';
import useMemberProfiles from 'pages/MemberListing/hooks/useMemberProfiles';
import { toEllipsisAddress, toEllipsisName } from 'utils/formatUtils';
import { getEthereumName } from 'utils/web3Utils';

const getAddressDisplayText = (address: string, isEllipsis: boolean): string =>
  isEllipsis ? toEllipsisAddress(address) : address;

const getNameDisplayText = (name: string | null, isEllipsis: boolean): string => {
  if (!name) {
    return '';
  }

  return isEllipsis ? toEllipsisName(name) : name;
};

type Props = {
  address: string;
  isEllipsis: boolean;
  isAliasShown: boolean;
};

type ReturnType = {
  alias: string;
  addressOrName: string;
  isLoading: boolean;
};

export default function useAddressDisplay({ address, isEllipsis, isAliasShown }: Props): ReturnType {
  const [isFetchingEthereumAddress, setIsFetchingEthereumAddress] = useState(true);
  const [alias, setAlias] = useState('');
  const [addressOrName, setAddressOrName] = useState('');
  const { provider } = useWeb3();
  const { daoAddress } = useParams();
  const { memberProfiles, isLoading: isLoadingMemberProfile } = useMemberProfiles(
    daoAddress || '',
    [address],
    isAliasShown
  );
  const fetchEthereumAddress = useCallback(
    async (target: string): Promise<void> => {
      setIsFetchingEthereumAddress(true);

      const result: string | null = provider ? await getEthereumName(target, provider) : null;
      if (result) {
        setAddressOrName(getNameDisplayText(result, isEllipsis));
      } else if (target) {
        setAddressOrName(getAddressDisplayText(target, isEllipsis));
      }
      setIsFetchingEthereumAddress(false);
    },
    [isEllipsis, provider]
  );

  useEffect(() => {
    fetchEthereumAddress(address);
  }, [address, fetchEthereumAddress]);

  useEffect(() => {
    if (!memberProfiles.length) {
      return;
    }

    const targetProfile = memberProfiles.find(({ publicAddress }) => publicAddress === address);
    const aliasDisplay = targetProfile ? getNameDisplayText(targetProfile.name, isEllipsis) : '';

    setAlias(aliasDisplay);
  }, [address, isEllipsis, memberProfiles]);

  return { alias, addressOrName, isLoading: isFetchingEthereumAddress || isLoadingMemberProfile };
}
