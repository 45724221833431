import { BudgetApprovalType, TransactionType } from '@adam-vault/adam-sdk';

const SWAP_BUDGET_TYPES = [BudgetApprovalType.Uniswap, BudgetApprovalType.LiquidUniswap];
const SWAP_TRANSACTION_TYPES = [TransactionType.SWAP, TransactionType.SWAP_LIQUID];
const OUTFLOW_TRANSACTION_TYPES = [
  TransactionType.OUTFLOW,
  TransactionType.OUTFLOW_LIQUID,
  TransactionType.OUTFLOW_721,
];

export const isSwapTransaction = (transactionType?: TransactionType): boolean => {
  if (!transactionType) {
    return false;
  }

  return SWAP_TRANSACTION_TYPES.includes(transactionType);
};

export const isSwapBudget = (budgetType?: BudgetApprovalType): boolean => {
  if (!budgetType) {
    return false;
  }

  return SWAP_BUDGET_TYPES.includes(budgetType);
};

export const isOutflowTransaction = (transactionType?: TransactionType): boolean => {
  if (!transactionType) {
    return false;
  }

  return OUTFLOW_TRANSACTION_TYPES.includes(transactionType);
};

export const isEmptyObject = (value: Record<string, unknown>): boolean => !value || Object.keys(value).length === 0;

type Enum<E> = Record<keyof E, number | string> & { [k: number]: string };
export function isValueInEnum<E extends Enum<E>>(enumDef: E, value: string | null | undefined): boolean {
  if (value === undefined || value === null) {
    return false;
  }

  return Object.values(enumDef).includes(value);
}
