import styled from 'styled-components';
import Box, { Props as BoxProps } from './Box';

export interface Props extends BoxProps {}

const Flex = styled(Box)`
  display: flex;
`;

export default Flex;
