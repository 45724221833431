import { atom } from 'jotai';
import isNil from 'lodash/isNil';

const isModalOpenAtom = atom(false);

const connectModalAtom = atom(
  (get) => ({
    isModalOpen: get(isModalOpenAtom),
  }),
  async (get, set, arg: any) => {
    const { isModalOpen } = arg;
    if (!isNil(isModalOpen)) {
      set(isModalOpenAtom, isModalOpen);
    }
  }
);

export default connectModalAtom;
