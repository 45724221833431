import communitySvg from 'assets/images/dao_type_community.svg';
import customisedSvg from 'assets/images/dao_type_customised.svg';
import etopSvg from 'assets/images/dao_type_ETOP.svg';
import investmentSvg from 'assets/images/dao_type_investment.svg';
import membershipClubSvg from 'assets/images/dao_type_membership_club.svg';
import privateClubSvg from 'assets/images/dao_type_private_club.svg';
import quickStartSvg from 'assets/images/dao_type_quick_start.svg';
import saftSvg from 'assets/images/dao_type_SAFT.svg';
import smallTeamSvg from 'assets/images/dao_type_small_team.svg';

export enum DaoPanelViewState {
  HIDDEN,
  LIST,
  INITIAL,
  COLLAPSE,
}

export enum MemberLimitType {
  UNLIMITED = 'Unlimited',
  LIMITED = 'Limited',
}

export enum SupportedDaoType {
  QUICK_START = 'quickStart ',
  INVESTMENT = 'investment',
  PRIVATE_CLUB = 'privateClub',
  COMMUNITY = 'community',
  SAFT = 'saft',
  ETOP = 'etop',
  SMALL_TEAM = 'smallTeam',
  MEMBERSHIP_CLUB = 'membershipClub',
  CUSTOMISED = 'customised',
}

export enum ReviewFormSection {
  BASIC_INFO = 'basicInfo',
  MEMBER_TOKEN = 'memberToken',
  ENTRY = 'entry',
  DEPOSIT = 'deposit',
  VOTING = 'voting',
}

export const DAO_TYPE = {
  [SupportedDaoType.QUICK_START]: {
    type: SupportedDaoType.QUICK_START,
    label: 'Quick Start',
    description: 'Create a Vault in just a few clicks.',
    icon: quickStartSvg,
    suitableForList: [
      { icon: 'ico_hot_feature', label: 'Simple Setup' },
      { icon: 'ico_user_friendly', label: 'Beginner-friendly' },
    ],
  },
  [SupportedDaoType.INVESTMENT]: {
    type: SupportedDaoType.INVESTMENT,
    label: 'Investment',
    description: 'Manage discretionary investment accounts at institutional standards.',
    icon: investmentSvg,
    suitableForList: [
      { icon: 'ico_connection', label: 'DeFi Connections' },
      { icon: 'ico_lock_up', label: 'Discretionary Account' },
    ],
  },
  [SupportedDaoType.SMALL_TEAM]: {
    type: SupportedDaoType.SMALL_TEAM,
    label: 'Small Team',
    description: 'Flat structure teams with less delegation of power and more individual engagement.',
    icon: smallTeamSvg,
    suitableForList: [
      { icon: 'ico_vault_members', label: '2-10 members' },
      { icon: 'ico_vote_method', label: '1 Member 1 Vote' },
    ],
  },
  [SupportedDaoType.PRIVATE_CLUB]: {
    type: SupportedDaoType.PRIVATE_CLUB,
    label: 'Private Club',
    description: 'Exclusive communities where decisions start to be decentralized and delegated.',
    icon: privateClubSvg,
    suitableForList: [
      { icon: 'ico_vault_members', label: '2-200 members' },
      { icon: 'ico_entry_token', label: 'Entry by Token' },
    ],
  },
  [SupportedDaoType.MEMBERSHIP_CLUB]: {
    type: SupportedDaoType.MEMBERSHIP_CLUB,
    label: 'Membership Club',
    description: 'Clubs where most members are silent but have rights to participate in major decisions.',
    icon: membershipClubSvg,
    suitableForList: [
      { icon: 'ico_vault_members', label: '200-1000 members' },
      { icon: 'ico_entry_token', label: 'Entry by Token' },
    ],
  },
  [SupportedDaoType.COMMUNITY]: {
    type: SupportedDaoType.COMMUNITY,
    label: 'Community',
    description: 'Larger communities where decisions are decentralized and autonomous.',
    icon: communitySvg,
    suitableForList: [
      { icon: 'ico_vault_members', label: '1000+ members' },
      { icon: 'ico_vote_method', label: '1 Member Token 1 Vote' },
    ],
  },
  [SupportedDaoType.SAFT]: {
    type: SupportedDaoType.SAFT,
    label: 'SAFT',
    description: 'Projects preparing for private token sale through Simple Agreement of Future Token.',
    icon: saftSvg,
    suitableForList: [
      { icon: 'ico_treasury_vault', label: 'Treasury' },
      { icon: 'ico_lock_up', label: '1 Member Token 1 Vote' },
    ],
  },
  [SupportedDaoType.ETOP]: {
    type: SupportedDaoType.ETOP,
    label: 'ETOP',
    description: 'Special purpose Vault for assigning Employee Token Ownership Plan to teams.',
    icon: etopSvg,
    suitableForList: [
      { icon: 'ico_treasury_vault', label: 'Treasury' },
      { icon: 'ico_lock_up', label: 'Long Lock-up' },
    ],
  },
  [SupportedDaoType.CUSTOMISED]: {
    type: SupportedDaoType.CUSTOMISED,
    label: 'Customised',
    description: 'Tailor-made a Vault by setting parameters one-by-one.',
    icon: customisedSvg,
    suitableForList: [
      { icon: 'ico_all_features', label: 'All Features' },
      { icon: 'ico_customisation', label: 'Full Customisation' },
    ],
  },
};
