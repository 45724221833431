import { Dao, Token } from '@adam-vault/adam-sdk';
import { BigNumber } from 'ethers';
import { atom } from 'jotai';
import { DaoPanelViewState } from 'constants/daoParam';
import { SupportedFeatureType } from 'constants/supportedFeature';

export const isFetchingCurrentDaoAtom = atom<boolean>(false);
export const currentDaoAtom = atom<Dao | null>(null);
export const currentDaoBaseCurrencyAtom = atom<Token | null | undefined>(undefined);
export const currentDaoSupportedFeatureAtom = atom<SupportedFeatureType | null>(null);
export const currentDaoTotalPriceBNAtom = atom<BigNumber | null | undefined>(null);
export const currentDaoTotalPriceInTreasuryBNAtom = atom<BigNumber | null | undefined>(null);
export const currentDaoTotalPriceInLiquidPoolBNAtom = atom<BigNumber | null | undefined>(null);
export const doesCurrentDaoExistAtom = atom<boolean | null>(null);
export const currentDaoTotalPriceAtom = atom<string>('');
export const isCurrentEoaACurrentDaoMemberAtom = atom<boolean | null>(null);

export const daoUrlAddressAtom = atom<string>('');
export const daoPanelViewStateAtom = atom<DaoPanelViewState>(DaoPanelViewState.INITIAL);
