import { Wallet as WalletConstants } from 'adam-frontend-shared';
import { ethers } from 'ethers';
import { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import Button from 'components/Button';
import Description from 'components/layout/Description';
import Modal from 'components/Modal';
import WalletButton from 'components/WalletButton';
import useTokenSettings from 'hooks/useTokenSettings';
import useWeb3 from 'hooks/useWeb3';

import browserUtils from 'utils/browserUtils';
import { toTokenBalanceDisplay } from 'utils/formatUtils';

export interface Props extends ReactModal.Props {
  onRequestClose: () => void;
}

const { Wallet } = WalletConstants;

const Content = styled.div`
  width: 500px;
`;

const StyledDescription = styled(Description)`
  font-size: 16px;
  margin-bottom: 12px;
  color: ${({ theme: { color } }): string => color.fontPrimary};
`;

const StyledWalletButton = styled(WalletButton)`
  width: 100%;
  height: 52px;
  background-color: ${({ theme: { color } }): string => color.oldDarkGray3};
`;

const DisconnectButton = styled(Button)`
  width: 100%;
  height: 52px;
  color: ${({ theme: { color } }): string => color.secondary};
`;

const Row = styled.div`
  display: flex;
  gap: 8px;
`;

const Column = styled.div`
  width: 50%;
`;

export default function EoaInfoModal(props: Props): JSX.Element {
  const { address, disconnectEOA, provider, walletType } = useWeb3();
  const { nativeToken } = useTokenSettings();
  const [balance, setBalance] = useState('0');
  const [isCopiedModalOpen, setIsCopiedModalOpen] = useState<boolean>(false);

  useEffect(() => {
    async function updateBalance(): Promise<void> {
      if (!provider || !address) {
        return;
      }
      setBalance(ethers.utils.formatEther(await provider.getBalance(address)));
    }

    updateBalance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, provider, props.isOpen]);

  const copyAddress = (): void => {
    if (!address) {
      return;
    }

    browserUtils.copyToClipboard(address);
    setIsCopiedModalOpen(true);
  };

  const disconnect = (): void => {
    props.onRequestClose();
    // wait for modal closing animation
    setTimeout(() => disconnectEOA(), 400);
  };

  const closeCopiedModal = (): void => setIsCopiedModalOpen(false);

  return (
    <Modal {...props} contentLabel="Connected Wallet">
      <Content>
        {walletType && (
          <>
            <StyledDescription>
              {Wallet[walletType].name} Balance{' '}
              <b>
                {toTokenBalanceDisplay(balance)} {nativeToken?.symbol || ''}
              </b>
            </StyledDescription>
            <Row>
              <Column>
                <StyledWalletButton address={address} walletType={walletType} onClick={copyAddress} size={24} />
              </Column>
              <Column>
                <DisconnectButton variant="text" onClick={disconnect}>
                  Disconnect
                </DisconnectButton>
              </Column>
            </Row>
          </>
        )}
      </Content>
      <Modal
        isOpen={isCopiedModalOpen}
        contentLabel="Address Copied"
        onRequestClose={closeCopiedModal}
        onConfirm={closeCopiedModal}
      >
        The address is copied to clipboard!
      </Modal>
    </Modal>
  );
}
