import Loading from 'components/Loading';
import useCurrentDao from 'hooks/useCurrentDao';

type Props = {
  children: JSX.Element;
};

export default function OnlyDaoMember({ children }: Props): JSX.Element | null {
  const { isMemberStateLoading: loading, isCurrentEoaAMember: isDaoMember } = useCurrentDao();

  if (loading) {
    return <Loading isFullWidth />;
  }

  return isDaoMember ? children : null;
}
