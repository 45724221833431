import { Adam, TokenInfoResponse, Network, networkConstant } from '@adam-vault/adam-sdk';
import { Chain } from 'adam-frontend-shared';
import { useAtom } from 'jotai';
import { useCallback, useEffect } from 'react';
import {
  DEFAULT_BASE_CURRENCY_SYMBOL,
  ReapTokenSymbol,
  StableCoinSymbolWithoutNative,
  USDC_BASE_CURRENCY_DISPLAY,
} from 'constants/token';
import { useSessionPreferences } from 'hooks/useSessionPreferences';
import useWeb3 from 'hooks/useWeb3';
import { adamAtom, tokenSettingsAtom } from 'store/adamAtom';

export function AdamManager(): null {
  const [adam, setAdam] = useAtom(adamAtom);
  const [, setTokenSettings] = useAtom(tokenSettingsAtom);
  const { signer, provider } = useWeb3();
  const { platformSelectedChainId } = useSessionPreferences();

  // Update Adam if signer/provider changed
  useEffect(() => {
    const { network, envParam } = Chain.CHAIN_INFO[platformSelectedChainId];
    const sdkNetwork = Chain.ChainNetworkAdamSDKNetworkMapping[network] as Network | undefined;

    const newAdam = new Adam(
      envParam.adamContractAddress,
      signer ?? provider ?? undefined,
      sdkNetwork,
      process.env.REACT_APP_ENV
    );
    setAdam(newAdam);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signer, provider, platformSelectedChainId]);

  // Update supported tokens when Adam is updated
  const updateTokenSettings = useCallback(async () => {
    try {
      const supportedTokens = await adam.supportedTokens();

      const { network } = Chain.CHAIN_INFO[platformSelectedChainId];
      const sdkNetwork = Chain.ChainNetworkAdamSDKNetworkMapping[network];

      const nativeToken = sdkNetwork ? networkConstant[sdkNetwork].native : null;
      const stableCoinSymbols = new Set(Object.values(StableCoinSymbolWithoutNative));
      const stableCoins = (nativeToken ? [nativeToken] : []).concat(
        supportedTokens.filter((token: TokenInfoResponse) =>
          stableCoinSymbols.has(token.symbol as StableCoinSymbolWithoutNative)
        )
      );
      const reapTokenSymbols = new Set(Object.values(ReapTokenSymbol));
      const reapTokens = supportedTokens.filter((token: TokenInfoResponse) =>
        reapTokenSymbols.has(token.symbol as ReapTokenSymbol)
      );
      const defaultBaseCurrencyInfo =
        supportedTokens.find(({ symbol }: TokenInfoResponse) => symbol === DEFAULT_BASE_CURRENCY_SYMBOL) || null;
      const defaultBaseCurrency = defaultBaseCurrencyInfo
        ? {
            ...defaultBaseCurrencyInfo,
            displaySymbol: USDC_BASE_CURRENCY_DISPLAY,
          }
        : null;

      setTokenSettings({
        supportedTokens,
        stableCoins,
        reapTokens,
        nativeToken,
        defaultBaseCurrency,
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.info('Failed to fetch supported tokens', { err });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adam]);

  useEffect(() => {
    updateTokenSettings();
  }, [updateTokenSettings]);

  return null;
}
