import { createTheme, ThemeProvider as MaterialThemeProvider } from '@mui/material/styles';
import { Chain, NetworkUtils as networkUtils } from 'adam-frontend-shared';
import { ethers } from 'ethers';
import { ClientContext } from 'graphql-hooks';
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import styled, { ThemeProvider } from 'styled-components';

import { AdamManager } from 'components/AdamManager';
import ConnectWalletModal from 'components/ConnectWalletModal';
import DaoSidebar from 'components/DaoSidebar';
import GlobalStyle from 'components/GlobalStyle';
import JoinDaoAndDepositModal from 'components/JoinDaoAndDepositModal';
import JoinDaoModal from 'components/JoinDaoModal';
import Web3Manager from 'components/Web3Manager';
import { DaoPanelViewState } from 'constants/daoParam';
import { COLLAPSE_DAO_SIDEBAR_WIDTH } from 'constants/dimension';
import useCurrentDao from 'hooks/useCurrentDao';
import useIsScrollable from 'hooks/useIsScrollable';
import { useSessionPreferences } from 'hooks/useSessionPreferences';
import CurrentDaoManager from 'managers/CurrentDaoManager';
import theme from 'theme/default';
import { isValueInEnum } from 'utils/checkingUtils';
import gtmUtils from 'utils/gtmUtils';

import { lockScroll, unlockScroll } from 'utils/scrollUtils';
import useInitYupMessages from './hooks/useInitYupMessages';
import useSubgraphClient from './hooks/useSubgraphClient';
import Routes from './Routes';

declare global {
  interface EthereumProvider extends ethers.providers.ExternalProvider {
    on: ethers.providers.Provider['on'];
    removeListener: ethers.providers.Provider['removeListener'];
  }
  interface Window {
    /* TEST CODE START */
    ethers: any;
    /* TEST CODE END */

    ethereum: EthereumProvider;
    dataLayer?: Record<string, any>[];
  }
}

interface PageWrapperProps extends React.HTMLProps<HTMLDivElement> {}

const Root = styled.div`
  display: flex;
  width: 100%;
  min-height: 100%;
  margin-bottom: 32px;
`;

const PageWrapperContainer = styled.div`
  width: 100%;
  min-height: 100%;
  flex: 1;
  display: flex;
  margin-left: ${COLLAPSE_DAO_SIDEBAR_WIDTH}px;
`;

const PageInner = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const PageContent = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 600px;
  width: 100%;
  height: 100%;
  padding: 0;
  overflow: hidden;
`;

const materialTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

// Init Google Tag Manager
gtmUtils.init();

const PageWrapper = (props: PageWrapperProps): JSX.Element => {
  const { daoPanelViewState, setDaoPanelView } = useCurrentDao();

  const ref = useRef<HTMLDivElement>(null);

  const setDaoPanelCollapse = useCallback(() => {
    if (daoPanelViewState !== DaoPanelViewState.INITIAL) {
      return;
    }
    setDaoPanelView(DaoPanelViewState.COLLAPSE);
  }, [daoPanelViewState, setDaoPanelView]);

  useEffect(() => {
    ref?.current?.addEventListener('click', setDaoPanelCollapse);

    return () => {
      ref?.current?.removeEventListener('click', setDaoPanelCollapse);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setDaoPanelCollapse]);

  return <PageWrapperContainer ref={ref}>{props.children}</PageWrapperContainer>;
};

const App: React.FC = () => {
  // Console log the current version
  // eslint-disable-next-line no-console
  console.info(`version: ${process.env.REACT_APP_GIT_REF}`);

  const { isScrollable } = useIsScrollable();

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { platformSelectedChainId, setPlatformSelectedChainId } = useSessionPreferences();
  const [isReadyToRender, setIsReadyToRender] = useState(false);

  /*
   * Listen platform selected network change, and update atom (sessionStorage will also be updated),
   * then refresh to update network setup for the whole site.
   */

  useEffect(() => {
    if (!isScrollable) {
      lockScroll();
      return;
    }
    unlockScroll();
  }, [isScrollable]);

  useEffect(() => {
    const networkFromParams = searchParams.get('network');
    const networkInfo = isValueInEnum(Chain.ChainNetwork, networkFromParams)
      ? networkUtils.getChainInfo(networkFromParams as Chain.ChainNetwork)
      : null;

    if (!networkInfo) {
      return;
    }

    if (networkInfo.chainId !== platformSelectedChainId) {
      setPlatformSelectedChainId(networkInfo.chainId);
      searchParams.delete('network');
      setSearchParams(searchParams);
      navigate(0);
    }
  }, [navigate, platformSelectedChainId, searchParams, setPlatformSelectedChainId, setSearchParams]);

  // Init and setup required services
  const subgraphClient = useSubgraphClient();
  useInitYupMessages();

  useEffect(() => {
    // Hot fix: web socket sometime is not ready
    setTimeout(() => {
      setIsReadyToRender(true);
    }, 1000);
  }, []);

  useEffect(() => {
    const { isTestnet, name } = Chain.CHAIN_INFO[platformSelectedChainId];
    const networkText = isTestnet ? ` - ${name}` : '';
    document.title = `ADAM Vault${networkText}`;
  }, [platformSelectedChainId]);

  return (
    <ThemeProvider theme={theme}>
      <MaterialThemeProvider theme={materialTheme}>
        <GlobalStyle />
        <ClientContext.Provider value={subgraphClient}>
          <AdamManager />
          <Web3Manager />
          <CurrentDaoManager />
          {isReadyToRender && (
            <Root>
              <JoinDaoModal />
              <JoinDaoAndDepositModal />
              <ConnectWalletModal />
              <DaoSidebar />
              <PageWrapper>
                <PageInner>
                  <PageContent>
                    <Routes />
                    <div id="helperPanel" />
                  </PageContent>
                </PageInner>
              </PageWrapper>
            </Root>
          )}
        </ClientContext.Provider>
      </MaterialThemeProvider>
    </ThemeProvider>
  );
};

export default App;
