import styled from 'styled-components';
import Icon from 'components/Icon';
import Loading from 'components/Loading';
import Modal from 'components/Modal';
import Text from 'components/Text';
import PATHS from 'constants/paths';
import useCanByPassVoting from 'hooks/useCanByPassVoting';
import useCurrentDao from 'hooks/useCurrentDao';
import useShareUrl from 'hooks/useShareUrl';
import CopyableText from 'pages/MemberListing/CopyableText';
import defaultTheme from 'theme/default';

const StyledShareLinkText = styled(Text)`
  margin-bottom: 8px;
`;

const WarningContainer = styled.div`
  display: flex;
  margin-top: 80px;
`;

const WarningText = styled(Text)`
  margin-left: 0.5em;
  color: ${({ theme: { color } }): string => color.warning};
`;

export type Props = {
  isOpen: boolean;
  closeModal: () => void;
};

export default function AddMemberModal(props: Props): JSX.Element {
  const { isOpen, closeModal } = props;
  const { currentDao } = useCurrentDao();
  const currentDaoAddress = currentDao?.address || '';
  const joinDaoUrl = useShareUrl(PATHS.DAO.JOIN_DAO, { daoAddress: currentDaoAddress });

  const { canByPassVoting, loading } = useCanByPassVoting(currentDaoAddress);

  return (
    <Modal isOpen={isOpen} contentLabel="Add Members to Vault" onRequestClose={closeModal}>
      {loading ? (
        <Loading isCenter />
      ) : (
        <>
          <StyledShareLinkText category="body" weight="bold">
            Share Vault link
          </StyledShareLinkText>
          <CopyableText
            text={joinDaoUrl}
            modalContentLabel="Vault link Copied"
            modalContent="The Vault link is copied to clipboard!"
          />
          {canByPassVoting && (
            <WarningContainer>
              <Icon icon="field_warning" size="18px" color={defaultTheme.color.warning} />
              <WarningText category="body">
                When there is more than 1 member, voting is required to effect changes to the DAO.
              </WarningText>
            </WarningContainer>
          )}
        </>
      )}
    </Modal>
  );
}
