import { Menu, MenuButton, MenuItem, MenuItems, MenuPopover, MenuLink } from '@reach/menu-button';
import { useRect } from '@reach/rect';
import { MouseEventHandler, useMemo, useRef } from 'react';
import styled, { css, keyframes, useTheme } from 'styled-components';
import Icon from 'components/Icon';
import Description from 'components/layout/Description';
import Rect from 'components/layout/Rect';
import useWindowSize from 'hooks/useWindowSize';
import defaultTheme from 'theme/default';

export type DropdownItemType = {
  key: string;
  content: string | React.ReactNode;
  href?: string;
  title?: string;
  onSelect: () => void;
  onClick?: MouseEventHandler<HTMLDivElement>;
};

export interface Props {
  className?: string;
  buttonText?: string;
  marginOfPopover?: number;
  items: Array<DropdownItemType>;
  renderCustomButtonContent?: () => React.ReactNode;
  popoverZIndex?: number;
  width?: number;
}

const DEFAULT_MENU_WIDTH = 164;

const BUFFER = 30;

const slideDownKeyframes = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const ButtonContent = styled(Rect)`
  padding: 6px 10px;
  border-radius: 8px;
  min-width: ${DEFAULT_MENU_WIDTH}px;
  background-color: ${({ theme: { color } }): string => color.dropdownBackground};
  text-align: left;
  flex-direction: row;
  align-items: center;
`;

const DescriptionWrapper = styled.div`
  flex: 1;
  margin-right: 8px;
`;

const StyledMenuPopover = styled(MenuPopover)<{
  menuButtonWidth: number;
  marginOfPopover: number;
  popoverZIndex?: number;
  left?: number;
  top?: number;
}>`
  animation: ${slideDownKeyframes} 0.2s ease;
  width: ${({ menuButtonWidth }): string => `${menuButtonWidth}px`};
  min-width: ${DEFAULT_MENU_WIDTH}px;
  margin-top: ${({ marginOfPopover }): string => `${marginOfPopover}px`};
  z-index: ${({ popoverZIndex }): number | string => popoverZIndex ?? 'default'};
  ${({ left }) =>
    left &&
    css`
      left: ${left}px !important;
    `};
  ${({ top }) =>
    top &&
    css`
      top: ${top}px !important;
    `};
`;

const StyledMenuItems = styled(MenuItems)`
  border-radius: 10px;
  background-color: ${({ theme: { color } }): string => color.widget};
`;

const MenuItemsWrapper = styled.div``;

const StyledMenuItem = styled(MenuItem)`
  font-size: 14px;
  font-weight: 400;
  padding: 8px 20px;
  color: ${({ theme: { color } }): string => color.fontPrimary};
  overflow: hidden;
  text-overflow: ellipsis;

  &[data-reach-menu-item][data-selected] {
    background: ${({ theme: { color } }): string => color.actionPrimary};
  }
`;

const StyledMenuLink = styled(MenuLink)`
  font-size: 14px;
  font-weight: 400;
  padding: 8px 20px;
  color: ${({ theme: { color } }): string => color.fontPrimary};

  &[data-reach-menu-item][data-selected] {
    background: ${({ theme: { color } }): string => color.primary};
  }
`;

const StyledIcon = styled(Icon)``;

export default function DropdownMenu(props: Props): JSX.Element {
  const { popoverZIndex = 1001 } = props;
  const { color } = useTheme() as typeof defaultTheme;
  const menuButtonRef = useRef<HTMLButtonElement>(null);
  const menuItemsRef = useRef<HTMLDivElement>(null);

  const menuButtonRect = useRect(menuButtonRef);
  const menuItemsRect = useRect(menuItemsRef);
  const { height: windowHeight } = useWindowSize();

  const { marginOfPopover = 8 } = props;

  const menuItemsTop = useMemo(() => {
    const isOutOfWindowBound =
      (menuButtonRect?.bottom ?? 0) + marginOfPopover + (menuItemsRect?.height ?? 0) > (windowHeight ?? 0) - BUFFER;
    return isOutOfWindowBound ? 0 : (menuButtonRect?.bottom ?? 0) + window.scrollY;
  }, [marginOfPopover, menuButtonRect?.bottom, menuItemsRect?.height, windowHeight]);

  return (
    // Menu is the wrapper component for the other components. No DOM element is rendered.
    <Menu>
      <MenuButton className={props.className} ref={menuButtonRef}>
        {props.renderCustomButtonContent ? (
          props.renderCustomButtonContent()
        ) : (
          <ButtonContent className="dropdown__button-content">
            <DescriptionWrapper>
              <Description className="dropdown__description">{props.buttonText}</Description>
            </DescriptionWrapper>
            <StyledIcon icon="field_dropdown" color={color.dropdownArrow} />
          </ButtonContent>
        )}
      </MenuButton>
      <StyledMenuPopover
        menuButtonWidth={menuButtonRect?.width ?? DEFAULT_MENU_WIDTH}
        marginOfPopover={marginOfPopover}
        popoverZIndex={popoverZIndex}
        left={menuButtonRect?.left}
        top={menuItemsTop}
      >
        <StyledMenuItems>
          <MenuItemsWrapper ref={menuItemsRef}>
            {props.items.map((x) =>
              x.href ? (
                <StyledMenuLink key={x.key} href={x.href}>
                  {x.content}
                </StyledMenuLink>
              ) : (
                <StyledMenuItem
                  key={x.key}
                  title={x.title}
                  onClick={x.onClick as MouseEventHandler<HTMLDivElement>}
                  onSelect={x.onSelect}
                >
                  {x.content}
                </StyledMenuItem>
              )
            )}
          </MenuItemsWrapper>
        </StyledMenuItems>
      </StyledMenuPopover>
    </Menu>
  );
}
