import styled, { css } from 'styled-components';
import { buttonCss } from 'components/Button';
import Text from 'components/Text';

export type Tab = {
  id?: string;
  label: string;
};

export interface Props {
  className?: string;
  variant?: 'normal' | 'button' | 'bubble';
  tabs: Tab[];

  // Controlled props, accept either tab index or tab id as the control ref
  focusedTabIndex?: number;
  focusedTabId?: string;
  onTabChange: (tabIndex: number, tabId: Tab['id']) => void;
}

type TabsWrapperProps = {
  variant?: Props['variant'];
};

type TabButtonProps = {
  variant?: Props['variant'];
  isFocused?: boolean;
};

const TabsWrapper = styled.div<TabsWrapperProps>`
  display: flex;
  align-items: center;

  ${({ variant, theme: { color } }) =>
    variant === 'button' &&
    css`
      background-color: ${color.oldDarkGray1};
      border-radius: 5px;
    `}

  ${({ variant }) =>
    variant === 'bubble' &&
    css`
      gap: 20px;
    `}
`;

const TabButton = styled.button<TabButtonProps>`
  ${({ variant, isFocused, theme: { color } }) =>
    variant === 'normal' &&
    css`
      padding: 0 10px;
      font-size: 22px;
      font-weight: 500;
      border-bottom: solid 2px ${isFocused ? color.fontHighlight : 'transparent'};
      transition: color 0.2s, border-color 0.2s;

      &:not(:first-of-type) {
        margin-left: 10px;
      }
    `}

  ${({ variant, isFocused, theme: { color } }) =>
    variant === 'button' &&
    css`
      margin: 0;
      ${buttonCss}
      background-color: ${isFocused ? color.buttonBackground.primary : 'transparent'};
      outline: unset !important;
    `};

  ${({ variant, isFocused, theme: { color } }) =>
    variant === 'bubble' &&
    css`
      padding: 3px 16px;
      border: solid 2px ${color.buttonBackground.primary};
      border-radius: 18px / 50%;
      background-color: ${isFocused ? color.buttonBackground.primary : 'transparent'};
    `};
`;

interface LabelProps {
  variant: Props['variant'];
  label: Tab['label'];
}

const Label = styled(Text).attrs((props: LabelProps) => {
  let category;

  switch (props.variant) {
    case 'normal':
    case 'button':
    case undefined:
      category = 'title-3';
      break;
    case 'bubble':
      category = 'headline-2';
  }

  return {
    category,
    weight: 'bold',
  };
})`
  ${({ variant, isFocused, theme: { color } }) =>
    variant === 'normal' &&
    css`
      color: ${isFocused ? color.fontHighlight : 'inherited'};
    `};

  ${({ variant, isFocused, theme: { color } }) =>
    variant === 'bubble' &&
    css`
      color: ${isFocused ? color.primaryText : color.buttonFontColor.primary};
    `};
`;

export default function Tabs({
  className,
  variant = 'normal',
  tabs,
  focusedTabIndex,
  focusedTabId,
  onTabChange,
}: Props): JSX.Element {
  return (
    <TabsWrapper className={className} variant={variant}>
      {tabs.map((tab, index) => {
        const handleClick = (): void => {
          onTabChange(index, tab.id);
        };

        const isFocused = index === focusedTabIndex || tab.id === focusedTabId;

        return (
          <TabButton key={tab.id || tab.label} onClick={handleClick} variant={variant} isFocused={isFocused}>
            <Label isFocused={isFocused} variant={variant}>
              {tab.label}
            </Label>
          </TabButton>
        );
      })}
    </TabsWrapper>
  );
}
