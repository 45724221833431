import { Chain, Wallet as WalletConstants } from 'adam-frontend-shared';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Button from 'components/Button';
import ExternalLink from 'components/layout/ExternalLink';
import Modal from 'components/Modal';
import { useSessionPreferences } from 'hooks/useSessionPreferences';
import useWeb3 from 'hooks/useWeb3';

const { Wallet } = WalletConstants;

const StyledModal = styled(Modal)`
  width: 680px;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 85px;
  text-align: center;
  font-size: 16px;
`;

const ModalBottomContent = styled.div`
  margin-top: 32px;
  font-size: 14px;
`;

const StyledButton = styled(Button)`
  margin-top: 32px;
`;

const StyledExternalLink = styled(ExternalLink)`
  text-decoration: none;
  color: inherit;
`;

export default function Web3Manager(): JSX.Element {
  const { error, walletType, chainId, tryToReconnectEOA, switchNetwork, setWeb3Ready, isEOAAddressUpdated } = useWeb3();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isChangeAddressModalOpen, setIsChangeAddressModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { platformSelectedChainId } = useSessionPreferences();
  const platformChainInfo = Chain.CHAIN_INFO[platformSelectedChainId];
  const walletChainInfo = chainId ? Chain.CHAIN_INFO[chainId] : null;
  const connectedWallet = walletType ? Wallet[walletType] : null;
  const doesWalletSupportSwitchNetwork = !!connectedWallet?.switchNetwork;
  const isWalletChainSupported =
    walletChainInfo &&
    Chain.SUPPORTED_CHAINS.find(
      ({ chainId: supportedChainId }: Chain.ChainInfoType) => walletChainInfo.chainId === supportedChainId
    );

  useEffect(() => {
    setIsChangeAddressModalOpen(isEOAAddressUpdated || false);
  }, [isEOAAddressUpdated]);

  const closeChangeAddressModal = (): void => {
    setIsChangeAddressModalOpen(false);
  };

  const onSwitchNetworkButtonClick = useCallback(async () => {
    setIsLoading(true);
    await switchNetwork(platformChainInfo.network);
    setIsLoading(false);
  }, [platformChainInfo.network, switchNetwork]);

  const onClickRefreshPage = useCallback(() => {
    setIsChangeAddressModalOpen(false);
    navigate(0);
  }, [navigate]);

  useEffect(() => {
    if (!error) {
      return;
    }

    // eslint-disable-next-line no-console
    console.info('Failed to perform wallet action', error);
  }, [error]);

  // Try to connect EOA
  useEffect(() => {
    async function tryToReconnect(): Promise<void> {
      await tryToReconnectEOA();
      setWeb3Ready(true);
    }

    tryToReconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!chainId) {
      return;
    }

    setIsModalOpen(chainId !== platformSelectedChainId);
  }, [chainId, platformSelectedChainId]);

  return (
    <>
      {/* Change address modal */}
      <StyledModal
        isOpen={isChangeAddressModalOpen}
        contentLabel="Change Address?"
        onRequestClose={closeChangeAddressModal}
        isHighestLayer
        isCenterAligned
      >
        <ModalContent>
          You have changed your crypto wallet address. Refresh to connect the new address with ADAM Vault.
          <StyledButton onClick={onClickRefreshPage}>REFRESH</StyledButton>
        </ModalContent>
      </StyledModal>
      {/* Block unsupported network */}
      <StyledModal isOpen={isModalOpen} contentLabel="Switch Network?" isHighestLayer isCenterAligned>
        <ModalContent>
          Your wallet is not connected to this network. Switch to start using ADAM Vault on {platformChainInfo.name}.
          {doesWalletSupportSwitchNetwork && (
            <StyledButton onClick={onSwitchNetworkButtonClick} loading={isLoading}>
              Switch to {platformChainInfo.name}
            </StyledButton>
          )}
          {isWalletChainSupported && (
            <ModalBottomContent>
              or use ADAM Vault on{' '}
              <StyledExternalLink href={walletChainInfo?.appUrl} target="_self">
                {walletChainInfo?.name}
              </StyledExternalLink>
            </ModalBottomContent>
          )}
        </ModalContent>
      </StyledModal>
    </>
  );
}
