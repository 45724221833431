import { MaxAmountType } from '@adam-vault/adam-sdk';

export const MAX_OUTFLOW_ADDRESSES_NUMBER = 200;

export const REAP_CARD = {
  MIN_TOP_UP_AMOUNT: 1000,
  PROCESSING_FEE_RATE: 0.01,
};

export enum SupportedBudgetApprovalStatus {
  ACTIVE = 'active',
  REVOKE = 'revoked',
}

export const BUDGET_APPROVAL_STATUS = {
  [SupportedBudgetApprovalStatus.ACTIVE]: {
    type: SupportedBudgetApprovalStatus.ACTIVE,
    label: 'Active',
  },
  [SupportedBudgetApprovalStatus.REVOKE]: {
    type: SupportedBudgetApprovalStatus.REVOKE,
    label: 'Revoke',
  },
};

export enum SupportedTransactionType {
  OUTFLOW = 'outflow',
  SWAP = 'swap',
  CARD = 'card',
}

export const TRANSACTION_TYPE = {
  [SupportedTransactionType.OUTFLOW]: {
    type: SupportedTransactionType.OUTFLOW,
    label: 'Direct Transfer',
  },
  [SupportedTransactionType.SWAP]: {
    type: SupportedTransactionType.SWAP,
    label: 'Swap',
  },
  [SupportedTransactionType.CARD]: {
    type: SupportedTransactionType.CARD,
    label: 'Virtual Card',
  },
};

export enum SupportedDestinationType {
  ANY = 'any',
  SPECIFIC_TEAM = 'specificTeam',
  SPECIFIC_MEMBERS = 'specificMembers',
  SPECIFIC_ADDRESSES = 'specificAddresses',
}

export const DESTINATION_TYPE = {
  [SupportedDestinationType.ANY]: {
    type: SupportedDestinationType.ANY,
    label: 'Any',
  },
  [SupportedDestinationType.SPECIFIC_TEAM]: {
    type: SupportedDestinationType.SPECIFIC_TEAM,
    label: 'A Specific Team',
  },
  [SupportedDestinationType.SPECIFIC_ADDRESSES]: {
    type: SupportedDestinationType.SPECIFIC_ADDRESSES,
    label: 'Specific Addresses',
  },
  [SupportedDestinationType.SPECIFIC_MEMBERS]: {
    type: SupportedDestinationType.SPECIFIC_MEMBERS,
    label: 'Specific Members',
  },
};

export enum SupportedDexType {
  UNISWAP = 'uniswap',
}

export const DEX_TYPE = {
  [SupportedDexType.UNISWAP]: {
    type: SupportedDexType.UNISWAP,
    label: 'Uniswap',
  },
};

export enum SourceType {
  LIQUID_POOL = 'liquidPool',
  TREASURY = 'treasury',
}

export const SOURCE_TYPE = {
  [SourceType.LIQUID_POOL]: {
    type: SourceType.LIQUID_POOL,
    label: 'Members',
  },
  [SourceType.TREASURY]: {
    type: SourceType.TREASURY,
    label: 'Public Account',
  },
};

export enum SupportedOutflowTokensType {
  MEMBER_TOKEN = 'memberToken',
  // And all supported Token
}

export const OUTFLOW_TOKENS_TYPE = {
  [SupportedOutflowTokensType.MEMBER_TOKEN]: {
    type: SupportedOutflowTokensType.MEMBER_TOKEN,
    label: 'Member Token',
  },
};

export enum SupportedTransferTokenType {
  ANY_ERC_20_TOKEN = 'anyERC20Token',
  POPULAR_ERC_20_TOKEN = 'popularERC20Token',
  CUSTOM_ERC_20_TOKEN = 'customERC20Token',
  MULTI_ERC_20_TOKENS = 'multiERC20Tokens',
  MEMBER_TOKEN = 'memberToken',
  ANY_NFT = 'anyNFT',
  SPECIFIC_NFT = 'specificNFT',
}

export const TRANSFER_TOKEN_TYPE = {
  [SupportedTransferTokenType.ANY_ERC_20_TOKEN]: {
    type: SupportedTransferTokenType.ANY_ERC_20_TOKEN,
    label: 'Any ERC-20 token',
  },
  [SupportedTransferTokenType.POPULAR_ERC_20_TOKEN]: {
    type: SupportedTransferTokenType.POPULAR_ERC_20_TOKEN,
    label: 'Popular ERC-20 tokens',
  },
  [SupportedTransferTokenType.CUSTOM_ERC_20_TOKEN]: {
    type: SupportedTransferTokenType.CUSTOM_ERC_20_TOKEN,
    label: 'Custom ERC-20 token',
  },
  [SupportedTransferTokenType.MULTI_ERC_20_TOKENS]: {
    type: SupportedTransferTokenType.MULTI_ERC_20_TOKENS,
    label: 'Multiple ERC-20 tokens',
  },
  [SupportedTransferTokenType.ANY_NFT]: {
    type: SupportedTransferTokenType.ANY_NFT,
    label: 'Any NFT',
  },
  [SupportedTransferTokenType.SPECIFIC_NFT]: {
    type: SupportedTransferTokenType.SPECIFIC_NFT,
    label: 'Specific NFT',
  },
  [SupportedTransferTokenType.MEMBER_TOKEN]: {
    type: SupportedTransferTokenType.MEMBER_TOKEN,
    label: 'Member Token',
  },
};

export enum SupportedExecutorType {
  ME = 'me',
  SPECIFIC_MEMBER = 'specificMember',
  SPECIFIC_TEAM = 'specificTeam',
  SPECIFIC_ADDRESS = 'specificAddress',
}

export enum SupportedUsageLimitType {
  UNLIMITED = 'unlimited',
  LIMITED = 'limited',
}

export const USAGE_LIMIT_TYPE = {
  [SupportedUsageLimitType.UNLIMITED]: {
    type: [SupportedUsageLimitType.UNLIMITED],
    label: 'Unlimited',
  },
  [SupportedUsageLimitType.LIMITED]: {
    type: [SupportedUsageLimitType.LIMITED],
    label: 'Limited',
  },
};

export enum SupportedUsagePeriodType {
  INDEFINITE = 'indefinite',
  SPECIFIC_PERIOD = 'specificPeriod',
}

export const USAGE_PERIOD_TYPE = {
  [SupportedUsagePeriodType.INDEFINITE]: {
    type: SupportedUsagePeriodType.INDEFINITE,
    label: 'Indefinite',
  },
  [SupportedUsagePeriodType.SPECIFIC_PERIOD]: {
    type: SupportedUsagePeriodType.SPECIFIC_PERIOD,
    label: 'Specific Period',
  },
};

export enum SupportedApproversType {
  NO = 'no',
  TEAM = 'team',
  ADDRESSES = 'addresses',
}

export const APPROVERS_TYPE = {
  [SupportedApproversType.TEAM]: {
    type: SupportedApproversType.TEAM,
    label: 'Team',
  },
  [SupportedApproversType.ADDRESSES]: {
    type: SupportedApproversType.ADDRESSES,
    label: 'Addresses',
  },
  [SupportedApproversType.NO]: {
    type: SupportedApproversType.NO,
    label: 'Not required',
  },
};

const BUDGET_PARAM = {
  SupportedTransactionType,
  SupportedDestinationType,
  MaxAmountType,
  ExecutorType: SupportedExecutorType,
};

export default BUDGET_PARAM;
