import { useCallback, useMemo } from 'react';
import { useController } from 'react-hook-form';
import styled, { useTheme } from 'styled-components';
import DropdownMenu from 'components/DropdownMenu';
import Icon from 'components/Icon';
import AddressText from 'components/layout/AddressText';
import Description from 'components/layout/Description';
import Rect from 'components/layout/Rect';
import defaultTheme from 'theme/default';
import HookFormFieldProps from 'types/HookFormFieldProps';

export type AddressDropdownInputOptionItem = {
  text?: string;
  address: string;
};

interface Props extends HookFormFieldProps {
  items: Array<AddressDropdownInputOptionItem>;
  name: string;
  className?: string;
}

const Container = styled(Rect)`
  min-height: 72px;
  background-color: ${({ theme: { color } }): string => color.textField};
  padding: 12px 16px;
  flex-direction: row;
  align-items: center;
  row-gap: 22px;
  text-align: left;
`;

const ReadOnlyContainer = styled(Rect)`
  min-height: 72px;
  background-color: ${({ theme: { color } }): string => color.textField};
  padding: 12px 16px;
  align-items: flex-start;
  text-align: left;
`;

const Content = styled.div`
  flex: 1;
`;

const Label = styled(Description)`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: ${({ theme: { color } }): string => color.fontPrimary};
  margin-bottom: 4px;
`;

const StyledAddressText = styled(AddressText)`
  color: ${({ theme: { color } }): string => color.fontSecondary};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
`;

const StyledDropdownMenu = styled(DropdownMenu)`
  width: 100%;
`;

export default function AddressDropdownInput(props: Props): JSX.Element {
  const { color } = useTheme() as typeof defaultTheme;

  const { field } = useController({ control: props.control, name: props.name });

  const dropdownItems = useMemo(
    () =>
      props.items.map((item) => ({
        key: item.address,
        content: item.text ?? item.address,
        onSelect: (): void => field.onChange(item.address),
      })),
    [field, props.items]
  );

  const selectedItem = props.items.find((item) => item.address === field.value);

  const renderCustomButtonContent = useCallback(
    (): JSX.Element => (
      <Container>
        <Content>
          {selectedItem?.text && <Label>{selectedItem?.text}</Label>}
          <StyledAddressText>{field.value}</StyledAddressText>
        </Content>
        <Icon icon="field_dropdown" color={color.dropdownArrow} />
      </Container>
    ),
    [color.dropdownArrow, field.value, selectedItem?.text]
  );

  if (props.items.length === 1) {
    return (
      <ReadOnlyContainer>
        {selectedItem?.text && <Label>{selectedItem?.text}</Label>}
        <StyledAddressText>{field.value}</StyledAddressText>
      </ReadOnlyContainer>
    );
  }

  return <StyledDropdownMenu renderCustomButtonContent={renderCustomButtonContent} items={dropdownItems} />;
}
