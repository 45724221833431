import styled from 'styled-components';
import Button from 'components/Button';
import Text from 'components/Text';

interface Props {
  onImportButtonClick: () => void;
}

const Wrapper = styled.div`
  margin-top: 18px;
  padding: 20px;
  width: 100%;
  border-radius: 8px;
  background-color: ${({ theme: { color } }) => color.panel};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

export default function ImportCard({ onImportButtonClick }: Props): JSX.Element {
  return (
    <Wrapper>
      <Text category="headline-1" weight="bold">
        Don&apos;t find your token?
      </Text>
      <Button onClick={onImportButtonClick}>IMPORT TOKEN</Button>
    </Wrapper>
  );
}
