import { Chain } from 'adam-frontend-shared';
import { useAtom } from 'jotai';
import { platformSelectedChainIdAtom } from 'store/sessionPreferencesAtom';

type Return = {
  platformSelectedChainId: Chain.ChainId;
  setPlatformSelectedChainId: (chainId: Chain.ChainId) => void;
};

export function useSessionPreferences(): Return {
  const [platformSelectedChainId, setPlatformSelectedChainId] = useAtom(platformSelectedChainIdAtom);

  return { platformSelectedChainId: platformSelectedChainId as Chain.ChainId, setPlatformSelectedChainId };
}
