import { useState, useEffect, useCallback, useMemo } from 'react';
import styled from 'styled-components';

import Button from 'components/Button';
import Modal from 'components/Modal';
import TxStatus from 'constants/txStatus';

interface Props {
  txStatus: TxStatus;
  confirmBtnText?: string;
  errorBtnText?: string;
  successMessage?: string;
  onConfirm?: (txStatus: TxStatus) => void;
  errorMessage?: string;
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;
`;

const Message = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  margin-bottom: 42px;
  font-size: 16px;
  color: ${({ theme: { color } }) => color.fontSecondary};
`;

const ButtonBar = styled.div`
  margin-top: ${({ theme: { space } }) => `${space[3]}px`};
  display: flex;
  justify-content: center;
`;

export default function TxStatusModal({
  txStatus,
  confirmBtnText = 'Close',
  errorBtnText = 'Close',
  successMessage = '',
  errorMessage = 'Something went wrong. Please try again later.',
  onConfirm,
}: Props): JSX.Element {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const closeModal = (): void => setIsOpen(false);

  const confirmAndClose = useCallback(() => {
    if (txStatus === TxStatus.COMPLETED && onConfirm) {
      onConfirm(txStatus);
    } else if (txStatus === TxStatus.ERROR) {
      closeModal();
    }
    closeModal();
  }, [onConfirm, txStatus]);

  useEffect(() => {
    if (txStatus !== TxStatus.PENDING) {
      return;
    }

    setIsOpen(true);
  }, [txStatus]);

  const { title, message, btnText } = useMemo(() => {
    switch (txStatus) {
      case TxStatus.PENDING:
        return {
          title: 'Pending Transaction',
          message: 'Confirm this transaction in your wallet.',
          btnText: '',
        };
      case TxStatus.COMPLETED:
        return { title: 'Transaction Completed', message: successMessage, btnText: confirmBtnText };
      case TxStatus.ERROR:
        return { title: 'Transaction Failed', message: errorMessage, btnText: errorBtnText };
      default:
        return { title: '', message: '', btnText: '' };
    }
  }, [txStatus, successMessage, errorMessage, confirmBtnText, errorBtnText]);

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal} contentLabel={title} isCenterAligned>
      <Content>
        <Message>{message}</Message>
        <ButtonBar>
          <Button onClick={confirmAndClose} loading={txStatus === TxStatus.PENDING} variant="ghost">
            {btnText}
          </Button>
        </ButtonBar>
      </Content>
    </Modal>
  );
}
