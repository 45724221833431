import { TokenData } from '@adam-vault/adam-sdk';
import { BigNumber } from 'ethers';
import { useAdamMemo } from 'hooks/useAdam';
import { toCustomDateString } from 'utils/datetimeUtils';

type ReturnDataType = {
  name: string;
  description: string;
  baseCurrencyData?: TokenData;
  membersCount: string;
  createdOn: string;
  isOverMembersCountLimit: boolean;
};

type JoinDaoInfoResult = { isLoading: boolean; data: ReturnDataType | null };

export default function useJoinDaoInfo(daoAddress: string): JoinDaoInfoResult {
  const [joinDaoInfoData, { isLoading }] = useAdamMemo<ReturnDataType | null>(
    async (adam) => {
      const daoEntity = adam.loadDao(daoAddress);
      const baseCurrencyEntity = await daoEntity.loadBaseCurrency();
      const loadedMembersCount = await daoEntity.countMembership();
      const maxMembersLimit = await daoEntity.maxMemberLimit();

      return {
        name: await daoEntity.name(),
        description: await daoEntity.description(),
        baseCurrencyData: await baseCurrencyEntity.fetch(),
        membersCount: loadedMembersCount,
        createdOn: toCustomDateString(await daoEntity.createdAt(), 'dd MMM yyyy'),
        isOverMembersCountLimit:
          !!maxMembersLimit && BigNumber.from(loadedMembersCount).gte(BigNumber.from(maxMembersLimit)),
      };
    },
    [daoAddress],
    null
  );

  return {
    data: joinDaoInfoData,
    isLoading,
  };
}
