import { Wallet as WalletConstants } from 'adam-frontend-shared';
import { useAtom } from 'jotai';
import { useCallback } from 'react';
import styled, { useTheme } from 'styled-components';
import Icon from 'components/Icon';
import Rect from 'components/layout/Rect';
import ModalWrapper from 'components/ModalWrapper';
import Text from 'components/Text';
import useWeb3 from 'hooks/useWeb3';
import connectModalAtom from 'store/connectModalAtom';
import SelectWallet from './SelectWallet';

const { BEGINNER_WALLETS, ADVANCED_WALLETS } = WalletConstants;

const TitleWrapper = styled.div`
  margin-bottom: 32px;
`;

const ContentWrapper = styled(Rect)`
  position: relative;
  display: flex;
  outline: 0;
  padding: 32px;
  border-radius: 8px;
  background-color: ${({ theme: { color } }) => color.connectWalletModalBackground};
`;

const CloseBtn = styled.div`
  position: absolute;
  top: 36px;
  right: 32px;
`;

export default function ConnectWalletModal(): JSX.Element {
  const [connectModal, setConnectModal] = useAtom(connectModalAtom);

  const { connectEOA } = useWeb3();

  const { color } = useTheme();

  const closeModal = useCallback((): void => {
    setConnectModal({ isModalOpen: false });
  }, [setConnectModal]);

  const onWalletOptionSelect = useCallback(
    (walletType: WalletConstants.WalletType): void => {
      closeModal();
      connectEOA(walletType);
    },
    [closeModal, connectEOA]
  );

  return (
    <ModalWrapper isOpen={connectModal.isModalOpen} onRequestClose={closeModal} ContentWrapper={ContentWrapper}>
      <TitleWrapper>
        <Text category="title-3" weight="bold">
          Connect Wallet
        </Text>
      </TitleWrapper>

      <CloseBtn>
        <button aria-label="Close" onClick={closeModal}>
          <Icon icon="close" color={color.connectWalletModalCloseButton} />
        </button>
      </CloseBtn>
      <SelectWallet
        beginnerWallets={BEGINNER_WALLETS}
        advancedWallets={ADVANCED_WALLETS}
        onSelect={onWalletOptionSelect}
      />
    </ModalWrapper>
  );
}
