import {
  useEffect,
  useState,
  useRef,
  InputHTMLAttributes,
  ChangeEvent,
  FocusEvent,
  KeyboardEvent,
  useCallback,
} from 'react';
import { useController } from 'react-hook-form';
import styled from 'styled-components';
import InputLayout from 'components/form/InputLayout';
import Description from 'components/layout/Description';
import Loading from 'components/Loading';
import HookFormFieldProps from 'types/HookFormFieldProps';
import { isNumber } from 'utils/numberUtils';
import { toNumberWithCommas } from 'utils/thousandSeparatorUtils';
import { Input } from './StylelessInput';

export interface Props extends HookFormFieldProps, InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  error?: string;
  isHelperTextLoading?: boolean;
  helperText?: string;
  onlyInteger?: boolean;
}

const Container = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const HelperDescription = styled(Description)`
  margin-left: 6px;
  min-width: 100px;
  text-align: right;
`;

// Only allow number and dot for input
const ACCEPTED_FLOAT_CHARACTER_REGEX = /[0-9.]/;
const ACCEPTED_INTEGER_CHARACTER_REGEX = /[0-9]/;
const DISPLAY_FLOAT_VALUE_REGEX = /^(?!0[0-9])[0-9,]*[.]?[0-9]*$/;
const DISPLAY_INTEGER_VALUE_REGEX = /^(?!0[0-9])[0-9,]+$/;

const getValidLatestInput = (
  latestInput: string,
  latestCharacter: string,
  prevInput: string,
  allowedCharactersRegex: RegExp,
  displayValueRegex: RegExp
): string => {
  // the user highlight the text and delete / enter a number
  if (latestInput.length < prevInput.length) {
    return latestInput;
  }

  if (!latestCharacter.match(allowedCharactersRegex) || !latestInput.match(displayValueRegex)) {
    return prevInput;
  }
  return latestInput;
};

export default function AmountInput(props: Props): JSX.Element {
  const { error, className, name = 'input', control, value, onlyInteger = false, ...baseProps } = props;
  const allowedCharactersRegex = onlyInteger ? ACCEPTED_INTEGER_CHARACTER_REGEX : ACCEPTED_FLOAT_CHARACTER_REGEX;
  const displayValueRegex = onlyInteger ? DISPLAY_INTEGER_VALUE_REGEX : DISPLAY_FLOAT_VALUE_REGEX;

  const [prevValue, setPrevValue] = useState('');
  const [latestCharacter, setLatestCharacter] = useState('');
  const [displayValue, setDisplayValue] = useState<string>('');
  const [cursorPosition, setCursorPosition] = useState<number | null>(null);

  const ref = useRef<HTMLInputElement>(null);
  const { field } = useController({ name, control });

  const inputValue = value ?? field.value;

  useEffect(() => {
    let v = inputValue;
    if (isNumber(v)) {
      v = toNumberWithCommas(v);
    }
    setDisplayValue(v);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const input = ref.current;
    if (input) {
      input.setSelectionRange(cursorPosition, cursorPosition);
    }
  }, [ref, cursorPosition, displayValue]);

  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const latestValue = e.target.value.replaceAll(',', '');
    let targetValue = getValidLatestInput(
      latestValue,
      latestCharacter,
      prevValue,
      allowedCharactersRegex,
      displayValueRegex
    );

    if (isNumber(targetValue)) {
      targetValue = toNumberWithCommas(targetValue);
      if (e.target.selectionStart) {
        const oldStringCommaNumber = Array.from(e.target.value.slice(0, e.target.selectionStart).matchAll(/,/g)).length;
        const newStringCommaNumber = Array.from(targetValue.slice(0, e.target.selectionStart).matchAll(/,/g)).length;
        if (newStringCommaNumber > oldStringCommaNumber) {
          setCursorPosition(e.target.selectionStart + 1);
        } else if (newStringCommaNumber < oldStringCommaNumber) {
          setCursorPosition(e.target.selectionStart - 1);
        } else {
          setCursorPosition(e.target.selectionStart);
        }
      }
    }

    setDisplayValue(targetValue);
    const event: ChangeEvent<HTMLInputElement> = {
      ...e,
      target: {
        ...e.target,
        value: targetValue.replaceAll(',', ''),
      },
    };

    if (baseProps.onChange) {
      baseProps.onChange(event);
    }
    field.onChange(event);
  };

  const onBlur = (e: FocusEvent<HTMLInputElement, Element>): void => {
    if (baseProps.onBlur) {
      baseProps.onBlur(e);
    }
    field.onBlur();
  };

  const onKeyPress = useCallback(
    (e: KeyboardEvent<HTMLInputElement>): void => {
      const character = e.key;
      // use trim() to resolve Safari unexpected white space issue
      const prev = `${displayValue ?? ''}`.replaceAll(',', '').trim();
      setPrevValue(prev);
      setLatestCharacter(character);
    },
    [displayValue]
  );

  return (
    <InputLayout className={className} error={error}>
      <Container>
        <Input
          className="amount-input__input"
          {...baseProps}
          ref={ref}
          value={displayValue}
          onChange={onChange}
          onBlur={onBlur}
          onKeyPress={onKeyPress}
        />
        {props.isHelperTextLoading && <Loading />}
        {!props.isHelperTextLoading && props.helperText && (
          <HelperDescription className="amount-input__helper-text">{props.helperText}</HelperDescription>
        )}
      </Container>
    </InputLayout>
  );
}
