import { Web3Params } from 'adam-frontend-shared';
import { useAtom } from 'jotai';
import web3Atom from 'store/web3Atom';
import { useSessionPreferences } from './useSessionPreferences';

export default function useWeb3Params(): Web3Params {
  const [web3, setWeb3] = useAtom(web3Atom);
  const { platformSelectedChainId } = useSessionPreferences();
  // FIXME: Make variant for platformSelectedChainId when we have production env
  return { platformSelectedChainId, web3, setWeb3 };
}
