import { toNumberWithCommas } from '@adam-vault/adam-sdk';
import { BigNumber } from 'ethers';
import { useCallback, useMemo, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import Button from 'components/Button';
import Icon from 'components/Icon';
import Loading from 'components/Loading';
import OnlyDaoMember from 'components/OnlyDaoMember';
import RowTable from 'components/RowTable';
import { DataModel } from 'components/RowTable/types';
import Text from 'components/Text';
import { USDC_BASE_CURRENCY_DISPLAY } from 'constants/token';
import useTokenSettings from 'hooks/useTokenSettings';
import { calPercentageFromBNs } from 'utils/numberUtils';
import ImportCard from './ImportCard';
import ImportErc20Modal from './ImportErc20Modal';
import { Erc20Asset } from './types';

interface Props {
  data: Erc20Asset[] | undefined;
  totalPriceBN: BigNumber | undefined;
  daoAddress: string;
  emptyStateDescription: string;
  allowImportTokenForMember?: boolean;
  onImportTokenComplete?: () => void;
  onDepositButtonClick: () => void;
}

const IconWrapper = styled.div`
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: ${({ theme: { color } }): string => color.assetIconPlaceholderBackground};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LastRowWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const EmptyStateWrapper = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const EmptyStateDescription = styled(Text).attrs({ category: 'body' })`
  padding: 6px 0 18px 0;
`;

const ZeroBalanceStatement = styled(Text).attrs({ category: 'sub-headline' })`
  color: ${({ theme: { color } }): string => color.fontTertiary};
`;

export default function TokensTable({
  data,
  totalPriceBN,
  daoAddress,
  emptyStateDescription,
  allowImportTokenForMember = false,
  onImportTokenComplete,
  onDepositButtonClick,
}: Props): JSX.Element {
  const { defaultBaseCurrency } = useTokenSettings();
  const { color } = useTheme();
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);

  const fields = useMemo(
    () => [
      {
        key: 'assets',
        text: 'Assets',
        width: 5 / 12,
        renderPreValue: (): JSX.Element => (
          <IconWrapper>
            <Icon icon="ico_entry_token" size="20px" color={color.assetIconColor} />
          </IconWrapper>
        ),
        renderValue: ({ name }: DataModel): JSX.Element => name,
        renderSubValue: ({ symbol }: DataModel): JSX.Element => symbol,
      },
      {
        key: 'balance',
        text: 'Balance',
        width: 5 / 12,
        renderValue: ({ balance, symbol }: DataModel): string => `${toNumberWithCommas(balance)} ${symbol}`,
        renderSubValue: ({ formattedPrice }: DataModel): string | JSX.Element => {
          if (formattedPrice === undefined) {
            return <Loading height={20} width={26} />;
          }
          // Show USD ? if can't get the price
          return `${USDC_BASE_CURRENCY_DISPLAY} ${formattedPrice ? toNumberWithCommas(formattedPrice) : '?'}`;
        },
      },
      {
        key: 'percent',
        text: 'Percentage',
        width: 2 / 12,
        renderValue: ({ priceBN }: DataModel): string | JSX.Element => {
          if (!defaultBaseCurrency || totalPriceBN === undefined || priceBN === undefined) {
            return <Loading />;
          }
          // Show ? % if can't get the price
          return `${
            priceBN ? calPercentageFromBNs(priceBN, totalPriceBN, { decimals: defaultBaseCurrency?.decimals }) : '?'
          } %`;
        },
      },
    ],
    [color.assetIconColor, defaultBaseCurrency, totalPriceBN]
  );

  const handleImportModalOpen = (): void => {
    setIsImportModalOpen(true);
  };

  const handleImportModalClose = (): void => setIsImportModalOpen(false);

  const renderLastRow = useCallback(() => {
    if (data && !data.length) {
      return (
        <EmptyStateWrapper>
          <Text category="title-3" weight="bold">
            It&apos;s empty...
          </Text>
          <EmptyStateDescription>{emptyStateDescription}</EmptyStateDescription>
          <Button icon="deposit_activity" onClick={onDepositButtonClick}>
            DEPOSIT
          </Button>
        </EmptyStateWrapper>
      );
    }

    return (
      <LastRowWrapper>
        <ZeroBalanceStatement>Tokens with zero balance are hidden</ZeroBalanceStatement>
      </LastRowWrapper>
    );
  }, [data, onDepositButtonClick]);

  if (data === undefined) {
    return <Loading isCenter isFullWidth />;
  }

  return (
    <>
      <RowTable fields={fields} data={data} renderLastRow={renderLastRow} />

      {/* Import card and modal */}
      {allowImportTokenForMember && (
        <OnlyDaoMember>
          <>
            <ImportCard onImportButtonClick={handleImportModalOpen} />
            <ImportErc20Modal
              isOpen={isImportModalOpen}
              handleImportModalClose={handleImportModalClose}
              daoAddress={daoAddress}
              onImportComplete={onImportTokenComplete}
            />
          </>
        </OnlyDaoMember>
      )}
    </>
  );
}
