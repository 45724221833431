import { TokenInfoResponse } from '@adam-vault/adam-sdk';
import { useAtom } from 'jotai';
import { useCallback } from 'react';
import { USDC_BASE_CURRENCY_DISPLAY } from 'constants/token';
import { tokenSettingsAtom } from 'store/adamAtom';

export type DefaultBaseCurrency = TokenInfoResponse & {
  displaySymbol?: string;
};

export type UseTokenSettinsReturn = {
  isLoading: boolean;
  supportedTokens: TokenInfoResponse[] | undefined;
  stableCoins: TokenInfoResponse[] | undefined;
  reapTokens: TokenInfoResponse[] | undefined;
  nativeToken: TokenInfoResponse | undefined | null;
  defaultBaseCurrency: DefaultBaseCurrency | undefined | null;
  getBaseCurrencyDisplaySymbol: (address?: string, forTokens?: string[]) => string;
};

export default function useTokenSettings(): UseTokenSettinsReturn {
  const [tokenSettings] = useAtom(tokenSettingsAtom);

  /**
   * Given the address of the base currency and an array of tokens whose values are derived upon it.
   * Return either USD or the symbol of the base currency (from accepted token list).
   * If the base currency is set for more than one tokens, USD should be returned.
   * If the base currency is set for only one token, and the token is the same as base currency, the symbol
   * of base currency should be returned, since there is no currency conversion involved.
   *
   *   e.g.
   *   getBaseCurrencyDisplaySymbol(USDC_address, [USDC_address, DAI_address]) => 'USD'
   *   getBaseCurrencyDisplaySymbol(USDC_address, [USDC_address]) => 'USDC'
   *
   * @param address address of the base currency
   * @param forTokens tokens to be represented by the base currency
   * @returns symbol of the base currency for display purposes
   */
  const getBaseCurrencyDisplaySymbol = useCallback(
    (address?: string, forTokens?: string[]): string => {
      if (!tokenSettings || !tokenSettings.supportedTokens || !tokenSettings.defaultBaseCurrency || !address) {
        return '';
      }

      const isForTokenEqualsBaseCurrency =
        forTokens && forTokens.length === 1 && forTokens[0].toLowerCase() === address.toLowerCase();
      if (
        !isForTokenEqualsBaseCurrency &&
        address.toLowerCase() === tokenSettings.defaultBaseCurrency?.address.toLowerCase()
      ) {
        return USDC_BASE_CURRENCY_DISPLAY;
      }

      return (
        tokenSettings.supportedTokens.find(
          (token: TokenInfoResponse) => token.address.toLowerCase() === address.toLowerCase()
        )?.symbol ?? ''
      );
    },
    [tokenSettings]
  );

  return {
    isLoading: tokenSettings === undefined,
    ...(tokenSettings || {
      supportedTokens: undefined,
      stableCoins: undefined,
      reapTokens: undefined,
      nativeToken: undefined,
      defaultBaseCurrency: undefined,
    }),
    getBaseCurrencyDisplaySymbol,
  };
}
