import { useEffect } from 'react';
import ReactModal from 'react-modal';
import styled, { css, useTheme } from 'styled-components';

import Button from 'components/Button';
import Icon from 'components/Icon';
import ModalWrapper from 'components/ModalWrapper';
import defaultTheme from 'theme/default';

export interface Props extends ReactModal.Props {
  displayMode?: 'normal' | 'absolute';
  isCenterAligned?: boolean;
  isHighestLayer?: boolean;
  onConfirm?: () => void;
  onEditButtonClick?: () => void;
}

const Wrapper = styled.div<{ displayMode: Props['displayMode'] }>`
  min-width: 200px;
  min-height: 100px;
  outline: 0;
  background-color: ${({ theme: { color } }): string => color.panel};
  border-radius: 8px;
  overflow: hidden;
`;

const Content = styled.div<{ displayMode: Props['displayMode']; isCenterAligned: Props['isCenterAligned'] }>`
  width: 100%;
  height: 100%;
  position: relative;
  text-align: ${({ isCenterAligned }) => (isCenterAligned ? 'center' : 'left')};

  ${({ displayMode }) =>
    displayMode === 'normal' &&
    css`
      padding: 40px;
    `}
`;

const CloseBar = styled.div<{ displayMode: Props['displayMode'] }>`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  ${({ displayMode }) =>
    displayMode === 'absolute' &&
    css`
      position: absolute;
      top: 26px;
      right: 26px;
    `}
`;

const TitleContainer = styled.div<{ displayMode: Props['displayMode']; isCenterAligned: Props['isCenterAligned'] }>`
  display: flex;
  margin-bottom: 12px;
  justify-content: ${({ isCenterAligned }) => (isCenterAligned ? 'center' : 'flex-start')};

  ${({ displayMode }) =>
    displayMode === 'absolute' &&
    css`
      position: absolute;
      top: 0;
      left: 0;
    `}
`;
const Title = styled.span`
  font-weight: 700;
  font-size: 32px;
  color: ${({ theme: { color } }): string => color.fontPrimary};
  margin-right: 16px;
`;

const Buttons = styled.div`
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Modal = (props: Props): JSX.Element => {
  const { displayMode = 'normal', isCenterAligned = false } = props;
  const theme = useTheme() as typeof defaultTheme;
  useEffect(() => {
    document.body.style.overflow = props.isOpen ? 'hidden' : '';

    return () => {
      document.body.style.overflow = '';
    };
  }, [props.isOpen]);

  return (
    <ModalWrapper ContentWrapper={Wrapper} isHighestLayer={props.isHighestLayer} {...props}>
      <Content displayMode={displayMode} isCenterAligned={isCenterAligned}>
        {props.onRequestClose && (
          <CloseBar displayMode={displayMode}>
            <button aria-label="Close" onClick={props.onRequestClose}>
              <Icon icon="close" />
            </button>
          </CloseBar>
        )}
        <TitleContainer displayMode={displayMode} isCenterAligned={isCenterAligned}>
          <Title>{props.contentLabel}</Title>
          {props.onEditButtonClick && (
            <button aria-label="edit" onClick={props.onEditButtonClick}>
              <Icon icon="edit2" color={theme.color.fontHighlight} />
            </button>
          )}
        </TitleContainer>
        {props.children}
        {props.onConfirm && (
          <Buttons>
            <Button onClick={props.onConfirm}>Confirm</Button>
          </Buttons>
        )}
      </Content>
    </ModalWrapper>
  );
};

export default Modal;
