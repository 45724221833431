import { Control } from 'react-hook-form';
import styled from 'styled-components';
import AmountInput, { Props as AmountInputProps } from 'components/form/AmountInput';
import DropdownInput from 'components/form/DropdownInput';
import Rect from 'components/layout/Rect';

interface Props {
  tokenOptions: Array<{ text: string; value: string }>;
  control?: Control<any, any>;
  names?: {
    token: string;
    amount: string;
  };
  helperText?: string;
  isHelperTextLoading?: boolean;
  onAmountInputChange?: AmountInputProps['onChange'];
}

const Container = styled(Rect)`
  height: 72px;
  background-color: ${({ theme: { color } }): string => color.textField};
  padding: 0px 16px;
  flex-direction: row;
  align-items: center;
  border-bottom: 2px solid ${({ theme: { color } }): string => color.actionPrimary};
`;

const TokenDropdownInput = styled(DropdownInput)`
  min-width: 94px;
  max-width: 150px;
  width: auto;
  height: 100%;

  .dropdown__button-content {
    min-width: 94px;
    height: 100%;
  }

  .dropdown__description {
    color: ${({ theme: { color } }): string => color.fontPrimary};
    font-weight: 400;
    font-size: 16px;
  }
`;

const StyledAmountInput = styled(AmountInput)`
  background-color: transparent;
  flex: 1;

  .amount_input__input {
    font-size: 16px;

    ::placeholder {
      color: ${({ theme: { color } }): string => color.tertiaryText};
    }
  }

  .amount_input__helper_text {
    color: ${({ theme: { color } }): string => color.tertiaryText};
    font-weight: 400;
    font-size: 16px;
  }
`;

export default function DepositAmountInput(props: Props): JSX.Element {
  const {
    names = {
      token: 'token',
      amount: 'amount',
    },
  } = props;

  return (
    <Container>
      <TokenDropdownInput control={props.control} name={names.token} items={props.tokenOptions} />
      <StyledAmountInput
        control={props.control}
        name={names.amount}
        onChange={props.onAmountInputChange}
        placeholder="Deposit Amount"
        isHelperTextLoading={props.isHelperTextLoading}
        helperText={props.helperText}
      />
    </Container>
  );
}
