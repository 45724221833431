import { trimTrailingZero, SourceType } from '@adam-vault/adam-sdk';
import { BigNumber } from 'ethers';
import { useAdamMemo } from 'hooks/useAdam';
import useTokenSettings from 'hooks/useTokenSettings';
import { AssetPrices, Assets } from '../types';

type ReturnType = {
  assets: Assets;
  assetPrices: AssetPrices | undefined;
  isFetchingAssets: boolean;
  isFetchingPrices: boolean;
  refetch: () => void;
};

export default function useLiquidPoolAssets(daoAddress: string): ReturnType {
  const { defaultBaseCurrency } = useTokenSettings();

  const [assets, { isLoading: isFetchingAssets, refetch }] = useAdamMemo(
    async (adam) => {
      const dao = adam.loadDao(daoAddress);
      const result = await dao.assets(SourceType.LIQUID_POOL);
      return {
        erc20: result
          .filter((r) => +r.balance > 0)
          .map((r) => ({
            address: r.address,
            balanceBN: r.balance,
            balance: trimTrailingZero(r.balanceStr),
            name: r.name,
            symbol: r.symbol,
          })),
      };
    },
    [daoAddress],
    { erc20: [] }
  );

  const [assetPrices, { isLoading: isFetchingPrices }] = useAdamMemo(
    async (adam) => {
      if (!defaultBaseCurrency) {
        return undefined;
      }
      const erc20Prices = await Promise.all(
        assets.erc20.map((tokenInfo) =>
          adam
            .loadFeedRegistry()
            .getPrice(tokenInfo.address, defaultBaseCurrency.address, tokenInfo.balanceBN)
            .catch(() => null)
        )
      );

      return {
        erc20: assets.erc20.reduce(
          (result, tokenInfo, index) => ({
            ...result,
            [tokenInfo.address]: erc20Prices[index],
          }),
          {}
        ),
        erc20TotalPriceBN: erc20Prices.reduce(
          (result, priceResult) => result.add(priceResult?.answer || BigNumber.from(0)),
          BigNumber.from(0)
        ),
      };
    },
    [assets, defaultBaseCurrency],
    undefined
  );

  return {
    assets,
    assetPrices,
    isFetchingAssets,
    isFetchingPrices,
    refetch,
  };
}
