import { MouseEventHandler, useCallback } from 'react';
import { useNavigate, generatePath } from 'react-router-dom';
import styled, { css } from 'styled-components';
import Icon from 'components/Icon';
import Text from 'components/Text';
import { Palette } from 'types/StyledComponentsTheme';

export interface CollapsedMenuProps {
  isCollapsed?: boolean;
}

export const collapseMenuCss = css<CollapsedMenuProps>`
  ${({ isCollapsed }) =>
    isCollapsed &&
    css`
      display: none;
    `}
  opacity: ${({ isCollapsed }) => (isCollapsed ? '0' : '1')};
  transition: opacity 0.2s ease-in;
  width: fit-content;
`;

export interface Props extends CollapsedMenuProps {
  isExternalLink?: boolean;
  isSelected?: boolean;
  disabled?: boolean;
  label: string;
  icon: string;
  to: string;
  index: number;
}

const buttonCss = css`
  width: 100%;
  padding: 10px 8px;
`;

const StyledButton = styled.button<{ disabled?: boolean }>`
  ${buttonCss}
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;

  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

  &:hover {
    border-radius: 8px;
    background-color: ${({ theme }) => theme.color.daoPanelItemHoverBackground};
  }
`;

const StyledAnchor = styled.a`
  ${buttonCss}
  text-decoration: none;
`;

const getColor = (color: Palette, isSelected?: boolean, disabled?: boolean): string => {
  if (isSelected) {
    return color.fontHighlight;
  }
  if (disabled) {
    return color.fontDisabled;
  }
  return color.fontPrimary;
};

const MenuText = styled(Text)<{ isSelected?: boolean; disabled?: boolean; isCollapsed?: boolean }>`
  color: ${({ isSelected, disabled, theme: { color } }) => getColor(color, isSelected, disabled)};
  transition: color 200ms ease;
  ${collapseMenuCss}
`;

export default function MenuLinkItem({
  isExternalLink,
  isSelected,
  disabled,
  isCollapsed,
  label,
  icon,
  to,
  index,
}: Props): JSX.Element {
  const navigate = useNavigate();
  const redirectLink: MouseEventHandler<HTMLButtonElement> = useCallback(
    (event) => {
      event.stopPropagation();
      if (!to) {
        return;
      }
      navigate(generatePath(to));
    },
    [navigate, to]
  );

  if (isExternalLink) {
    return (
      <StyledAnchor href={to} target="_blank" rel="noreferrer">
        <MenuText category="body" weight="bold" isCollapsed={isCollapsed}>
          {label}
        </MenuText>
      </StyledAnchor>
    );
  }

  return (
    <StyledButton onClick={redirectLink} disabled={disabled}>
      <Icon icon={icon} isGradient={isSelected} index={index} />
      <MenuText category="body" weight="bold" isSelected={isSelected} disabled={disabled} isCollapsed={isCollapsed}>
        {label}
      </MenuText>
    </StyledButton>
  );
}
