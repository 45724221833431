/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useMemo, useState, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Modal from 'components/Modal';
import PATHS from 'constants/paths';
import useCurrentDao from 'hooks/useCurrentDao';

const DAO_REGEX = /^\/dao\/([^?/]+)/;
const CREATE_DAO_REGEX = new RegExp(`^${PATHS.DAO.CREATE_DAO}`);
const NEW_DAO_PARAM_REGEX = /\?introduction=true*/;

const MAX_POLLING_ATTEMPT = 20;
const POLLING_INTERVAL = 5000; // 5 seconds

const StyledModal = styled(Modal)`
  width: 500px;
`;

export default function CurrentDaoManager(): JSX.Element | null {
  const location = useLocation();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [attemptNumber, setAttemptNumber] = useState<number>(0);
  const [timeoutIds, setTimeoutIds] = useState<NodeJS.Timeout[]>([]);
  const [isNewDaoQueryDone, setIsNewDaoQueryDone] = useState<boolean>(false);

  const daoAddress = useMemo(() => {
    const matches = location.pathname.match(DAO_REGEX);
    if (!!matches && matches.length > 1) {
      return matches[1].toLowerCase();
    }
    return '';
  }, [location]);

  const { currentDao, doesDaoExist, reloadTotalPrice, reload, clear } = useCurrentDao(daoAddress);

  const currentDaoAddress = currentDao?.address.toLowerCase();

  const isDaoPage = !!location.pathname.match(DAO_REGEX);
  const isCreateDaoPage = !!location.pathname.match(CREATE_DAO_REGEX);
  const isNewDaoPage = isDaoPage && !!location.search.match(NEW_DAO_PARAM_REGEX);
  const isExceptPage = isCreateDaoPage;
  const isExceptRedirectPage = isExceptPage || isNewDaoPage;

  const isRedirectDaoNotFound = isDaoPage && !isExceptRedirectPage && doesDaoExist !== null && !doesDaoExist;

  const clearPolling = useCallback(() => {
    timeoutIds.forEach((timeoutId) => {
      clearTimeout(timeoutId);
    });
  }, [timeoutIds]);
  const executePolling = useCallback(async (): Promise<void> => {
    if (isNewDaoQueryDone) {
      return;
    }
    await reload();
    setAttemptNumber((prevState) => prevState + 1);
    const timeoutId = setTimeout(executePolling, POLLING_INTERVAL);
    setTimeoutIds((prevState) => {
      const ids = [...prevState];
      ids.push(timeoutId);
      return ids;
    });
  }, [isNewDaoQueryDone, reload]);

  useEffect(() => {
    if (currentDaoAddress === daoAddress || attemptNumber >= MAX_POLLING_ATTEMPT) {
      setIsNewDaoQueryDone(true);
    }
  }, [currentDaoAddress, daoAddress, clearPolling, attemptNumber]);

  useEffect(() => {
    if (!isNewDaoQueryDone) {
      return;
    }
    clearPolling();
  }, [clearPolling, isNewDaoQueryDone]);

  useEffect(() => {
    setIsNewDaoQueryDone(false);
  }, [daoAddress]);

  useEffect(() => {
    if (!isNewDaoPage) {
      return;
    }
    setAttemptNumber(0);
    executePolling();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNewDaoPage]);

  useEffect(() => {
    if (isExceptPage) {
      clear();
      return;
    }

    reload();
  }, [reload, isExceptPage, clear]);

  useEffect(() => {
    reloadTotalPrice();
  }, [location, reloadTotalPrice]);

  useEffect(() => {
    if (!daoAddress) {
      return;
    }
    if (isRedirectDaoNotFound) {
      navigate(PATHS.NOTFOUND);
    }
    setIsModalOpen(isRedirectDaoNotFound);
  }, [isRedirectDaoNotFound, navigate, daoAddress]);

  const onRequestClose = (): void => {
    setIsModalOpen(false);
  };

  return (
    <StyledModal isOpen={isModalOpen} onRequestClose={onRequestClose} contentLabel="Vault Not Found">
      Please check Vault address.
    </StyledModal>
  );
}
