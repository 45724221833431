import { Wallet as WalletConstants } from 'adam-frontend-shared';
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import Button from 'components/Button';
import GeneratedAvatar from 'components/GeneratedAvatar';
import Img from 'components/layout/Img';
import Loading from 'components/Loading';
import useWeb3 from 'hooks/useWeb3';
import { toEllipsisAddress } from 'utils/formatUtils';
import { getEthereumName } from 'utils/web3Utils';

export interface Props {
  className?: string;
  walletType: WalletConstants.WalletType;
  address?: string | null;
  size?: number;
  onClick?: () => void;
}

const { Wallet } = WalletConstants;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
`;

const WalletIconImg = styled(Img)<{ size: number }>`
  margin-right: 12px;
  height: ${({ size }) => size}px;
`;

const IconWrapper = styled.div`
  margin-left: 12px;
  display: flex;
  align-items: center;
`;

export default function WalletButton(props: Props): JSX.Element {
  const { provider } = useWeb3();

  const { className, size = 16, walletType, address } = props;
  const [displayAddress, setDisplayAddress] = useState('');
  const [isLoadingAddress, setIsLoadingAddress] = useState(true);

  useEffect(() => {
    async function updateDisplayAddress(): Promise<void> {
      setIsLoadingAddress(true);

      if (!provider || !address) {
        return;
      }
      const ensDomain = await getEthereumName(address, provider);
      setDisplayAddress(ensDomain || address);

      setIsLoadingAddress(false);
    }

    updateDisplayAddress();
  }, [address, provider]);

  return (
    <StyledButton variant="plain" onClick={props.onClick} className={className}>
      {walletType && <WalletIconImg src={Wallet[walletType].logo} size={size} />}
      {isLoadingAddress ? <Loading /> : toEllipsisAddress(displayAddress)}
      <IconWrapper>
        <GeneratedAvatar variant="pixel" size={size} seed={address || ''} />
      </IconWrapper>
    </StyledButton>
  );
}
