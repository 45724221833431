import styled from 'styled-components';
import { space, SpaceProps } from 'styled-system';

const Title = styled.p<SpaceProps>`
  display: flex;
  align-items: center;
  margin: 0;
  font-size: 24px;
  font-weight: 600;
  color: ${({ theme: { color } }) => color.fontPrimary};
  ${space}
`;

export default Title;
