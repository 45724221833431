import { MouseEventHandler } from 'react';
import styled from 'styled-components';

export interface AvatarProps {
  className?: string;
  size?: string | number;
  onClick?: MouseEventHandler<HTMLDivElement>;
}

const Avatar = styled.div<AvatarProps>`
  height: ${({ size }): string => (!!size?.toString() ? size.toString() : '40px')};
  cursor: ${({ onClick }): string => (!!onClick ? 'pointer' : 'unset')};
`;

export default Avatar;
