import styled from 'styled-components';
import Button from 'components/Button';

const ActionButton = styled(Button)`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
`;

export default ActionButton;
