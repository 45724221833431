import styled from 'styled-components';

export interface Props extends React.HTMLAttributes<HTMLLabelElement> {}

const Label = styled.label<Props>`
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme: { color } }) => color.fontSecondary};
`;

export default Label;
