import BoringAvatar from 'boring-avatars';
import { useTheme } from 'styled-components';
import Avatar, { AvatarProps } from 'components/layout/Avatar';

export type GeneratedAvatarVariant = 'marble' | 'pixel';

export interface Props extends AvatarProps {
  seed: string;
  variant?: GeneratedAvatarVariant;
}

export default function GeneratedAvatar(props: Props): JSX.Element {
  const { variant = 'marble', size = '40px', onClick } = props;
  const theme: any = useTheme();
  const colorSet = variant === 'marble' ? theme.color.daoAvatarColorSet : theme.color.addressAvatarColorSet;

  return (
    <Avatar className={props.className} size={size} onClick={onClick}>
      <BoringAvatar size={size} name={props.seed} variant={variant} colors={colorSet} />
    </Avatar>
  );
}
