import { useAtom } from 'jotai';
import { useCallback, useMemo } from 'react';
import { GlobalModal } from 'constants/globalModal';
import connectModalAtom from 'store/connectModalAtom';
import isModalOpenAtom from 'store/isJoinDaoModalOpenAtom';
import joinDaoAndDepositModalAtom from 'store/joinDaoAndDepositModalAtom';

type ReturnType = {
  isModalOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
};

export default function useGlobalModal(type: GlobalModal): ReturnType {
  const [connectModal, setConnectModal] = useAtom(connectModalAtom);
  const [joinDaoModal, setJoinDaoModal] = useAtom(isModalOpenAtom);
  const [joinDaoAndDepositModal, setJoinDaoAndDepositModal] = useAtom(joinDaoAndDepositModalAtom);

  const isModalOpen = useMemo(() => {
    switch (type) {
      case GlobalModal.CONNECT:
        return connectModal.isModalOpen;
      case GlobalModal.JOIN_DAO:
        return joinDaoModal.isModalOpen;
      case GlobalModal.JOIN_DAO_AND_DEPOSIT:
        return joinDaoAndDepositModal.isModalOpen;
    }
  }, [connectModal.isModalOpen, joinDaoAndDepositModal.isModalOpen, joinDaoModal.isModalOpen, type]);

  const openModal = useCallback(() => {
    switch (type) {
      case GlobalModal.CONNECT:
        setConnectModal({ isModalOpen: true });
        break;
      case GlobalModal.JOIN_DAO:
        setJoinDaoModal({ isModalOpen: true });
        break;
      case GlobalModal.JOIN_DAO_AND_DEPOSIT:
        setJoinDaoAndDepositModal({ isModalOpen: true });
        break;
    }
  }, [setConnectModal, setJoinDaoAndDepositModal, setJoinDaoModal, type]);

  const closeModal = useCallback(() => {
    switch (type) {
      case GlobalModal.CONNECT:
        setConnectModal({ isModalOpen: false });
        break;
      case GlobalModal.JOIN_DAO:
        setJoinDaoModal({ isModalOpen: false });
        break;
      case GlobalModal.JOIN_DAO_AND_DEPOSIT:
        setJoinDaoAndDepositModal({ isModalOpen: false });
        break;
    }
  }, [setConnectModal, setJoinDaoAndDepositModal, setJoinDaoModal, type]);

  return {
    isModalOpen,
    openModal,
    closeModal,
  };
}
