import styled from 'styled-components';
import exclamationMarkIcon from 'assets/images/exclamation_mark.svg';

export interface Props {
  className?: string;
  msg?: string;
}

const ErrorMsgWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  color: ${({ theme: { color } }): string => color.critical};
  font-size: 16px;
  font-weight: 400;
`;

export default function ErrorMsg({ className, msg }: Props): JSX.Element {
  return (
    <ErrorMsgWrapper className={className}>
      <img src={exclamationMarkIcon} alt="requirement-error" />
      {msg}
    </ErrorMsgWrapper>
  );
}
