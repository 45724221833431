import { atom } from 'jotai';

interface ModalArg {
  isModalOpen: boolean;
}

const isModalOpenAtom = atom<boolean>(false);

const joinDaoAndDepositModalAtom = atom(
  (get) => ({
    isModalOpen: get(isModalOpenAtom),
  }),
  async (get, set, arg: ModalArg) => {
    const { isModalOpen } = arg;
    if (typeof isModalOpen === 'boolean') {
      set(isModalOpenAtom, isModalOpen);
    }
  }
);

export default joinDaoAndDepositModalAtom;
