import isNil from 'lodash/isNil';
import styled, { css } from 'styled-components';
import Description from 'components/layout/Description';
import Rect from 'components/layout/Rect';
import Title from 'components/layout/Title';
import Loading from 'components/Loading';

export interface Props {
  label: string;
  value?: string | number | null;
  isHighlighted?: boolean;
}

const Container = styled(Rect)`
  display: inline-block;
  padding-left: 20px;
  padding-right: 20px;
`;

const Label = styled(Description)`
  color: ${({ theme: { color } }) => color.fontSecondaryLight};
`;

const Value = styled(Title)<{ isHighlighted?: Props['isHighlighted'] }>`
  ${({ isHighlighted, theme: { color } }) =>
    isHighlighted &&
    css`
      background: linear-gradient(90deg, ${color.primary} 0%, ${color.secondary} 100%);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      text-fill-color: transparent;
    `};
`;

export default function InfoItemCard(props: Props): JSX.Element {
  const isNilValue = isNil(props.value);

  return (
    <Container>
      <Label>{props.label}</Label>
      {isNilValue && <Loading />}
      {!isNilValue && <Value isHighlighted={props.isHighlighted}>{props.value}</Value>}
    </Container>
  );
}
