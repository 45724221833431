import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import DaoAvatar from 'components/DaoAvatar';
import Loading from 'components/Loading';
import { DaoPanelViewState } from 'constants/daoParam';
import { useAdamMemo } from 'hooks/useAdam';
import useCurrentDao from 'hooks/useCurrentDao';
import { Dao as DaoType } from '../hooks/useJoinedDaos';

export interface Props {
  dao: DaoType;
}

const DaoListItemButton = styled.button.attrs({ tabIndex: -1 })`
  display: flex;
  gap: 16px;
  align-items: center;
  text-align: left;
  font-weight: bold;
  font-size: 16px;
  padding: 10px 24px;

  &:hover {
    background-color: ${({ theme: { color } }): string => color.daoPanelItemHoverBackground};
  }
`;

export default function DaoListItem({ dao }: Props): JSX.Element {
  const { id: daoAddress, name: daoName } = dao;
  const { setDaoPanelView } = useCurrentDao();
  const navigate = useNavigate();

  const [daoLogoCID, { isLoading }] = useAdamMemo(
    async (adam) => {
      const daoEntity = adam.loadDao(daoAddress);
      const logoCID = await daoEntity.logoCID();
      return !!logoCID ? logoCID : '';
    },
    [daoAddress],
    ''
  );

  const onClickDaoListItem = useCallback(() => {
    navigate(`/dao/${daoAddress}/`);
    setDaoPanelView(DaoPanelViewState.INITIAL);
  }, [navigate, setDaoPanelView, daoAddress]);

  return (
    <DaoListItemButton onClick={onClickDaoListItem}>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <DaoAvatar daoAddress={daoAddress} logoCID={daoLogoCID} />
          {daoName}
        </>
      )}
    </DaoListItemButton>
  );
}
