import { SourceType } from '@adam-vault/adam-sdk';
import { useCallback, useMemo, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import Loading from 'components/Loading';
import { AssetSource } from 'constants/asset';
import PATHS from 'constants/paths';
import useCurrentDao from 'hooks/useCurrentDao';
import MemberTokenTab from './MemberTokenTab';
import NftTab from './NftTab';
import TabBar from './TabBar';
import TokensTable from './TokensTable';
import TopBar, { TopBarButton } from './TopBar';
import { AssetPrices, Assets } from './types';

export interface Props {
  sourceType?: AssetSource;
  daoAddress: string;
  lpAddress: string;

  // assets related
  isFetchingAssets: boolean;
  assets: Assets;
  assetPrices: AssetPrices | undefined;
  refetchAssets: () => void;
}

enum AssetType {
  TOKENS = 'tokens',
  MEMBER_TOKEN = 'member-token',
  NFT = 'nft',
}

export default function PublicAccountSection({
  sourceType,
  daoAddress,
  isFetchingAssets,
  assets,
  assetPrices,
  refetchAssets,
}: Props): JSX.Element {
  const [currentAssetType, setCurrentAssetType] = useState(AssetType.TOKENS);
  const { currentDao } = useCurrentDao();
  const data = useMemo(() => {
    if (!assets?.erc20) {
      return [];
    }

    return assets?.erc20.map((tokenInfo) => {
      const price = assetPrices?.erc20?.[tokenInfo.address];

      return {
        ...tokenInfo,
        priceBN: price === null ? null : price?.answer,
        formattedPrice: price === null ? null : price?.answerStr,
      };
    });
  }, [assets?.erc20, assetPrices?.erc20]);

  const navigate = useNavigate();

  const assetTypeTabs = useMemo(
    () => [
      {
        id: AssetType.TOKENS,
        label: 'Tokens',
      },
      // Show Member Token tab if member token of DAO is set
      ...(currentDao?.data.memberToken
        ? [
            {
              id: AssetType.MEMBER_TOKEN,
              label: 'Member Token',
            },
          ]
        : []),
      {
        id: AssetType.NFT,
        label: 'NFT',
      },
    ],
    [currentDao?.data.memberToken]
  );

  const handleOnImportComplete = (): void => {
    refetchAssets();
  };

  const redirectToDepositPage = useCallback((): void => {
    navigate(generatePath(PATHS.DAO.DEPOSIT_SPECIFIC, { daoAddress, to: SourceType.TREASURY }));
  }, [daoAddress, navigate]);

  const renderTopBarButtons = useCallback(
    () => (
      <TopBarButton icon="deposit_activity" onClick={redirectToDepositPage}>
        DEPOSIT
      </TopBarButton>
    ),
    [redirectToDepositPage]
  );

  const handleAssetTypeChange = useCallback((_, tabId) => setCurrentAssetType(tabId), [setCurrentAssetType]);

  const navigateToTransactionLogsPage = (): void => {
    navigate(generatePath(PATHS.DAO.TRANSACTION_LOGS, { daoAddress, source: sourceType }));
  };

  if (isFetchingAssets) {
    return <Loading isCenter />;
  }

  return (
    <>
      <TopBar
        title="Public Account"
        address={daoAddress}
        description="Vault assets are safely kept here. They do not belong to any Vault members."
        renderButtons={renderTopBarButtons}
      />
      <TabBar
        tabs={assetTypeTabs}
        focusedTabId={currentAssetType}
        onTabChange={handleAssetTypeChange}
        onTransactionsButtonClick={navigateToTransactionLogsPage}
      />

      {currentAssetType === AssetType.TOKENS && (
        <TokensTable
          data={data}
          totalPriceBN={assetPrices?.erc20TotalPriceBN}
          daoAddress={daoAddress}
          emptyStateDescription="Start depositing tokens to Public Account now!"
          allowImportTokenForMember
          onImportTokenComplete={handleOnImportComplete}
          onDepositButtonClick={redirectToDepositPage}
        />
      )}
      {currentAssetType === AssetType.MEMBER_TOKEN && <MemberTokenTab daoAddress={daoAddress} />}
      {currentAssetType === AssetType.NFT && <NftTab daoAddress={daoAddress} />}
    </>
  );
}
