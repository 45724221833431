import { useState, useCallback, MouseEventHandler } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';
import DaoAvatar from 'components/DaoAvatar';
import Icon from 'components/Icon';
import StylelessButton from 'components/layout/StylelessButton';
import Loading from 'components/Loading';
import Text from 'components/Text';
import PATHS from 'constants/paths';
import { useAdamMemo } from 'hooks/useAdam';
import useCurrentDao from 'hooks/useCurrentDao';
import useWeb3 from 'hooks/useWeb3';
import AddMemberModal from './AddMemberModal';

export interface CollapsedMenuProps {
  isCollapsed?: boolean;
}

export const collapseMenuCss = css<CollapsedMenuProps>`
  ${({ isCollapsed }) =>
    isCollapsed
      ? css`
          height: 0;
          width: 0;
          opacity: 0;
        `
      : css`
          height: auto;
          width: auto;
          opacity: 1;
        `}
  overflow: hidden;
  transition: opacity 0.5s ease, width 0.5s ease, height 0.5s ease;
`;

export interface Props extends CollapsedMenuProps {
  onSwitchDaoClick?: () => void;
}

type ClickableProps = {
  onClick?: Props['onSwitchDaoClick'];
};

const Container = styled.div<CollapsedMenuProps>`
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: ${({ isCollapsed }) => (isCollapsed ? '55px' : '85px')};
  transition: height 0.5s ease;
`;

const AvatarAndAddMember = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  height: 20px;
`;

const AddMemberIcon = styled(Icon)<CollapsedMenuProps>`
  cursor: pointer;
  ${collapseMenuCss}
`;

const DaoTitle = styled(Text)<ClickableProps & CollapsedMenuProps>`
  cursor: ${({ onClick }): string => (!!onClick ? 'pointer' : 'unset')};
  ${collapseMenuCss}
`;

const SwitchDaoTextButton = styled(StylelessButton)<ClickableProps>`
  display: flex;
  align-items: center;
  width: auto;
  cursor: ${({ onClick }): string => (!!onClick ? 'pointer' : 'unset')};
`;

export default function InfoSection({ isCollapsed, onSwitchDaoClick }: Props): JSX.Element {
  const { isLoading, currentDao } = useCurrentDao();
  const { isConnected } = useWeb3();
  const navigate = useNavigate();
  const [daoLogoCID, { isLoading: isUseAdamLoading }] = useAdamMemo(
    async () => {
      const logoCID = await currentDao?.logoCID();
      return !!logoCID ? logoCID : '';
    },
    [currentDao],
    ''
  );

  const [isModalOpen, setIsModalOpen] = useState(false);

  const daoAddress = currentDao?.address.toLowerCase() ?? '';

  const onAddMemberModalOpen = () => {
    setIsModalOpen(true);
  };

  const onAddMemberModalClose = () => {
    setIsModalOpen(false);
  };

  const onClickDaoAvatar: MouseEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      e.stopPropagation();
      const assetsPath = generatePath(PATHS.DAO.ASSETS, { daoAddress });
      navigate(assetsPath);
    },
    [navigate, daoAddress]
  );

  return (
    <Container isCollapsed={isCollapsed}>
      {isLoading || isUseAdamLoading ? (
        <Loading />
      ) : (
        <>
          <AvatarAndAddMember>
            <DaoAvatar size="32px" daoAddress={daoAddress} logoCID={daoLogoCID} onClick={onClickDaoAvatar} />
            <AddMemberIcon icon="add_member1" onClick={onAddMemberModalOpen} isCollapsed={isCollapsed} />
          </AvatarAndAddMember>
          <DaoTitle category="headline-2" weight="bold" isCollapsed={isCollapsed} onClick={onSwitchDaoClick}>
            {currentDao?.data.name}
          </DaoTitle>
          {isConnected && !isCollapsed && (
            <SwitchDaoTextButton onClick={onSwitchDaoClick}>
              <Text category="footnote">Switch Vault</Text> <Icon icon="ico_switch" size="24px" />
            </SwitchDaoTextButton>
          )}
          {!isCollapsed && <AddMemberModal isOpen={isModalOpen} closeModal={onAddMemberModalClose} />}
        </>
      )}
    </Container>
  );
}
