import formatDate from 'date-fns/format';
import fromUnixTime from 'date-fns/fromUnixTime';
import getUnixTime from 'date-fns/getUnixTime';
import { SECONDS_PER_MINUTE, SECONDS_PER_HOUR, SECONDS_PER_DAY } from 'constants/datetime';
import { formatUnixTime } from './formatUtils';

export const fromUnixTimeToDate = (unixTime: number | string): Date => fromUnixTime(Number(unixTime));

export const fromDateToUnixTime = (date: Date): number => getUnixTime(date);

export const getCurrentUnixTime = (): number => {
  const date = new Date();
  date.setSeconds(0, 0);
  return getUnixTime(date);
};

export const toShortDateString = (date: Date): string =>
  `${date.toLocaleString('en-us', {
    month: 'short',
    year: 'numeric',
  })}`;

export const toDatetimeString = (date: Date): string =>
  `${date.toLocaleString('en-uk', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
  })}`;

export const toCustomDateString = (date: Date, format: string): string => formatDate(date, format);

export const fromTimestampToShortDateString = (timestamp: number): string =>
  toShortDateString(new Date(timestamp * 1000));

export const fromTimestampToDatetimeString = (timestamp: number): string =>
  toDatetimeString(new Date(timestamp * 1000));

export const fromTimestampToDateString = (timestamp: number): string =>
  formatUnixTime(timestamp.toString(), 'dd MMM yyyy').toUpperCase();

export const fromDateTimeStringToTimestamp = (dateTimeString: string): number => Date.parse(dateTimeString) / 1000;

export enum TimeUnitsEnum {
  day,
  hour,
  minute,
  second,
}

type TimeUnits = { [unit in keyof typeof TimeUnitsEnum]: number };

/**
 *
 * @param seconds number of seconds in total
 * @returns the number of day(s), hour(s), minute(s) and second(s) converted from `seconds`
 */
export const fromSecondsToDHMS = (seconds: number | string): TimeUnits => {
  const target = Number(seconds);
  const day = Math.floor(target / SECONDS_PER_DAY);
  const hour = Math.floor((target % SECONDS_PER_DAY) / SECONDS_PER_HOUR);
  const minute = Math.floor((target % SECONDS_PER_HOUR) / SECONDS_PER_MINUTE);
  const second = Math.floor(target % SECONDS_PER_MINUTE);
  return { day, hour, minute, second };
};

/**
 *
 * @param seconds number of seconds in total
 * @param unitType the time unit you would like to convert your `seconds` to
 * @returns the number of day(s)/hour(s)/minute(s)/second(s) converted from `seconds`
 */
export const fromSecondsToFullDHMS = (seconds: number | string, unitType: TimeUnitsEnum): number => {
  const target = Number(seconds);
  if (unitType === TimeUnitsEnum.day) {
    return target / SECONDS_PER_DAY;
  }
  if (unitType === TimeUnitsEnum.hour) {
    return target / SECONDS_PER_HOUR;
  }
  if (unitType === TimeUnitsEnum.minute) {
    return target / SECONDS_PER_MINUTE;
  }
  return target;
};

type TimeUnitsDisplay = { [unit in keyof typeof TimeUnitsEnum as `${unit}Display`]: string };
type TimeUnitsDisplayCustomProcessFunction = (input: string) => string;

/**
 * (FUTURE WARNING: should take account of i18n)
 * @param units number of day(s), hour(s), minute(s), second(s)
 * @param customProcessingFunc a function for post-processing the time unit display string
 * @returns display strings of each time unit, taking plurality into consideration
 */
export const getTimeUnitsDisplay = (
  units: TimeUnits,
  customProcessingFunc?: TimeUnitsDisplayCustomProcessFunction
): TimeUnitsDisplay => {
  const dayWord = 'day';
  const hourWord = 'hour';
  const minuteWord = 'minute';
  const secondWord = 'second';
  let dayDisplay = units.day === 1 ? `1 ${dayWord}` : `${units.day} ${dayWord}s`;
  let hourDisplay = units.hour === 1 ? `1 ${hourWord}` : `${units.hour} ${hourWord}s`;
  let minuteDisplay = units.minute === 1 ? `1 ${minuteWord}` : `${units.minute} ${minuteWord}s`;
  let secondDisplay = units.second === 1 ? `1 ${secondWord}` : `${units.second} ${secondWord}s`;
  dayDisplay = !!customProcessingFunc ? customProcessingFunc(dayDisplay) : dayDisplay;
  hourDisplay = !!customProcessingFunc ? customProcessingFunc(hourDisplay) : hourDisplay;
  minuteDisplay = !!customProcessingFunc ? customProcessingFunc(minuteDisplay) : minuteDisplay;
  secondDisplay = !!customProcessingFunc ? customProcessingFunc(secondDisplay) : secondDisplay;
  return { dayDisplay, hourDisplay, minuteDisplay, secondDisplay };
};
