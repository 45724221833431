import styled, { useTheme } from 'styled-components';
import Icon from 'components/Icon';
import defaultTheme from 'theme/default';

interface Props {
  className?: string;
  msg?: string;
}

const WarningMsgWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${({ theme: { color } }): string => color.warning};
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
`;

export default function WarningMsg({ className, msg }: Props): JSX.Element {
  const { color } = useTheme() as typeof defaultTheme;

  return (
    <WarningMsgWrapper className={className}>
      <Icon icon="field_warning" color={color.warning} size="18px" />
      {msg}
    </WarningMsgWrapper>
  );
}
