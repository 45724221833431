import styled from 'styled-components';

export interface Props extends React.ImgHTMLAttributes<HTMLImageElement> {}

const Img = styled.img<Props>`
  // Fix unexpected margin issue
  display: block;
`;

export default Img;
