import { Adam, Dao, LiquidPool, Network } from '@adam-vault/adam-sdk';

export type SupportedFeatureType = {
  [key in Feature]: boolean;
};

enum ContractType {
  ADAM = 'ADAM',
  DAO = 'DAO',
  LIQUID_POOL = 'LIQUID_POOL',
}

enum AdamVersion {
  V1 = 'V1',
  V2 = 'V2',
}

enum DAOVersion {
  V1 = 'V1',
  V2 = 'V2',
}

enum LiquidPoolVersion {
  V1 = 'V1',
  V2 = 'V2',
}

export enum Feature {
  BASIC = 'BASIC',
  BUDGET_DESTINATION_TEAM = 'BUDGET_DESTINATION_TEAM',
  CREATE_DAO_REFERRAL = 'CREATE_DAO_REFERRAL',
  EXISTING_VOTING_TOKEN = 'EXISTING_VOTING_TOKEN',
  QUIT_VAULT = 'QUIT_VAULT',
  REVOKE_BUDGET_APPROVAL = 'REVOKE_BUDGET_APPROVAL',
  SWAP = 'SWAP',
  SWAP_ANY_FROM_TREASURY = 'SWAP_ANY_FROM_TREASURY',

  // Beta features
  REAP_CARD = 'REAP_CARD',
}

export const UISupportedFeature: SupportedFeatureType = {
  [Feature.BASIC]: true,
  [Feature.BUDGET_DESTINATION_TEAM]: true,
  [Feature.CREATE_DAO_REFERRAL]: true,
  [Feature.EXISTING_VOTING_TOKEN]: true,
  [Feature.QUIT_VAULT]: true,
  [Feature.REVOKE_BUDGET_APPROVAL]: true,
  [Feature.SWAP]: true,
  [Feature.SWAP_ANY_FROM_TREASURY]: true,
  [Feature.REAP_CARD]: true,
};

const ALL_NETWORKS = Object.values(Network);

const FeatureNetworkMapping: { [key in Feature]: Network[] } = {
  [Feature.BASIC]: ALL_NETWORKS,
  [Feature.BUDGET_DESTINATION_TEAM]: ALL_NETWORKS,
  [Feature.CREATE_DAO_REFERRAL]: [Network.GOERLI, Network.ARBITRUM_GOERLI],
  [Feature.EXISTING_VOTING_TOKEN]: ALL_NETWORKS,
  [Feature.QUIT_VAULT]: ALL_NETWORKS,
  [Feature.REVOKE_BUDGET_APPROVAL]: ALL_NETWORKS,
  [Feature.SWAP]: [Network.MAINNET, Network.GOERLI],
  [Feature.SWAP_ANY_FROM_TREASURY]: ALL_NETWORKS,
  [Feature.REAP_CARD]: ALL_NETWORKS,
};

type VersionMapping = {
  [ContractType.DAO]: DAOVersion[];
  [ContractType.ADAM]: AdamVersion[];
  [ContractType.LIQUID_POOL]: LiquidPoolVersion[];
};

const ALL_ADAM_VERSIONS = Object.values(AdamVersion);
const ALL_DAO_VERSIONS = Object.values(DAOVersion);
const ALL_LIQUID_POOL_VERSIONS = Object.values(LiquidPoolVersion);

const FeatureVersionMapping: { [key in Feature]: VersionMapping } = {
  [Feature.BASIC]: {
    [ContractType.ADAM]: ALL_ADAM_VERSIONS,
    [ContractType.DAO]: ALL_DAO_VERSIONS,
    [ContractType.LIQUID_POOL]: ALL_LIQUID_POOL_VERSIONS,
  },
  [Feature.BUDGET_DESTINATION_TEAM]: {
    [ContractType.ADAM]: [AdamVersion.V2],
    [ContractType.DAO]: ALL_DAO_VERSIONS,
    [ContractType.LIQUID_POOL]: ALL_LIQUID_POOL_VERSIONS,
  },
  [Feature.CREATE_DAO_REFERRAL]: {
    [ContractType.ADAM]: [AdamVersion.V2],
    [ContractType.DAO]: ALL_DAO_VERSIONS,
    [ContractType.LIQUID_POOL]: ALL_LIQUID_POOL_VERSIONS,
  },
  [Feature.EXISTING_VOTING_TOKEN]: {
    [ContractType.ADAM]: [AdamVersion.V2],
    [ContractType.DAO]: [DAOVersion.V2],
    [ContractType.LIQUID_POOL]: ALL_LIQUID_POOL_VERSIONS,
  },
  [Feature.QUIT_VAULT]: {
    [ContractType.ADAM]: ALL_ADAM_VERSIONS,
    [ContractType.DAO]: [DAOVersion.V2],
    [ContractType.LIQUID_POOL]: ALL_LIQUID_POOL_VERSIONS,
  },
  [Feature.REVOKE_BUDGET_APPROVAL]: {
    [ContractType.ADAM]: ALL_ADAM_VERSIONS,
    [ContractType.LIQUID_POOL]: [LiquidPoolVersion.V2],
    [ContractType.DAO]: [DAOVersion.V2],
  },
  [Feature.SWAP]: {
    [ContractType.ADAM]: ALL_ADAM_VERSIONS,
    [ContractType.DAO]: ALL_DAO_VERSIONS,
    [ContractType.LIQUID_POOL]: ALL_LIQUID_POOL_VERSIONS,
  },
  [Feature.SWAP_ANY_FROM_TREASURY]: {
    [ContractType.ADAM]: ALL_ADAM_VERSIONS,
    [ContractType.DAO]: [DAOVersion.V1, DAOVersion.V2],
    [ContractType.LIQUID_POOL]: ALL_LIQUID_POOL_VERSIONS,
  },
  [Feature.REAP_CARD]: {
    [ContractType.ADAM]: ALL_ADAM_VERSIONS,
    [ContractType.DAO]: ALL_DAO_VERSIONS,
    [ContractType.LIQUID_POOL]: ALL_LIQUID_POOL_VERSIONS,
  },
};

type AdamVersionVerifier = { [key in AdamVersion]: (adamEntity: Adam) => Promise<boolean> };
type DAOVersionVerifier = { [key in DAOVersion]: (daoEntity: Dao) => Promise<boolean> };
type LiquidPoolVersionVerifier = { [key in LiquidPoolVersion]: (liquidPoolEntity: LiquidPool) => Promise<boolean> };

type VersionVerifierType = {
  [ContractType.ADAM]: AdamVersionVerifier;
  [ContractType.DAO]: DAOVersionVerifier;
  [ContractType.LIQUID_POOL]: LiquidPoolVersionVerifier;
};

const VersionVerifier: VersionVerifierType = {
  [ContractType.ADAM]: {
    [AdamVersion.V1]: async () => true,
    [AdamVersion.V2]: async (adamEntity: Adam) => adamEntity.isV2Supported && adamEntity.isV2Supported(),
  },
  [ContractType.DAO]: {
    [DAOVersion.V1]: async () => true,
    [DAOVersion.V2]: async (daoEntity: Dao) => {
      const dao = daoEntity as Dao;
      return dao.isV2Supported && dao.isV2Supported();
    },
  },
  [ContractType.LIQUID_POOL]: {
    [LiquidPoolVersion.V1]: async () => true,
    [LiquidPoolVersion.V2]: async (liquidPoolEntity: LiquidPool) => {
      const liquidPool = liquidPoolEntity as LiquidPool;
      return liquidPool.isV2Supported && liquidPool.isV2Supported();
    },
  },
};

export const getAdamVersion = async (adamEntity: Adam): Promise<AdamVersion> => {
  const adamVersionsReverse = Object.keys(VersionVerifier.ADAM).reverse() as unknown as AdamVersion[];
  for (const ver of adamVersionsReverse) {
    if (await VersionVerifier[ContractType.ADAM][ver](adamEntity)) {
      return ver;
    }
  }

  return AdamVersion.V1;
};

export const getDaoVersion = async (daoEntity: Dao): Promise<DAOVersion> => {
  const daoVersionsReverse = Object.keys(VersionVerifier.DAO).reverse() as unknown as DAOVersion[];
  for (const ver of daoVersionsReverse) {
    if (await VersionVerifier.DAO[ver](daoEntity)) {
      return ver;
    }
  }

  return DAOVersion.V1;
};

export const getLiquidPoolVersion = async (liquidPoolEntity: LiquidPool): Promise<LiquidPoolVersion> => {
  const liquidPoolVersionsReverse = Object.keys(
    VersionVerifier.LIQUID_POOL
  ).reverse() as unknown as LiquidPoolVersion[];
  for (const ver of liquidPoolVersionsReverse) {
    if (await VersionVerifier[ContractType.LIQUID_POOL][ver](liquidPoolEntity)) {
      return ver;
    }
  }

  return LiquidPoolVersion.V1;
};

export const getDaoSupportedVersions = async (daoEntity: Dao): Promise<DAOVersion[]> => {
  const versions = Object.keys(VersionVerifier[ContractType.DAO]) as unknown as DAOVersion[];
  const allVersionCheckings = await Promise.all(versions.map((ver) => VersionVerifier.DAO[ver](daoEntity)));

  return versions.reduce((supportedVersions, version, index) => {
    if (allVersionCheckings[index]) {
      supportedVersions.push(version);
    }
    return supportedVersions;
  }, [] as DAOVersion[]);
};

const isFeatureSupportedByUI = (feature: Feature): boolean => UISupportedFeature[feature];

const isFeatureSupportedByNetwork = (network: Network, feature: Feature): boolean =>
  FeatureNetworkMapping[feature].includes(network);

export const isFeatureSupportedByDAO = async (daoEntity: Dao, feature: Feature): Promise<boolean> => {
  if (!isFeatureSupportedByUI(feature) || !isFeatureSupportedByNetwork(daoEntity.network, feature)) {
    return false;
  }

  const daoVersion = await getDaoVersion(daoEntity);
  return FeatureVersionMapping[feature][ContractType.DAO]?.includes(daoVersion) || true;
};

export const getAdamSupportedFeatures = async (entity: Adam): Promise<SupportedFeatureType> => {
  const version = await getAdamVersion(entity);
  const features = Object.keys(FeatureVersionMapping) as unknown as Feature[];

  return features.reduce(
    (result, feature: Feature) => ({
      ...result,
      [feature]:
        isFeatureSupportedByUI(feature) &&
        isFeatureSupportedByNetwork(entity.network, feature) &&
        FeatureVersionMapping[feature][ContractType.ADAM]?.includes(version),
    }),
    {}
  ) as SupportedFeatureType;
};

export const getDaoSupportedFeatures = async (entity: Dao): Promise<SupportedFeatureType> => {
  const version = await getDaoVersion(entity);
  const features = Object.keys(FeatureVersionMapping) as unknown as Feature[];

  return features.reduce(
    (result, feature: Feature) => ({
      ...result,
      [feature]:
        isFeatureSupportedByUI(feature) &&
        isFeatureSupportedByNetwork(entity.network, feature) &&
        FeatureVersionMapping[feature][ContractType.DAO]?.includes(version),
    }),
    {}
  ) as SupportedFeatureType;
};

export const getLiquidPoolSupportedFeatures = async (entity: LiquidPool): Promise<SupportedFeatureType> => {
  const version = await getLiquidPoolVersion(entity);
  const features = Object.keys(FeatureVersionMapping) as unknown as Feature[];

  return features.reduce(
    (result, feature: Feature) => ({
      ...result,
      [feature]:
        isFeatureSupportedByUI(feature) &&
        isFeatureSupportedByNetwork(entity.network, feature) &&
        FeatureVersionMapping[feature][ContractType.LIQUID_POOL]?.includes(version),
    }),
    {}
  ) as SupportedFeatureType;
};
