import styled from 'styled-components';
import Box from 'components/layout/Box';
import Description from 'components/layout/Description';
import TableRow from './TableRow';
import { ActionField, FieldArray } from './types';

interface Props {
  fields: FieldArray;
  actionField?: ActionField;
  padding?: string;
}

const RowContainer = styled(TableRow)`
  border-bottom: 2px solid ${({ theme: { color } }): string => color.field};
  gap: 10px;
`;

const HeaderText = styled(Description)`
  color: ${({ theme: { color } }): string => color.fontSecondaryLight};
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
`;

export default function HeaderRow(props: Props): JSX.Element {
  return (
    <RowContainer alignItems="center" justifyContent="space-between" padding={props.padding}>
      {props.fields.map((field, index) => (
        <Box key={`header-row__box-${index}`} width={field.width}>
          <HeaderText>{field.text}</HeaderText>
        </Box>
      ))}
      {!!props.actionField && <Box width={props.actionField.width} />}
    </RowContainer>
  );
}
