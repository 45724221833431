import styled from 'styled-components';
import Button from 'components/Button';
import Title from 'components/layout/Title';

export interface Props {
  statement: string;
  description?: string;
  actionBtnIcon?: string;
  actionBtnText?: string;
  actionBtnCustomCss?: string;
  onActionBtnClick?: () => void;
  children?: JSX.Element;
}

const EmptyStateWrapper = styled.div`
  padding: 0 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 100%;
`;

const Statement = styled(Title)``;

export default function EmptyState({
  statement,
  description,
  actionBtnText,
  onActionBtnClick,
  children,
}: Props): JSX.Element {
  return (
    <EmptyStateWrapper>
      <Statement>{statement}</Statement>
      {description}
      {actionBtnText && <Button onClick={onActionBtnClick}>{actionBtnText}</Button>}
      {children}
    </EmptyStateWrapper>
  );
}
