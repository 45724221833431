export { toNumberWithCommas } from '@adam-vault/adam-sdk';

export const fromStringWithComma = (value?: string | number): string => {
  if (!value) {
    return '';
  }
  if (typeof value === 'number') {
    return value.toString();
  }
  return value.replaceAll(',', '');
};
