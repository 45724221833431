import { useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import ActionButton from 'components/ActionButton';
import Text from 'components/Text';
import { GlobalModal } from 'constants/globalModal';
import useCurrentDao from 'hooks/useCurrentDao';
import useGlobalModal from 'hooks/useGlobalModal';

export interface CollapsedMenuProps {
  isCollapsed?: boolean;
}

export const collapseMenuCss = css<CollapsedMenuProps>`
  ${({ isCollapsed }) =>
    isCollapsed
      ? css`
          height: 0;
          width: 0;
          opacity: 0;
        `
      : css`
          height: auto;
          width: auto;
          opacity: 1;
        `}
  overflow: hidden;
  transition: opacity 0.5s ease, width 0.5s ease, height 0.5s ease;
`;

export interface Props extends CollapsedMenuProps {
  daoAddress: string;
}

interface InnerProps extends Props {}

const Container = styled.div<CollapsedMenuProps>`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme: { color } }): string => color.panel};
  margin: 20px -10px 32px;
  padding: 16px 20px;
  border-radius: 8px;
  width: 192px;
  height: 134px;
  ${collapseMenuCss}
`;

const Title = styled(Text)`
  text-align: center;
  margin-bottom: 6px;
`;

const Description = styled(Text)`
  color: ${({ theme: { color } }): string => color.fontPrimary};
  text-align: center;
  margin-bottom: 12px;
`;

const SubmitButton = styled(ActionButton)`
  margin: 0;
`;

function JoinDaoNoticeInner(props: InnerProps): JSX.Element | null {
  const { openModal } = useGlobalModal(GlobalModal.JOIN_DAO_AND_DEPOSIT);

  const navigateToJoinDoaPage = useCallback(() => {
    openModal();
  }, [openModal]);

  return (
    <Container isCollapsed={props.isCollapsed}>
      <Title category="headline-2" weight="bold">
        Welcome!
      </Title>
      <Description category="footnote">Join now to unlock all features</Description>
      <SubmitButton variant="secondary" onClick={navigateToJoinDoaPage}>
        <Text category="sub-headline" weight="bold">
          JOIN VAULT
        </Text>
      </SubmitButton>
    </Container>
  );
}

export default function JoinDaoNotice(props: Props): JSX.Element | null {
  const { isLoading, isMemberStateLoading, doesDaoExist, isCurrentEoaAMember } = useCurrentDao();

  const [needReRender, setNeedReRender] = useState(false);

  // to force useSubscription to refetch after daoAddress is changed
  useEffect(() => {
    if (props.daoAddress) {
      setNeedReRender(true);
      setTimeout(() => {
        setNeedReRender(false);
      }, 100);
    }
  }, [props.daoAddress]);

  if (isLoading || isMemberStateLoading || !props.daoAddress || needReRender || isCurrentEoaAMember || !doesDaoExist) {
    return null;
  }

  return <JoinDaoNoticeInner daoAddress={props.daoAddress} isCollapsed={props.isCollapsed} />;
}
