import { Adam } from '@adam-vault/adam-sdk';
import { useAtom } from 'jotai';
import { useCallback, useState, useEffect } from 'react';
import { adamAtom } from 'store/adamAtom';

type DependencyList = ReadonlyArray<any>;

export default function useAdam(): Adam {
  const [adam] = useAtom(adamAtom);
  return adam;
}

export function useAdamMemo<T>(
  callback: (adam: Adam) => Promise<T>,
  deps: DependencyList,
  defaultValue: T
): [T, { refetch: () => void; isLoading: boolean; error: Error | null }] {
  const adam = useAdam();
  const [forceUpdate, setForceUpdate] = useState<number>(0);
  const [value, setValue] = useState<T>(defaultValue);
  const [error, setError] = useState<Error | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetch = useCallback(async () => {
    try {
      setError(null);
      setIsLoading(true);
      setValue(await callback(adam));
      setIsLoading(false);
    } catch (err) {
      console.error('An Error was thrown by useAdamMemo', { err });
      setValue(defaultValue);
      setIsLoading(false);
      setError(err as Error);
    }
  }, [forceUpdate, adam, ...deps]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const refetch = useCallback(() => {
    setForceUpdate((count: number) => count + 1);
  }, [setForceUpdate]);

  return [value, { refetch, isLoading, error }];
}
