import React from 'react';
import styled, { useTheme, css, FlattenInterpolation } from 'styled-components';
import { space, flexbox, layout, SpaceProps, FlexboxProps, LayoutProps } from 'styled-system';
import Icon from 'components/Icon';
import Loading from 'components/Loading';
import defaultTheme from 'theme/default';

type themeType = typeof defaultTheme;

export interface Props extends React.ComponentPropsWithoutRef<'button'>, SpaceProps, FlexboxProps, LayoutProps {
  className?: string;
  children?: React.ReactNode;
  loading?: boolean;
  variant?: 'plain' | 'primary' | 'secondary' | 'ghost' | 'text';
  icon?: string;
  iconSize?: string;
  iconColor?: string;
}

interface ButtonWrapperProps extends React.ComponentPropsWithoutRef<'button'> {
  className?: string;
  variant?: Props['variant'];
  loading?: Props['loading'];
}

const getDefaultCss = (color: themeType['color'], variant: Props['variant']): string => {
  if (!variant) {
    return '';
  }
  switch (variant) {
    case 'primary':
      return `background-color: ${color.actionPrimary};`;
    case 'secondary':
      return `background: ${color.backgroundGradient};`;
    case 'ghost':
      return `
        background-color: transparent;
        color: ${color.secondary};
        border: 1px solid ${color.secondary};
      `;
    case 'text':
      return `
        background-color: transparent;
        color: ${color.secondary};
      `;
    case 'plain':
      return '';
  }
};

const getHoverCss = (color: themeType['color'], variant: Props['variant']): string => {
  if (!variant) {
    return '';
  }
  switch (variant) {
    case 'primary':
      return `background-color: ${color.secondaryDarken};`;
    case 'secondary':
      return `background: ${color.gradientDarken};`;
    case 'ghost':
      return `
        background-color: ${color.actionPrimary};
        color: ${color.fontPrimary};
      `;
    case 'text':
      return 'text-decoration: underline;';
    case 'plain':
      return '';
  }
};

const getFocusCss = (color: themeType['color'], variant: Props['variant']): string => {
  if (!variant) {
    return '';
  }
  switch (variant) {
    case 'primary':
      return `background-color: ${color.actionPrimary};`;
    case 'secondary':
      return `background: ${color.backgroundGradient};`;
    case 'ghost':
    case 'text':
      return `
        border: 1px solid ${color.secondary};
        background-color: ${color.secondaryFocus};
        color: ${color.secondary};
        text-decoration: unset;
      `;
    case 'plain':
      return '';
  }
};

const getDisabledCss = (color: themeType['color'], variant: Props['variant']): string => {
  if (!variant) {
    return '';
  }
  switch (variant) {
    case 'primary':
      return `background-color: ${color.secondaryDarken};`;
    case 'secondary':
      return `background: ${color.gradientDarken};`;
    case 'ghost':
      return `
        border-color: ${color.buttonBackground.disabled};
        color: ${color.buttonBackground.disabled};
      `;
    case 'text':
      return `color: ${color.buttonBackground.disabled};`;
    case 'plain':
      return '';
  }
};

const getLoadingColor = (color: themeType['color'], variant: Props['variant']): string => {
  if (!variant) {
    return color.fontPrimary;
  }
  switch (variant) {
    case 'ghost':
    case 'text':
      return color.secondary;
    case 'plain':
    case 'primary':
    case 'secondary':
      return color.fontPrimary;
  }
};

export const buttonCss = css<Props>`
  min-height: 42px;
  font-weight: 400;
  border-radius: 5px;
  padding: 10px 22px;
  margin: 5px;
  outline: 5px none transparent;

  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 8px;

  transition: all 200ms ease;
  transition-property: background-color, color, border, outline;

  /* Default background color (with special handling of gradient color for seconday) */
  ${({ variant, theme: { color } }): string => getDefaultCss(color, variant)}

  /* Hover */
  &:hover:enabled {
    ${({ variant, theme: { color } }): string => getHoverCss(color, variant)}
  }

  /* onClick: activated */
  &:active:enabled {
    opacity: 0.6;
  }

  /* Focus */
  /* :focus-visible ignores the focus effect on button click */
  &:focus:enabled:focus-visible {
    outline: ${({ theme: { color } }): string => `5px solid ${color.secondaryFocus}`};
    ${({ variant, theme: { color } }): string => getFocusCss(color, variant)}
  }

  /* Disabled */
  &:disabled {
    ${({ loading, variant, theme: { color } }) =>
      loading
        ? 'opacity: 0.6;'
        : css`
            opacity: 0.5;
            ${getDisabledCss(color, variant)}
          `}
  }

  ${space}
  ${flexbox}
  ${layout}
` as FlattenInterpolation<unknown>;

const ButtonWrapper = styled.button<ButtonWrapperProps>`
  ${buttonCss}
`;

export default function Button({
  children,
  loading,
  type = 'button',
  variant = 'primary',
  disabled,
  icon,
  iconSize,
  iconColor,
  ...otherProps
}: Props): JSX.Element {
  const { color } = useTheme() as typeof defaultTheme;

  return (
    <ButtonWrapper type={type} variant={variant} {...otherProps} disabled={disabled || loading} loading={loading}>
      {loading && <Loading isCenter height="100%" color={getLoadingColor(color, variant)} />}
      {!loading && (
        <>
          {icon && <Icon icon={icon} size={iconSize} color={iconColor} />}
          {children}
        </>
      )}
    </ButtonWrapper>
  );
}
