import { BigNumber } from 'ethers';
import { useAdamMemo } from 'hooks/useAdam';
import { AdmissionTokenRequirement, EntryRequirements, MinDepositAmountRequirement } from 'types/EntryRequirements';
import web3Utils from 'utils/web3Utils';

type ReturnType = EntryRequirements & {
  isLoading: boolean;
};

export default function useEntryRequirements(daoAddress: string): ReturnType {
  const [requirements, { isLoading }] = useAdamMemo(
    async (adam) => {
      const daoEntity = adam.loadDao(daoAddress);

      const admissionTokens = await daoEntity.admissionTokens();
      const admissionTokenRequirements: Array<AdmissionTokenRequirement> = await Promise.all(
        admissionTokens.map(async (token) => {
          let tokenEntity = null;
          if (token.isMemberToken) {
            tokenEntity = (await daoEntity.loadMemberToken()) || (await adam.loadToken(token.token));
          } else {
            tokenEntity = await adam.loadToken(token.token);
          }

          const decimals = await tokenEntity.decimals();

          return {
            amountBN: BigNumber.from(token.minTokenToAdmit),
            formattedAmount: web3Utils.fromUnit(token.minTokenToAdmit.toString(), decimals),
            token: await tokenEntity.fetch(),
            tokenId: token.tokenId ? token.tokenId.toString() : '',
            isMemberToken: token.isMemberToken,
          };
        })
      );

      const minDepositAmount = await daoEntity.minDepositAmount();
      const formattedMinDepositAmount = await daoEntity.formatMinDepositAmount();
      const baseCurrencyEntity = await daoEntity.loadBaseCurrency();
      const baseCurrencyData = await baseCurrencyEntity.fetch();
      const memberToken = await daoEntity.loadMemberToken();
      const depositTokens = (await daoEntity.depositTokens()).filter((token) => token !== memberToken?.address);

      const isMinDepositAmountRequired = minDepositAmount.gt(0);

      const minDepositAmountRequirement: MinDepositAmountRequirement = isMinDepositAmountRequired
        ? {
            isRequired: isMinDepositAmountRequired,
            amountBN: minDepositAmount,
            formattedAmount: formattedMinDepositAmount,
            depositTokens,
            token: baseCurrencyData,
          }
        : { isRequired: isMinDepositAmountRequired };

      return {
        admissionTokens: admissionTokenRequirements,
        minDepositAmount: minDepositAmountRequirement,
      };
    },
    [daoAddress],
    null
  );

  return {
    ...requirements,
    isLoading,
  };
}
