import { Chain } from 'adam-frontend-shared';
import isNil from 'lodash/isNil';
import { MouseEventHandler, useMemo, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';
import DaoAvatar from 'components/DaoAvatar';
import Icon from 'components/Icon';
import Rect from 'components/layout/Rect';
import Loading from 'components/Loading';
import Modal from 'components/Modal';
import Text from 'components/Text';
import { COLLAPSE_DAO_SIDEBAR_WIDTH } from 'constants/dimension';
import PATHS from 'constants/paths';
import { useAdamMemo } from 'hooks/useAdam';
import useCurrentDao from 'hooks/useCurrentDao';
import useTokenSettings from 'hooks/useTokenSettings';
import useWeb3 from 'hooks/useWeb3';
import browserUtils from 'utils/browserUtils';
import { toEllipsisAddress } from 'utils/formatUtils';

export interface CollapsedMenuProps {
  isCollapsed?: boolean;
}

export const collapseMenuCss = css<CollapsedMenuProps>`
  ${({ isCollapsed }) =>
    isCollapsed
      ? css`
          height: 0;
          width: 0;
          opacity: 0;
        `
      : css`
          height: auto;
          width: auto;
          opacity: 1;
        `}
  overflow: hidden;
  transition: opacity 0.5s ease, width 0.5s ease, height 0.5s ease;
  transition: opacity 0.5s ease-in;
`;

export interface Props extends CollapsedMenuProps {}

const Container = styled(Rect)<CollapsedMenuProps>`
  display: flex;
  padding: ${({ isCollapsed }) => (isCollapsed ? 0 : '14px 24px')};
  background: ${({ theme: { color }, isCollapsed }): string =>
    isCollapsed ? 'transparent' : color.daoPanelDepositSectionBackground};
  min-height: 108px;
  max-height: ${({ isCollapsed }) => (isCollapsed ? 'none' : '117px')};
  width: ${({ isCollapsed }) => (isCollapsed ? `${COLLAPSE_DAO_SIDEBAR_WIDTH}px` : '207px')};
  transition: width 0.2s ease-in;
`;
const DaoBalanceSection = styled.div<CollapsedMenuProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${collapseMenuCss}
`;

const DaoIconContainer = styled.div`
  position: relative;
  margin: 0 24px 0 6px;
`;

const DaoWalletIcon = styled.div``;

const DaoIcon = styled.div`
  position: absolute;
  right: -30%;
  bottom: -15%;
`;

const DaoAddress = styled(Text)`
  cursor: pointer;
`;

const BalanceAndAddress = styled.div``;
const ActionButtons = styled.div<CollapsedMenuProps>`
  margin-top: 14px;
  display: flex;
  flex-direction: ${({ isCollapsed }) => (isCollapsed ? 'column' : 'row')};
  justify-content: space-between;
  align-items: ${({ isCollapsed }) => (isCollapsed ? 'center' : 'stretch')};
  row-gap: ${({ isCollapsed }) => (isCollapsed ? '0px' : '15px')};
`;

const ActionButton = styled.div<CollapsedMenuProps>`
  padding: ${({ isCollapsed }) => (isCollapsed ? '10px 8px' : '0px')};
  border-radius: ${({ isCollapsed }) => (isCollapsed ? '8px' : '0px')};

  &:hover {
    background-color: ${({ isCollapsed, theme }) =>
      isCollapsed ? theme.color.daoPanelItemHoverBackground : 'transparent'};
    cursor: pointer;
  }
`;

export default function DepositSection(props: Props): JSX.Element {
  const { isCollapsed } = props;
  const { chainId } = useWeb3();
  const { defaultBaseCurrency } = useTokenSettings();
  const {
    isLoading: isDaoLoading,
    isTotalPriceLoading,
    currentDao,
    doesDaoExist,
    getTotalPriceDisplay,
  } = useCurrentDao();
  const daoAddress = currentDao?.address.toLowerCase();
  const totalPrice = useMemo(() => getTotalPriceDisplay({ maxDecimals: 2 }), [getTotalPriceDisplay]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [daoLogoCID, { isLoading: loading }] = useAdamMemo(
    async (adam) => {
      const daoEntity = adam.loadDao(daoAddress);
      const logoCID = await daoEntity.logoCID();

      return !!logoCID ? logoCID : '';
    },
    [daoAddress],
    ''
  );

  const navigate = useNavigate();
  const redirectToDeposit: MouseEventHandler<HTMLDivElement> = (e): void => {
    e.stopPropagation();
    const path = generatePath(PATHS.DAO.DEPOSIT, { daoAddress });
    navigate(path);
  };
  const redirectToBudget: MouseEventHandler<HTMLDivElement> = (e): void => {
    e.stopPropagation();
    navigate(generatePath(PATHS.DAO.BUDGET, { daoAddress }));
  };

  const copyAddress = (): void => {
    if (!daoAddress) {
      return;
    }
    browserUtils.copyToClipboard(daoAddress);
    setIsModalOpen(true);
  };
  const openEtherscan: MouseEventHandler<HTMLDivElement> = (e): void => {
    e.stopPropagation();
    window.open(!isNil(chainId) ? `${Chain.CHAIN_INFO[chainId].blockExplorerUrl}address/${daoAddress}` : '');
  };

  const closeModal = (): void => setIsModalOpen(false);

  if ((!doesDaoExist || loading || isDaoLoading || isTotalPriceLoading) && !isCollapsed) {
    return <Loading isCenter />;
  }

  return (
    <Container isCollapsed={isCollapsed}>
      <>
        <DaoBalanceSection isCollapsed={isCollapsed}>
          <DaoIconContainer>
            <DaoWalletIcon>
              <Icon icon="vault" size="27px" />
            </DaoWalletIcon>
            <DaoIcon>
              <DaoAvatar daoAddress={daoAddress ?? ''} size="15px" logoCID={daoLogoCID} />
            </DaoIcon>
          </DaoIconContainer>
          <BalanceAndAddress>
            {totalPrice && (
              <Text category="body" weight="bold">
                {totalPrice} {defaultBaseCurrency?.displaySymbol}
              </Text>
            )}
            <DaoAddress category="address" onClick={copyAddress}>
              {toEllipsisAddress(daoAddress)}
            </DaoAddress>
          </BalanceAndAddress>
        </DaoBalanceSection>
        <ActionButtons isCollapsed={isCollapsed}>
          <ActionButton isCollapsed={isCollapsed}>
            <Icon icon="deposit" onClick={redirectToDeposit} title="Deposit" />
          </ActionButton>
          <ActionButton isCollapsed={isCollapsed}>
            <Icon icon="budget" onClick={redirectToBudget} title="Budget" />
          </ActionButton>
          <ActionButton isCollapsed={isCollapsed}>
            <Icon icon="redirect" onClick={openEtherscan} title="Check on Etherscan" />
          </ActionButton>
        </ActionButtons>
      </>

      <Modal isOpen={isModalOpen} contentLabel="Address Copied" onRequestClose={closeModal} onConfirm={closeModal}>
        The Vault address {daoAddress} is copied to clipboard!
      </Modal>
    </Container>
  );
}
