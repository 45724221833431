import styled from 'styled-components';
import { space, SpaceProps } from 'styled-system';

export interface Props extends React.AnchorHTMLAttributes<HTMLAnchorElement>, SpaceProps {}

const ExternalLink = styled.a<Props>`
  ${space}
`;

ExternalLink.defaultProps = {
  target: '_blank',
  rel: 'noreferrer',
};

export default ExternalLink;
