import { transparentize } from 'polished';
import { StyledComponentsTheme } from 'types/StyledComponentsTheme';

const color = {
  // Deprecated
  white1: '#FFFFFF',
  black1: '#06010d',
  black2: '#25253D',
  oldDarkGray1: '#201c2d',
  oldDarkGray2: '#3D3557',
  oldDarkGray3: '#24243a',
  oldGray1: '#A0A0A0',
  green1: '#2F9C96',
  purple1: '#7E3AD9',
  lightPurple1: '#d4cde2',

  // Design system
  black: '#000000',
  white: '#FFFFFF',
  deepPuce: '#CB3D79',
  yellowOrange: '#FFB03A',
  lightBlue1: '#33C3FF',
  blue: '#2FC3FC',
  blueDarken: '#244F74',
  violetsBlue: '#9577FF',
  violetsBlueDarken: '#433a76',
  violetsBlueFocus: 'rgba(149, 119, 255, 0.35)',
  violetsBlueHover: `linear-gradient(0deg, rgba(32, 32, 58, 0.7), rgba(32, 32, 58, 0.7)), #9577FF`,
  coolGrey: '#8E8AA0',
  darkGray1: '#25253D',
  darkGray2: '#1D1D30',
  darkGray3: '#312f57',
  blackCoral: '#59576F',
  languidLavender: '#D4CDE2',
  crayolaGreen: '#12A961',
  darkGunmetal: '#20203A',
  yankeesBlue: '#2C2C4E',
  yankeesBlueFocus: '#4C4283',
  jacarta: '#373760',
  darkSlateGray: '#313151',
  koamaru: '#454574',
};

const primaryColor = color.blue;
const primaryColorDarken = color.blueDarken;

const secondaryColor = color.violetsBlue;
const secondaryColorDarken = color.violetsBlueDarken;
const secondaryColorFocus = color.violetsBlueFocus;
const secondaryGradientColor = color.purple1;

const gradientColor = `linear-gradient(93.4deg, ${primaryColor} 0%, ${secondaryGradientColor} 102.23%)`;
const gradientColorDarken = `linear-gradient(93.4deg, ${primaryColorDarken} 0%, ${secondaryColorDarken} 102.23%)`;
const progressBarGradientColor = `linear-gradient(180deg, ${primaryColor} 0%, ${secondaryGradientColor} 100%)`;
const dashboardDepositSectionGradientColor = `linear-gradient(113.25deg, #2EB3FA 3.03%, #5F58E7 65.03%);`;
const dashboardSwapSectionGradientColor = `linear-gradient(120.51deg, #FFFFFF -33%, #F37AB9 58.71%, #D84E96 84.38%);`;
const dashboardTokenTransferSectionGradientColor = `linear-gradient(123.34deg, #FFFFFF -22.58%, #9577FF 79.85%);`;
const dashboardRecentActivitiesSectionGradientColor = `linear-gradient(165.59deg, #27B9FC 0%, rgba(32, 32, 58, 0.1) 64.84%);`;
const dashboardSettingsSectionGradientColor = `linear-gradient(120.71deg, #F2BCFF -3.09%, #27BFFC 36.91%, #5F58E7 94.12%);`;
const dashboardMemberTokenSectionGradientColor = `linear-gradient(123.34deg, #FFFFFF -22.58%, #27BFFC 79.85%);`;
const dashboardRewardSectionGradientColor = `linear-gradient(123.34deg, #F9B4D8 -22.58%, #9577FF 79.85%);`;
const connectWalletCardGradientColor = `linear-gradient(93.4deg, #33c3ff 0%, #7e3ad9 102.23%);`;
const daoPanelDepositSectionGradientColor = `linear-gradient(93.4deg, ${color.lightBlue1} 0%, ${secondaryGradientColor} 102.23%)`;

const space = [0, 4, 8, 16, 32, 64, 128, 256, 512];

const theme: StyledComponentsTheme = {
  // styled system
  space,

  // custom theme
  // TODO: Rename color to colors
  color: {
    ...color,
    primary: primaryColor,
    primaryDarken: primaryColorDarken,
    secondary: secondaryColor,
    secondaryDarken: secondaryColorDarken,
    secondaryFocus: secondaryColorFocus,
    gradient: gradientColor,
    gradientDarken: gradientColorDarken,

    // TODO sync the structure
    backgroundPrimary: color.oldDarkGray1,
    backgroundSecondary: color.oldDarkGray2,
    backgroundTertiary: transparentize(0.8, color.oldDarkGray2),
    backgroundGradient: gradientColor,
    backgroundProgressBar: progressBarGradientColor,
    backgroundPagePrimary: color.yankeesBlue,
    dashboardDepositSectionGradient: dashboardDepositSectionGradientColor,
    dashboardSwapSectionGradient: dashboardSwapSectionGradientColor,
    dashboardTokenTransferSectionGradient: dashboardTokenTransferSectionGradientColor,
    dashboardRecentActivitiesSectionGradient: dashboardRecentActivitiesSectionGradientColor,
    dashboardSettingsSectionGradient: dashboardSettingsSectionGradientColor,
    dashboardMemberTokenSectionGradient: dashboardMemberTokenSectionGradientColor,
    dashboardRewardSectionGradient: dashboardRewardSectionGradientColor,
    createProposalFormBackground: color.darkGray1,
    connectWalletCardGradient: connectWalletCardGradientColor,
    connectWalletCardButtonBackground: transparentize(0.8, color.white1),
    fontPrimary: color.white1,
    fontSecondary: color.languidLavender,
    fontSecondaryLight: color.lightPurple1,
    fontTertiary: color.coolGrey,
    fontQuaternary: color.blackCoral,
    fontHighlight: color.violetsBlue,
    fontTag: color.yankeesBlue,
    fontDisabled: transparentize(0.8, color.white1),
    formBackground: color.yankeesBlue,
    buttonFontColor: {
      primary: color.violetsBlue,
      secondary: color.languidLavender,
    },
    buttonBackground: {
      primary: color.violetsBlue,
      secondary: color.oldDarkGray2,
      disabled: color.blackCoral,
    },
    radioDisabled: color.blackCoral,
    checkboxDisabled: color.blackCoral,
    checkboxBorder: color.languidLavender,
    dropdownArrow: color.coolGrey,
    dropdownBackground: color.jacarta,
    dropdownHoverBackground: color.violetsBlueHover,
    tagBackground: color.languidLavender,

    tooltipText: color.white,
    tooltipBackground: color.jacarta,

    link: color.violetsBlue,
    scrollThumbColor: color.white1,
    siteBackground: color.black1,
    executeButtonBackground: color.crayolaGreen,
    for: color.crayolaGreen,
    against: color.deepPuce,
    error: color.deepPuce,
    delete: color.deepPuce,
    loading: transparentize(0.5, color.white1),
    daoAvatarColorSet: [color.deepPuce, color.yellowOrange, color.lightBlue1, color.violetsBlue, color.darkGray1],
    addressAvatarColorSet: [color.lightBlue1, color.violetsBlue, color.darkGray1],
    approvalButtonWithAction: {
      active: color.crayolaGreen,
      inactive: color.blackCoral,
    },

    // Design system
    surface: color.darkGunmetal,
    widget: color.koamaru,
    panel: color.yankeesBlue,
    table: color.yankeesBlue,
    actionPrimary: color.violetsBlue,
    field: color.jacarta,
    textField: color.jacarta,
    footer: color.jacarta,
    border: color.yankeesBlue,
    borderDark: color.koamaru,
    divider: color.darkSlateGray,
    critical: color.deepPuce,
    warning: color.yellowOrange,
    success: color.violetsBlue,
    approve: color.crayolaGreen,
    primaryText: color.white,
    secondaryText: color.languidLavender,
    tertiaryText: color.coolGrey,
    quaternaryText: color.blackCoral,
    menu: color.black,
    progressBar: color.yankeesBlue,
    disabled: color.coolGrey,
    asterisk: color.violetsBlue,
    requirementButton: color.jacarta,
    requirementButtonFocus: color.yankeesBlueFocus,
    hintCoverBackground: color.jacarta,
    daoTypeButton: color.yankeesBlue,
    poolMenuBackground: color.black,
    assetIconPlaceholderBackground: color.koamaru,
    assetIconColor: color.jacarta,
    daoPanelBackground: color.black,
    daoPanelPageContentSeparator: color.yankeesBlue,
    daoPanelDepositSectionBackground: daoPanelDepositSectionGradientColor,
    daoPanelItemHoverBackground: transparentize(0.7, color.violetsBlue),
    networkButtonCircleTestnet: color.lightBlue1,
    networkButtonCircleMainnet: color.crayolaGreen,
    walletButtonInModalBackground: color.jacarta,
    connectWalletModalBackground: color.yankeesBlue,
    connectWalletModalCloseButton: color.languidLavender,
    presetButton: color.jacarta,
    presetButtonFocus: color.violetsBlueFocus,
  },
  fontFamily: {
    primary: "'Lato', sans-serif",
    secondary: "'Roboto', sans-serif",
    address: "'Roboto Mono', monospace",
  },
  fontSize: {
    small: '1em',
    medium: '2em',
    large: '3em',
  },
  spacing: {
    default: 15,
  },
};

export default theme;
