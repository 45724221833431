import TagManager from 'react-gtm-module';

const init = (): void => {
  if (!process.env.REACT_APP_GTM_ID || window.dataLayer) {
    return;
  }

  TagManager.initialize({
    gtmId: process.env.REACT_APP_GTM_ID,
  });
};

type SendEvent = {
  event: string;
  payload?: {
    eventProps: {
      category: string;
      action: string;
      label: string;
      value: string;
    };
  };
};

const sendEvent = ({ event, payload }: SendEvent): void => {
  if (!window.dataLayer) {
    return;
  }

  window.dataLayer.push({
    event,
    ...payload,
  });
};

export default {
  init,
  sendEvent,
};
