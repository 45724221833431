import styled from 'styled-components';
import defaultTheme from 'theme/default';

type themeType = typeof defaultTheme;

export interface Props extends React.ComponentPropsWithoutRef<'div'> {
  className?: string;
  variant?: 'primary' | 'secondary' | 'tertiary' | 'quaternary';
  category:
    | 'large-title'
    | 'title-1'
    | 'title-2'
    | 'title-3'
    | 'headline-1'
    | 'headline-2'
    | 'body'
    | 'callout'
    | 'sub-headline'
    | 'footnote'
    | 'address';
  weight?: 'regular' | 'semi-bold' | 'bold';
}

const getVariantCss = (color: themeType['color'], variant: Props['variant']): string => {
  if (!variant) {
    return '';
  }

  switch (variant) {
    case 'primary':
      return `color: ${color.fontPrimary};`;
    case 'secondary':
      return `color: ${color.fontSecondary};`;
    case 'tertiary':
      return `color: ${color.fontTertiary};`;
    case 'quaternary':
      return `color: ${color.fontQuaternary};`;
  }
};

const getCategoryCss = (category: Props['category']): string => {
  switch (category) {
    case 'large-title':
      return `
            font-size: 40px;
            line-height: 50px;
        `;
    case 'title-1':
      return `
            font-size: 32px;
            line-height: 40px;
        `;
    case 'title-2':
      return `
            font-size: 28px;
            line-height: 36px;
        `;
    case 'title-3':
      return `
            font-size: 24px;
            line-height: 32px;
        `;
    case 'headline-1':
      return `
            font-size: 20px;
            line-height: 28px;
        `;
    case 'headline-2':
      return `
            font-size: 18px;
            line-height: 26px;
        `;
    case 'body':
    case 'callout':
      return `
            font-size: 16px;
            line-height: 24px;
        `;
    case 'sub-headline':
      return `
            font-size: 14px;
            line-height: 22px;
        `;
    case 'footnote':
      return `
            font-size: 13px;
            line-height: 19px;
        `;
    case 'address':
      return `
            font-size: 11px;
            line-height: 15px;
        `;
  }
};

const getWeightCss = (weight: Props['weight']): string => {
  if (!weight) {
    return '';
  }

  switch (weight) {
    case 'regular':
      return 'font-weight: 400;';
    case 'semi-bold':
      return 'font-weight: 600;';
    case 'bold':
      return 'font-weight: 700;';
  }
};

const getFontFamilyCss = (category: Props['category']): string => {
  switch (category) {
    case 'address':
      return "font-family: 'Roboto Mono';";
    default:
      return 'font-family: Lato;';
  }
};

const TextContainer = styled.div<Props>`
  ${({ variant, theme: { color } }): string => getVariantCss(color, variant)}
  ${({ category }): string => getCategoryCss(category)}
  ${({ weight }): string => getWeightCss(weight)}
  ${({ category }): string => getFontFamilyCss(category)}
`;

export default function Text({ variant = 'primary', weight = 'regular', children, ...otherProps }: Props): JSX.Element {
  return (
    <TextContainer variant={variant} weight={weight} {...otherProps}>
      {children}
    </TextContainer>
  );
}
