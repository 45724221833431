import { Dao, SourceType, toBigNumber, TokenData, TokenInfoResponse } from '@adam-vault/adam-sdk';
import { DevTool } from '@hookform/devtools';
import { BigNumber } from 'ethers';
import { useState, useEffect, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { MixedSchema } from 'yup/lib/mixed';
import ActionButton from 'components/ActionButton';
import ErrorMsg from 'components/ErrorMsg';
import AddressDropdownInput, { AddressDropdownInputOptionItem } from 'components/form/AddressDropdownInput';
import DepositAmountInput from 'components/form/DepositAmountInput';
import Form from 'components/form/Form';
import Description from 'components/layout/Description';
import Title from 'components/layout/Title';
import WarningMsg from 'components/WarningMsg';
import UnicodeCharacter from 'constants/unicodeCharacter';
import { useAdamMemo } from 'hooks/useAdam';
import useForm from 'hooks/useForm';
import useRouterQuery from 'hooks/useRouterQuery';
import useTokenPrice from 'hooks/useTokenPrice';
import useTokenSettings from 'hooks/useTokenSettings';
import useWeb3 from 'hooks/useWeb3';
import { toAmountString, getBigNumberString } from 'utils/numberUtils';
import { toNumberWithCommas } from 'utils/thousandSeparatorUtils';
import { toUnitBN, toSupportedTokenUnitBN } from 'utils/web3Utils';
import EoaDisplay from './EoaDisplay';
import { AssetPools, DepositFormMode, DepositInfoFormData } from './types';

export interface Props {
  assetPools: AssetPools;
  depositTokens: TokenInfoResponse[];
  minDepositAmount: string;
  formData?: DepositInfoFormData;
  baseCurrency?: TokenData;
  daoAddress: string;
  isDaoMember: boolean;
  lockDays: number;
  isLoading?: boolean;
  mode: DepositFormMode;
  isOverMembersCountLimit?: boolean;
  onNext: (data: DepositInfoFormData) => void;
  onApproveErc20: (data: DepositInfoFormData) => void;
  onHasEnoughDepositAmountChange?: (hasEnoughDepositAmount: boolean) => void;
}

const getMinimumDepositAmount = async (dao: Dao, toAddress: string, tokenAddress: string): Promise<BigNumber> => {
  if (toAddress === dao.address.toLowerCase()) {
    return BigNumber.from('0');
  }
  const minAmount = (await dao.minDepositAmountForStaking(tokenAddress)).amount;
  return minAmount;
};

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: auto;
`;

const StyledTitle = styled(Title)`
  font-size: 32px;
  margin-bottom: 32px;
`;

const Label = styled(Description)`
  margin-bottom: 7px;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  color: ${({ theme: { color } }): string => color.fontPrimary};

  &:not(:first-of-type) {
    margin-top: 28px;
  }
`;

const RequiredSpan = styled.span`
  color: ${({ theme: { color } }): string => color.fontHighlight};
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
`;

const StyledWarningMsg = styled(WarningMsg)`
  margin-top: 8px;
`;

const StyledErrorMsg = styled(ErrorMsg)`
  margin-top: 8px;
`;

const BalanceText = styled(Description)`
  font-weight: 400;
  color: ${({ theme: { color } }): string => color.fontSecondary};
  margin-top: 8px;
  font-size: 14px;
`;

const SubmitButton = styled(ActionButton)`
  width: 100%;
  height: 72px;
  margin-top: 48px;
`;

export default function DepositInfoForm(props: Props): JSX.Element {
  const { isConnected, address: eoaAddress } = useWeb3();
  const { supportedTokens, nativeToken, defaultBaseCurrency, getBaseCurrencyDisplaySymbol } = useTokenSettings();
  const [isValidatingTokenAmount, setIsValidatingTokenAmount] = useState(false);

  const [currentDao, { isLoading: isGettingCurrentDao }] = useAdamMemo(
    async (adam) => {
      const daoEntity = adam.loadDao(props.daoAddress);

      return daoEntity;
    },
    [props.daoAddress],
    null
  );

  const toAddressOptions = useMemo(() => {
    const liquidPoolOption = {
      text: props.assetPools[SourceType.LIQUID_POOL].name,
      address: props.assetPools[SourceType.LIQUID_POOL].address,
    };
    const treasuryOption = {
      text: props.assetPools[SourceType.TREASURY].name,
      address: props.assetPools[SourceType.TREASURY].address,
    };
    const options: Array<AddressDropdownInputOptionItem> = [];
    switch (props.mode) {
      case DepositFormMode.DEPOSIT:
        options.push(treasuryOption);
        if (props.isDaoMember) {
          options.push(liquidPoolOption);
        }
        break;
      case DepositFormMode.JOIN_AND_DEPOSIT:
        options.push(liquidPoolOption);
        break;
    }
    return options;
  }, [props.assetPools, props.isDaoMember, props.mode]);

  const tokenOptions = useMemo(
    () =>
      props.depositTokens.map((token) => ({
        text: token.symbol,
        value: token.address,
      })),
    [props.depositTokens]
  );

  const baseCurrencyDecimals = useMemo(() => props.baseCurrency?.decimals || 18, [props.baseCurrency?.decimals]);

  const query = useRouterQuery();
  const presetToType = (
    Object.values(SourceType).includes(query.to as SourceType) ? query.to : null
  ) as SourceType | null;

  const defaultValues: Partial<DepositInfoFormData> = useMemo(() => {
    const token = (props.depositTokens.length > 0 ? props.depositTokens[0].address : props.baseCurrency?.address) ?? '';

    switch (props.mode) {
      case DepositFormMode.DEPOSIT: {
        const toType = presetToType || (props.isDaoMember ? SourceType.LIQUID_POOL : SourceType.TREASURY);

        return {
          toAddress: props.assetPools[toType].address,
          token,
        };
      }
      case DepositFormMode.JOIN_AND_DEPOSIT:
        return {
          toAddress: props.assetPools[SourceType.LIQUID_POOL].address,
          token,
        };
    }
  }, [props.depositTokens, props.baseCurrency?.address, props.mode, props.assetPools, props.isDaoMember, presetToType]);

  const depositAmountValidation: MixedSchema = yup.mixed().when(['toAddress', 'token'], (toAddress, tokenAddress) =>
    yup.mixed().test('depositAmount', '', async function checkDepositAmount(value) {
      if (!currentDao || !value) {
        return false;
      }

      try {
        const adam = await currentDao.loadAdam();
        const token = adam.loadToken(tokenAddress);
        const tokenBalance = await token.balanceOf(eoaAddress || '');
        const minDepositAmount = await getMinimumDepositAmount(currentDao, toAddress, tokenAddress);
        const depositTokenSymbol = await token.symbol();
        const depositTokenDecimals = await token.decimals();
        const valueInBN = toBigNumber(value, depositTokenDecimals);

        setIsValidatingTokenAmount(false);

        const nInputDecimals = getBigNumberString(value)[1];
        if (nInputDecimals > depositTokenDecimals) {
          // eslint-disable-next-line react/no-this-in-sfc
          return this.createError({ message: `This token can have at most ${depositTokenDecimals} decimals` });
        }

        if (valueInBN.eq(BigNumber.from('0'))) {
          // eslint-disable-next-line react/no-this-in-sfc
          return this.createError({
            message: `The amount should not be zero`,
          });
        }

        if (valueInBN.lt(minDepositAmount)) {
          const tokenAmountDisplay = toAmountString(minDepositAmount.toString(), { decimals: depositTokenDecimals });
          // eslint-disable-next-line react/no-this-in-sfc
          return this.createError({
            message: `Minimum deposit amount is ${tokenAmountDisplay} ${depositTokenSymbol}`,
          });
        }

        if (valueInBN.gt(tokenBalance)) {
          // eslint-disable-next-line react/no-this-in-sfc
          return this.createError({ message: "You don't have enough balance" });
        }
      } catch (err) {
        setIsValidatingTokenAmount(false);
        // eslint-disable-next-line react/no-this-in-sfc
        return this.createError({ message: 'Something went wrong. Please try again.' });
      }

      return true;
    })
  );

  const schema = yup.object({
    toAddress: yup.string().required(),
    token: yup.string().required(),
    amount: depositAmountValidation.required(),
  });

  const {
    control,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm<DepositInfoFormData>({
    schema,
    defaultValues: props.formData ?? defaultValues,
  });

  const toAddress = watch('toAddress');
  const tokenAddress = watch('token');
  const amount = watch('amount');

  const [{ tokenBalanceDisplay }, { isLoading: isTokenBalanceLoading }] = useAdamMemo(
    async (adam) => {
      if (!isConnected || !eoaAddress) {
        return {
          tokenBalanceDisplay: '0',
        };
      }
      const tokenEntity = adam.loadToken(tokenAddress);

      return {
        tokenBalanceDisplay: toNumberWithCommas(await tokenEntity.formatBalanceOf(eoaAddress)),
      };
    },
    [tokenAddress, eoaAddress, isConnected],
    {
      tokenBalanceDisplay: '0',
    }
  );

  const { priceData, isLoading: isPriceDataLoading } = useTokenPrice(
    tokenAddress,
    props.baseCurrency?.address || defaultBaseCurrency?.address || '',
    amount
  );

  const amountInBaseCurrency = useMemo(() => {
    if (!amount || !priceData) {
      return '0';
    }
    return priceData.answerStr;
  }, [amount, priceData]);

  const amountInBaseCurrencyBN = useMemo(() => {
    if (!amount || !priceData) {
      return BigNumber.from('0');
    }
    return priceData.answer;
  }, [amount, priceData]);

  const hasEnoughDepositAmount = useMemo(
    () => !!amountInBaseCurrencyBN?.gte(toUnitBN(props.minDepositAmount, baseCurrencyDecimals) || BigNumber.from('0')),
    [amountInBaseCurrencyBN, baseCurrencyDecimals, props.minDepositAmount]
  );

  const selectedToken = supportedTokens?.find((token: TokenInfoResponse): boolean => token.address === tokenAddress);
  const selectedTokenSymbol = selectedToken?.symbol || '';

  const [allowance, { isLoading: isAllowanceLoading }] = useAdamMemo(
    async (adam) => {
      if (!isConnected) {
        return BigNumber.from(0);
      }
      return adam.loadToken(tokenAddress).allowance(eoaAddress || '', toAddress);
    },
    [tokenAddress, eoaAddress, toAddress, isConnected],
    BigNumber.from(0)
  );

  // do not need to approve erc20 tokens
  const canDeposit = useMemo(
    () =>
      tokenAddress === nativeToken?.address ||
      allowance.gte(toSupportedTokenUnitBN(supportedTokens, amount ?? '0', { address: tokenAddress }) || '0'),

    [allowance, amount, nativeToken?.address, supportedTokens, tokenAddress]
  );

  const onAmountInputChange = useCallback(() => {
    setIsValidatingTokenAmount(true);
  }, []);

  useEffect(() => {
    // for display in review form
    setValue('amountInBaseCurrency', amountInBaseCurrency);
  }, [amountInBaseCurrency, setValue]);

  useEffect(() => {
    if (!isConnected) {
      reset();
      setValue('toAddress', props.assetPools[SourceType.TREASURY].address);
    }
  }, [isConnected, props.assetPools, reset, setValue]);

  useEffect(() => {
    if (props.onHasEnoughDepositAmountChange) {
      props.onHasEnoughDepositAmountChange(hasEnoughDepositAmount);
    }
  }, [hasEnoughDepositAmount, props, props.onHasEnoughDepositAmountChange]);

  const isDisabledToJoin = props.mode === DepositFormMode.JOIN_AND_DEPOSIT && props.isOverMembersCountLimit;

  const nextButtonText = useMemo(() => {
    if (isDisabledToJoin) {
      return 'VAULT IS FULL';
    }
    if (!canDeposit) {
      return `ALLOW ADAM TO ACCESS YOUR ${selectedTokenSymbol}`;
    }
    return 'NEXT';
  }, [canDeposit, isDisabledToJoin, selectedTokenSymbol]);

  const isBaseCurrencySelected = props.baseCurrency?.address !== tokenAddress;
  const shouldShowAmountInBaseCurrency =
    !!props.baseCurrency && isBaseCurrencySelected && !isPriceDataLoading && !!priceData;
  const baseCurrencyDisplaySymbol = getBaseCurrencyDisplaySymbol(
    props.baseCurrency?.address,
    props.depositTokens.map(({ address }) => address)
  );

  const hasDepositError = !!errors.amount?.message;

  const isSubmittingDisabled =
    !isConnected ||
    isGettingCurrentDao ||
    isAllowanceLoading ||
    isTokenBalanceLoading ||
    props.isLoading ||
    hasDepositError;

  return (
    <StyledForm onSubmit={handleSubmit(canDeposit ? props.onNext : props.onApproveErc20)}>
      <Content>
        <StyledTitle>Deposit</StyledTitle>
        <Label>From</Label>
        <EoaDisplay />
        <Label>
          To<RequiredSpan>*</RequiredSpan>
        </Label>
        <AddressDropdownInput control={control} name="toAddress" items={toAddressOptions} />
        {toAddress === props.assetPools[SourceType.TREASURY].address && (
          <StyledWarningMsg msg="Please note the assets in Public Account is not redeemable" />
        )}
        <Label>
          Amount<RequiredSpan>*</RequiredSpan>
        </Label>
        <DepositAmountInput
          tokenOptions={tokenOptions}
          control={control}
          onAmountInputChange={onAmountInputChange}
          isHelperTextLoading={!isBaseCurrencySelected && isPriceDataLoading}
          helperText={
            toAddress === props.assetPools[SourceType.LIQUID_POOL].address &&
            tokenAddress !== props.baseCurrency?.address &&
            shouldShowAmountInBaseCurrency
              ? `${UnicodeCharacter.ALMOST_EQUAL_TO} ${toAmountString(priceData?.answer.toString() || '0', {
                  decimals: props.baseCurrency?.decimals || 0,
                  maxDecimals: 2,
                  shouldShowAlmostEqualTo: false,
                })} (${baseCurrencyDisplaySymbol})`
              : ''
          }
        />
        {isConnected && !isTokenBalanceLoading && (
          <BalanceText>
            {toAddress === props.assetPools[SourceType.LIQUID_POOL].address
              ? `Balance ${tokenBalanceDisplay} ${selectedTokenSymbol}; Lockup Period ${props.lockDays} days`
              : `Balance ${tokenBalanceDisplay} ${selectedTokenSymbol}`}
          </BalanceText>
        )}
        {hasDepositError && <StyledErrorMsg msg={errors.amount?.message} />}
      </Content>
      <SubmitButton
        variant="primary"
        type="submit"
        disabled={isSubmittingDisabled || isDisabledToJoin}
        loading={props.isLoading || isAllowanceLoading || isTokenBalanceLoading || isValidatingTokenAmount}
      >
        {nextButtonText}
      </SubmitButton>
      <DevTool control={control} />
    </StyledForm>
  );
}
