import { useCallback, useState } from 'react';
import styled from 'styled-components';
import DepositFormSection from 'components/DepositFormSection';
import { DepositFormMode } from 'components/DepositFormSection/types';
import Loading from 'components/Loading';

import useEntryFulfillments from 'hooks/useEntryFulfillments';
import useJoinDaoInfo from './hooks/useJoinDaoInfo';
import JoinDaoFormSection from './JoinDaoFormSection';

export interface Props {
  daoAddress: string;
}

const Container = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  min-width: 526px;
  max-width: 1052px;
`;

const LoadingWrapper = styled.div`
  min-width: 526px;
  max-width: 1052px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

const JoinArea = styled.div`
  flex: 1;
  width: 526px;
`;

const DepositArea = styled.div`
  flex: 1;
  width: 526px;
`;

export default function JoinDaoAndDepositFormSection(props: Props): JSX.Element {
  const { isLoading: isFulfillmentsLoading, ...fulfillments } = useEntryFulfillments(props.daoAddress);

  const { isLoading: isJoinDaoInfoLoading, data: joinDaoInfo } = useJoinDaoInfo(props.daoAddress);

  const [hasEnoughDepositAmount, setHasEnoughDepositAmount] = useState(false);

  const onHasEnoughDepositAmountChange = useCallback((value: boolean) => {
    setHasEnoughDepositAmount(value);
  }, []);

  const hasDepositSection =
    fulfillments?.minDepositAmount?.isRequired &&
    (!fulfillments.admissionTokens ||
      fulfillments.admissionTokens?.length === 0 ||
      fulfillments.admissionTokens?.every((token) => token.isFulfilled));

  const isLoading = isFulfillmentsLoading || isJoinDaoInfoLoading;

  if (isLoading) {
    return (
      <LoadingWrapper>
        <Loading isCenter />
      </LoadingWrapper>
    );
  }

  return (
    <Container>
      {!!joinDaoInfo && (
        <>
          <JoinArea>
            <JoinDaoFormSection
              daoAddress={props.daoAddress}
              fulfillments={fulfillments}
              hasEnoughDepositAmount={hasEnoughDepositAmount}
              {...joinDaoInfo}
            />
          </JoinArea>
          {hasDepositSection && (
            <DepositArea>
              <DepositFormSection
                daoAddress={props.daoAddress}
                mode={DepositFormMode.JOIN_AND_DEPOSIT}
                isOverMembersCountLimit={joinDaoInfo.isOverMembersCountLimit}
                onHasEnoughDepositAmountChange={onHasEnoughDepositAmountChange}
              />
            </DepositArea>
          )}
        </>
      )}
    </Container>
  );
}
