import { Chain } from 'adam-frontend-shared';
import { useSessionPreferences } from './useSessionPreferences';

export default function useIPFSGetUrl(cid: string | null | undefined): string | null {
  const { platformSelectedChainId } = useSessionPreferences();
  const { apiHostUrl } = Chain.CHAIN_INFO[platformSelectedChainId].envParam;

  if (!cid) {
    return null;
  }

  return `${apiHostUrl}ipfs-gateway/ipfs/${cid}/`;
}
