import { Adam, TokenInfoResponse } from '@adam-vault/adam-sdk';
import { Chain } from 'adam-frontend-shared';
import { atom } from 'jotai';
import { DEFAULT_PROVIDER } from './web3Atom';

export type DefaultBaseCurrency = TokenInfoResponse & {
  displaySymbol?: string;
};

type TokenSettingsAtom =
  | {
      supportedTokens: TokenInfoResponse[] | undefined;
      stableCoins: TokenInfoResponse[] | undefined;
      reapTokens: TokenInfoResponse[] | undefined;
      nativeToken: TokenInfoResponse | undefined | null;
      defaultBaseCurrency: DefaultBaseCurrency | undefined | null;
    }
  | undefined;

const { network, envParam } = Chain.CHAIN_INFO[Chain.DEFAULT_CHAIN_ID];
const sdkNetwork = Chain.ChainNetworkAdamSDKNetworkMapping[network];

if (!sdkNetwork) {
  throw new Error(`Network ${network} is not supported by Adam SDK`);
}

export const adamAtom = atom<Adam>(
  new Adam(envParam.adamContractAddress, DEFAULT_PROVIDER, sdkNetwork, process.env.REACT_APP_ENV)
);

export const tokenSettingsAtom = atom<TokenSettingsAtom>(undefined);
