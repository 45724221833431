import { useQuery as useHookQuery } from 'graphql-hooks';
import { useEffect, useState } from 'react';

export type ReturnType<TQueryType> = {
  loading: boolean;
  data?: TQueryType;
  error?: unknown;
};

export type Options = {
  query?: string;
  variables?: Array<any> | any;
  pollInterval?: number;
};

/*
 * Temp useQuery hook before migrating to graph-client + apollo
 *   - https://github.com/graphprotocol/graph-client/tree/main/examples/apollo
 *   - https://thegraph.com/docs/en/querying/querying-from-an-application/#apollo-client
 */
export default function useQuery<TQueryType = any>(query: string, options?: Options): ReturnType<TQueryType> {
  const { loading, data, error, refetch } = useHookQuery(query, options);
  const [isRefetching, setIsRefetching] = useState(false);
  // In order to prevent from page refresh due to loading state change, so for passive refetch (polling), will return loading as false
  const isLoading = isRefetching ? false : loading;

  useEffect(() => {
    if (!options?.pollInterval) {
      return;
    }

    const polling = setInterval(() => {
      setIsRefetching(true);
      refetch();
    }, options.pollInterval);

    return () => clearInterval(polling);
  }, [options?.pollInterval, refetch]);

  // Set is refetching state to false only after the new set of data is set
  useEffect(() => {
    if (!options?.pollInterval || !data) {
      return;
    }
    setIsRefetching(false);
  }, [data, options?.pollInterval]);

  return { data, error, loading: isLoading };
}
