import { SupportedProposalType } from './proposalType';

const PATHS = {
  BASE_PATH: process.env.REACT_APP_BASE_PATH + '#',
  HOME: '/',
  NOTFOUND: 'notFound',
  FAUCET: '/faucet',
  REWARD: '/reward',
  DAO: {
    ROOT: '/dao',
    CREATE_DAO: '/dao/add',
    CREATE_DAO_REFERRAL: '/dao/add?referral=:referral',
    DASHBOARD: '/dao/:daoAddress/dashboard',
    ACTIVITIES: '/dao/:daoAddress/activities',
    BUDGET: '/dao/:daoAddress/budget',
    BUDGET_DETAILS: '/dao/:daoAddress/budget/:budgetId',
    PROPOSALS: '/dao/:daoAddress/proposals',
    CREATE_PROPOSAL: '/dao/:daoAddress/proposals/add',
    CREATE_PROPOSAL_SPECIFIC_DETAILS_TYPE: '/dao/:daoAddress/proposals/add?proposalType=:proposalType&type=:type',
    CREATE_PROPOSAL_SPECIFIC_CONTRACT_UPGRADE: `/dao/:daoAddress/proposals/add?proposalType=${SupportedProposalType.DAO_SETTINGS}&targetVersionId=:versionId`,
    CREATE_PROPOSAL_SPECIFIC_REVOKE_BUDGET: `/dao/:daoAddress/proposals/add?proposalType=${SupportedProposalType.REVOKE_BUDGET}&budgetApproval=:budgetApprovalAddress`,
    JOIN_DAO: '/dao/:daoAddress/join',
    SET_USER_ALIAS: '/dao/:daoAddress/set-user-alias',
    DEPOSIT: '/dao/:daoAddress/deposit',
    DEPOSIT_SPECIFIC: '/dao/:daoAddress/deposit?to=:to',
    BUDGET_APPROVAL_DETAILS: '/dao/:daoAddress/budget-approval/:budgetId',
    BUDGET_APPROVAL_DETAILS_SELECTED_TRANSACTION:
      '/dao/:daoAddress/budget-approval/:budgetId/transaction/:transactionId',
    CREATE_TEAM: '/dao/:daoAddress/members/team/add',
    MEMBERS: '/dao/:daoAddress/members',
    TEAMS: '/dao/:daoAddress/members/teams',
    ASSETS: '/dao/:daoAddress/assets',
    ASSETS_TAB: '/dao/:daoAddress/assets?tab=:tab',
    TRANSACTION_LOGS: '/dao/:daoAddress/transaction-logs?source=:source',
    SETTINGS: '/dao/:daoAddress/settings',
    DAO_INFO_SETTING: '/dao/:daoAddress/settings/daoInfo',
    ENTRY_REQUIREMENTS_SETTING: '/dao/:daoAddress/settings/entryRequirements',
    DEPOSIT_SETTING: '/dao/:daoAddress/settings/deposit',
    VOTING_SETTING: '/dao/:daoAddress/settings/voting',
    CONTRACT_UPGRADE_SETTING: '/dao/:daoAddress/settings/contract-version',
  },
};

export default PATHS;
