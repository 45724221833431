import { BigNumber } from 'ethers';
import { useAdamMemo } from 'hooks/useAdam';
import useWeb3 from 'hooks/useWeb3';
import { EntryFulfillments, AdmissionTokenFulfillment } from 'types/EntryRequirements';
import useEntryRequirements from './useEntryRequirements';

type ReturnType = EntryFulfillments & {
  isLoading: boolean;
};

export default function useEntryFulfillments(daoAddress: string): ReturnType {
  const { address: eoaAddress } = useWeb3();

  const { isLoading: isRequirementLoading, ...requirements } = useEntryRequirements(daoAddress);

  const [admissionTokenFulfillments, { isLoading: isFulfillmentsLoading }] = useAdamMemo<
    Array<AdmissionTokenFulfillment>
  >(
    async (adam) => {
      if (!eoaAddress || !requirements.admissionTokens) {
        return [];
      }

      const fulfillments: Array<AdmissionTokenFulfillment> = await Promise.all(
        requirements.admissionTokens.map(async (requirement) => {
          let balance = BigNumber.from(0);
          const tokenEntity = adam.loadToken(requirement.token?.address);
          if (!!requirement.tokenId && Number(requirement.tokenId) !== 0) {
            const erc1155TokenEntity = await tokenEntity.loadAsErc1155();
            balance = await erc1155TokenEntity.balanceOf(eoaAddress, requirement.tokenId);
          } else {
            balance = await tokenEntity.balanceOf(eoaAddress);
          }
          const isFulfilled = Boolean(balance.gte(requirement.amountBN));

          return {
            ...requirement,
            isFulfilled,
          };
        })
      );

      return fulfillments;
    },
    [daoAddress, eoaAddress, requirements.admissionTokens],
    []
  );

  const isLoading = isRequirementLoading || isFulfillmentsLoading;

  return {
    ...requirements,
    admissionTokens: admissionTokenFulfillments,
    isLoading,
  };
}
