import { SourceType, TokenData, TokenInfoResponse } from '@adam-vault/adam-sdk';
import { Wallet as WalletConstants } from 'adam-frontend-shared';
import { useMemo } from 'react';
import styled from 'styled-components';
import AddressDisplay from 'components/AddressDisplay';
import Button from 'components/Button';
import Description from 'components/layout/Description';
import Title from 'components/layout/Title';
import UnicodeCharacter from 'constants/unicodeCharacter';
import useTokenSettings from 'hooks/useTokenSettings';
import useWeb3 from 'hooks/useWeb3';
import { toNumberWithCommas } from 'utils/thousandSeparatorUtils';
import { AssetPools, DepositFormMode, DepositInfoFormData } from './types';

export interface Props {
  assetPools: AssetPools;
  formData?: DepositInfoFormData;
  baseCurrency?: TokenData;
  depositTokens: TokenInfoResponse[];
  isLoading?: boolean;
  lockDays: number;
  gasFee: string;
  mode: DepositFormMode;
  isOverMembersCountLimit?: boolean;
  onBack?: () => void;
  onDeposit?: () => void;
}

const { Wallet } = WalletConstants;

const StyledTitle = styled(Title)`
  font-size: 32px;
  margin-bottom: 32px;
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: auto;
`;

const Label = styled(Description)`
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 2px;
`;

const AmountText = styled(Description)`
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: ${({ theme: { color } }): string => color.fontPrimary};
  margin-bottom: 36px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`;

const WalletLogoImg = styled.img`
  width: 20px;
  height: 20px;
`;

const AddressTitle = styled(Title)`
  font-weight: 700;
  font-size: 20px;
`;

const StyledAddressDisplay = styled(AddressDisplay)`
  margin-top: 4px;
  margin-bottom: 36px;
  padding: 4px 12px;
  background-color: ${({ theme: { color } }): string => color.textField};
  width: fit-content;
  border-radius: 4px;
`;

const GasFeeText = styled(Description)`
  margin-top: 12px;
  font-weight: 400;
  line-height: 22px;
`;

const LockupText = styled(Description)`
  margin-top: 6px;
  font-weight: 400;
  line-height: 22px;
`;

const ActionsRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const BackButton = styled(Button)`
  text-align: left;
  color: ${({ theme: { color } }): string => color.fontHighlight};
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  padding-left: 0;
  justify-content: flex-start;
`;

const DepositButton = styled(Button)`
  padding: 24px 70px;
  align-self: flex-end;
  margin-left: auto;
`;

export default function ReviewForm(props: Props): JSX.Element | null {
  const { walletType, address: eoaAddress } = useWeb3();
  const { supportedTokens, getBaseCurrencyDisplaySymbol, nativeToken } = useTokenSettings();

  const toAddressName = useMemo(
    () => Object.values(props.assetPools).find((item) => item.address === props.formData?.toAddress)?.name,
    [props.assetPools, props.formData?.toAddress]
  );

  const selectedToken = supportedTokens?.find((token) => token.address === props.formData?.token);
  const selectedTokenSymbol = selectedToken?.symbol || '';

  const baseCurrencyDisplaySymbol = getBaseCurrencyDisplaySymbol(
    props.baseCurrency?.address,
    props.depositTokens?.map(({ address }) => address)
  );

  const isDisabledToJoin = props.mode === DepositFormMode.JOIN_AND_DEPOSIT && props.isOverMembersCountLimit;

  const depositButtonText = useMemo(() => {
    if (isDisabledToJoin) {
      return 'Vault IS FULL';
    }

    if (props.mode === DepositFormMode.JOIN_AND_DEPOSIT) {
      return 'DEPOSIT TO JOIN';
    }

    return 'DEPOSIT';
  }, [isDisabledToJoin, props.mode]);

  if (!props.formData) {
    return null;
  }

  return (
    <>
      <Content>
        <StyledTitle>Review Transaction</StyledTitle>
        <Label>Deposit Amount</Label>
        <AmountText>
          {props.formData.token === props.baseCurrency?.address.toLowerCase()
            ? `${toNumberWithCommas(props.formData.amount)} ${selectedTokenSymbol}`
            : `${toNumberWithCommas(props.formData.amount)} ${selectedTokenSymbol} ${
                UnicodeCharacter.ALMOST_EQUAL_TO
              } ${toNumberWithCommas(props.formData.amountInBaseCurrency)} ${baseCurrencyDisplaySymbol}`}
        </AmountText>
        <Label>From</Label>
        {walletType && (
          <Row>
            <WalletLogoImg src={Wallet[walletType].logo} />
            <AddressTitle>{Wallet[walletType].name}</AddressTitle>
          </Row>
        )}
        {eoaAddress && <StyledAddressDisplay address={eoaAddress} isEllipsis />}
        <Label>To</Label>
        <AddressTitle>{toAddressName}</AddressTitle>
        <StyledAddressDisplay address={props.formData.toAddress} isEllipsis />
        {props.gasFee && <GasFeeText>{`Expected Gas Fee ${props.gasFee} ${nativeToken?.symbol || ''}`}</GasFeeText>}
        <LockupText>
          {props.formData.toAddress === props.assetPools[SourceType.LIQUID_POOL].address
            ? `Hard Lock-up ${props.lockDays} days`
            : 'Not Redeemable'}
        </LockupText>
      </Content>
      <ActionsRow>
        {!props.isLoading && (
          <BackButton variant="text" onClick={props.onBack}>
            BACK
          </BackButton>
        )}
        <DepositButton
          onClick={props.onDeposit}
          loading={props.isLoading}
          icon={props.mode === DepositFormMode.DEPOSIT ? 'deposit' : ''}
          disabled={isDisabledToJoin}
        >
          {depositButtonText}
        </DepositButton>
      </ActionsRow>
    </>
  );
}
