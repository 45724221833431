import { useRect } from '@reach/rect';
import { useRef } from 'react';
import styled from 'styled-components';
import { layout, LayoutProps } from 'styled-system';
import { DaoPanelViewState } from 'constants/daoParam';
import { SIDEBAR_Z_INDEX } from 'constants/dimension';
import useCurrentDao from 'hooks/useCurrentDao';
import DaoPanel from './DaoPanel';

const Container = styled.div`
  display: flex;
  height: 100%;
  position: fixed;
  z-index: ${SIDEBAR_Z_INDEX};
  top: 0;
  left: 0;
  background-color: ${({ theme: { color } }): string => color.siteBackground};
`;

const Placeholder = styled.div<LayoutProps>`
  height: 100%;
  transition: width 200ms ease;
  ${layout}
`;

export default function DaoSidebar(): JSX.Element | null {
  const ref = useRef(null);
  const rect = useRect(ref, { observe: true });
  const { daoPanelViewState } = useCurrentDao();

  if (daoPanelViewState === DaoPanelViewState.HIDDEN) {
    return null;
  }

  return (
    <>
      <Container ref={ref}>
        <DaoPanel />
      </Container>
      <Placeholder width={rect?.width} />
    </>
  );
}
