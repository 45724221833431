import { useEffect } from 'react';
import * as yup from 'yup';

const required = 'Required field';

export default function useInitYupMessages(): void {
  const defaultSetting = {
    mixed: {
      required,
      notType: function notType({ type }: any) {
        if (type === 'number') {
          return 'It must be a number';
        }
        return required;
      },
    },
  };

  // if we integrate with i18n library, we will need to listen the change of selected language
  useEffect(() => {
    yup.setLocale({ ...defaultSetting });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
