/* eslint-disable @typescript-eslint/no-unused-vars */
import { Dao, SourceType, toDecimalString, Token } from '@adam-vault/adam-sdk';
import { BigNumber } from 'ethers';
import { useAtom } from 'jotai';
import { useCallback, useEffect, useState } from 'react';
import { DaoPanelViewState } from 'constants/daoParam';
import { getDaoSupportedFeatures, SupportedFeatureType } from 'constants/supportedFeature';
import useAdam from 'hooks/useAdam';
import useTokenSettings from 'hooks/useTokenSettings';
import {
  isFetchingCurrentDaoAtom,
  currentDaoAtom,
  currentDaoBaseCurrencyAtom,
  currentDaoSupportedFeatureAtom,
  currentDaoTotalPriceBNAtom,
  currentDaoTotalPriceInTreasuryBNAtom,
  currentDaoTotalPriceInLiquidPoolBNAtom,
  isCurrentEoaACurrentDaoMemberAtom,
  doesCurrentDaoExistAtom,
  daoUrlAddressAtom,
  daoPanelViewStateAtom,
} from 'store/daoAtom';
import { toAbbreviatedLargeNumber } from 'utils/numberUtils';
import useWeb3 from './useWeb3';

type GetTotalPriceDisplayProps = { maxDecimals?: number; source?: SourceType };

interface CurrentDaoHook {
  isLoading: boolean;
  isTotalPriceLoading: boolean;
  isMemberStateLoading: boolean;
  doesDaoExist: boolean | null;
  isCurrentEoaAMember: boolean | null;
  daoUrlAddress: string;
  daoPanelViewState: DaoPanelViewState;
  currentDao: Dao | null;
  supportedFeature: SupportedFeatureType | null;
  totalPriceBN: BigNumber | null | undefined;
  totalPriceInTreasuryBN: BigNumber | null | undefined;
  totalPriceInLiquidPoolBN: BigNumber | null | undefined;
  getTotalPriceDisplay: ({ maxDecimals }: GetTotalPriceDisplayProps) => string;
  totalPriceError?: string;
  reloadTotalPrice: () => Promise<void>;
  reloadIsCurrentEoaAMember: () => Promise<void>;
  reload: () => Promise<void>;
  clear: () => void;
  setDaoPanelView: (viewState: DaoPanelViewState) => void;
}

export default function useCurrentDao(daoAddress?: string): CurrentDaoHook {
  const adam = useAdam();
  const { defaultBaseCurrency } = useTokenSettings();
  const { address: eoaAddress } = useWeb3();

  const [isLoading, setIsLoading] = useAtom(isFetchingCurrentDaoAtom);
  const [dao, setDao] = useAtom(currentDaoAtom);
  const [daoBaseCurrency, setDaoBaseCurrency] = useAtom(currentDaoBaseCurrencyAtom);
  const [supportedFeature, setSupportedFeature] = useAtom(currentDaoSupportedFeatureAtom);
  const [totalPriceBN, setTotalPriceBN] = useAtom(currentDaoTotalPriceBNAtom);
  const [totalPriceInTreasuryBN, setTotalPriceInTreasuryBN] = useAtom(currentDaoTotalPriceInTreasuryBNAtom);
  const [totalPriceInLiquidPoolBN, setTotalPriceInLiquidPoolBN] = useAtom(currentDaoTotalPriceInLiquidPoolBNAtom);
  const [daoUrlAddress, setDaoUrlAddress] = useAtom(daoUrlAddressAtom);
  const [doesDaoExist, setDoesDaoExist] = useAtom(doesCurrentDaoExistAtom);
  const [isCurrentEoaAMember, setIsCurrentEoaAMember] = useAtom(isCurrentEoaACurrentDaoMemberAtom);
  const [daoPanelViewState, setDaoPanelViewState] = useAtom(daoPanelViewStateAtom);
  const [totalPriceError, setTotalPriceError] = useState<string>();
  const [isMemberStateLoading, setIsMemberStateLoading] = useState<boolean>(true);

  const isReady = daoUrlAddress.toLowerCase() === dao?.address.toLowerCase();
  const isTotalPriceLoading = !isReady || !totalPriceBN;

  useEffect(() => {
    if (!daoAddress) {
      return;
    }
    setDaoUrlAddress(daoAddress);
  }, [daoAddress, setDaoUrlAddress]);

  const fetchDaoInfo = useCallback(
    async (address: string) => {
      const loadedDao = adam.loadDao(address);
      await loadedDao?.fetch();
      const loadedDaoBaseCurrency = await loadedDao.loadBaseCurrency();
      return { dao: loadedDao, baseCurrency: loadedDaoBaseCurrency };
    },
    [adam]
  );

  const updateSupportedFeature = useCallback(
    async (targetDao: Dao) => {
      try {
        const supported = await getDaoSupportedFeatures(targetDao);
        setSupportedFeature(supported);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.info('Failed to fetch supported features', { err });
        setSupportedFeature(null);
      }
    },
    [setSupportedFeature]
  );

  const updateTotalPriceInTreasury = useCallback(
    async (targetDao: Dao, targetDaoBaseCurrency: Token) => {
      if (!defaultBaseCurrency) {
        return;
      }

      const { answer: treasuryBalanceInDaoBaseCurrency } = await targetDao.totalPriceInDefaultBaseCurrency(
        SourceType.TREASURY
      );

      const treasuryBalanceInDefaultBaseCurrency =
        targetDaoBaseCurrency.address === defaultBaseCurrency.address
          ? treasuryBalanceInDaoBaseCurrency
          : (
              await adam
                .loadFeedRegistry()
                .getPrice(targetDaoBaseCurrency.address, defaultBaseCurrency.address, treasuryBalanceInDaoBaseCurrency)
                .catch(() => null)
            )?.answer;

      setTotalPriceInTreasuryBN(treasuryBalanceInDefaultBaseCurrency);

      return treasuryBalanceInDefaultBaseCurrency;
    },
    [adam, defaultBaseCurrency, setTotalPriceInTreasuryBN]
  );

  const updateTotalPriceInLiquidPool = useCallback(
    async (targetDao: Dao, targetDaoBaseCurrency: Token) => {
      if (!defaultBaseCurrency) {
        return;
      }

      const { answer: lpBalanceInDaoBaseCurrency } = await targetDao.totalPriceInDefaultBaseCurrency(
        SourceType.LIQUID_POOL
      );

      const lpBalanceInDefaultBaseCurrency =
        targetDaoBaseCurrency.address === defaultBaseCurrency.address
          ? lpBalanceInDaoBaseCurrency
          : (
              await adam
                .loadFeedRegistry()
                .getPrice(targetDaoBaseCurrency.address, defaultBaseCurrency.address, lpBalanceInDaoBaseCurrency)
                .catch(() => null)
            )?.answer;

      setTotalPriceInLiquidPoolBN(lpBalanceInDefaultBaseCurrency);

      return lpBalanceInDefaultBaseCurrency;
    },
    [adam, defaultBaseCurrency, setTotalPriceInLiquidPoolBN]
  );

  const updateTotalPrice = useCallback(
    async (targetDao: Dao, targetDaoBaseCurrency: Token) => {
      try {
        const totalInTreasury = await updateTotalPriceInTreasury(targetDao, targetDaoBaseCurrency);
        const totalInLiquidPool = await updateTotalPriceInLiquidPool(targetDao, targetDaoBaseCurrency);

        const totalBN = totalInTreasury && totalInLiquidPool ? totalInTreasury.add(totalInLiquidPool) : null;
        setTotalPriceError(undefined);
        setTotalPriceBN(totalBN);
      } catch (e) {
        const error = e as unknown as Error;
        setTotalPriceError(error.message);
      }
    },
    [setTotalPriceBN, updateTotalPriceInLiquidPool, updateTotalPriceInTreasury]
  );

  const reloadTotalPrice = useCallback(async () => {
    if (!dao || !daoBaseCurrency) {
      return;
    }

    updateTotalPrice(dao, daoBaseCurrency);
  }, [dao, daoBaseCurrency, updateTotalPrice]);

  const getTotalPriceDisplay = useCallback(
    ({ source, maxDecimals = 4 }: GetTotalPriceDisplayProps) => {
      let priceBN;
      switch (source) {
        case SourceType.TREASURY:
          priceBN = totalPriceInTreasuryBN;
          break;
        case SourceType.LIQUID_POOL:
          priceBN = totalPriceInLiquidPoolBN;
          break;
        default:
          priceBN = totalPriceBN;
      }

      if (!isReady || !priceBN) {
        return '';
      }

      return toAbbreviatedLargeNumber(
        toDecimalString(priceBN, {
          decimals: defaultBaseCurrency?.decimals || 0,
          maxDecimals,
        }),
        false
      );
    },
    [defaultBaseCurrency, isReady, totalPriceBN, totalPriceInLiquidPoolBN, totalPriceInTreasuryBN]
  );

  const updateIsCurrentEoaAMember = useCallback(
    async (targetDao: Dao) => {
      if (!targetDao) {
        return;
      }

      if (!eoaAddress) {
        setIsCurrentEoaAMember(false);
        return;
      }

      const membershipEntity = await targetDao.loadMembership();
      const hasMember = await membershipEntity.isMember(eoaAddress);
      setIsCurrentEoaAMember(hasMember);
    },
    [eoaAddress, setIsCurrentEoaAMember]
  );

  const reloadIsCurrentEoaAMember = useCallback(async () => {
    if (!dao) {
      return;
    }

    return updateIsCurrentEoaAMember(dao);
  }, [dao, updateIsCurrentEoaAMember]);

  const reload = useCallback(async () => {
    // Reset
    setIsLoading(true);
    setDoesDaoExist(null);
    setIsCurrentEoaAMember(null);
    setSupportedFeature(null);
    setTotalPriceBN(null);

    if (!daoAddress) {
      setIsLoading(false);
      return;
    }

    // Update DAO basic info
    try {
      const { dao: fetchedDao, baseCurrency: fetchedDaoBaseCurrency } = await fetchDaoInfo(daoAddress);
      // Update other info
      await updateIsCurrentEoaAMember(fetchedDao);
      await updateSupportedFeature(fetchedDao);
      await updateTotalPrice(fetchedDao, fetchedDaoBaseCurrency);
      setDoesDaoExist(true);
      setDao(fetchedDao);
      setDaoBaseCurrency(fetchedDaoBaseCurrency);
    } catch {
      setDoesDaoExist(false);
      setDao(null);
      setDaoBaseCurrency(null);
    }
    setIsLoading(false);
  }, [
    setIsLoading,
    setDoesDaoExist,
    setIsCurrentEoaAMember,
    setSupportedFeature,
    setTotalPriceBN,
    daoAddress,
    fetchDaoInfo,
    updateIsCurrentEoaAMember,
    updateSupportedFeature,
    updateTotalPrice,
    setDao,
    setDaoBaseCurrency,
  ]);

  const clear = useCallback(() => {
    setDao(null);
    setDaoBaseCurrency(null);
    setTotalPriceBN(null);
    setDaoUrlAddress('');
    setDoesDaoExist(null);
    setIsCurrentEoaAMember(null);
  }, [setDao, setDaoBaseCurrency, setTotalPriceBN, setDaoUrlAddress, setDoesDaoExist, setIsCurrentEoaAMember]);

  const setDaoPanelView = useCallback(
    (viewState: DaoPanelViewState) => {
      setDaoPanelViewState(viewState);
    },
    [setDaoPanelViewState]
  );

  // Fetch again eoa member state when eoa address change
  useEffect(() => {
    reloadIsCurrentEoaAMember();
  }, [reloadIsCurrentEoaAMember]);

  useEffect(() => {
    setIsMemberStateLoading(isCurrentEoaAMember === null);
  }, [isCurrentEoaAMember]);

  return {
    isLoading,
    isTotalPriceLoading,
    isMemberStateLoading,
    doesDaoExist,
    isCurrentEoaAMember,
    daoUrlAddress,
    daoPanelViewState,
    currentDao: isReady ? dao : null,
    supportedFeature,
    totalPriceBN: isReady ? totalPriceBN : null,
    totalPriceInTreasuryBN: isReady ? totalPriceInTreasuryBN : null,
    totalPriceInLiquidPoolBN: isReady ? totalPriceInLiquidPoolBN : null,
    totalPriceError,
    getTotalPriceDisplay,
    reloadTotalPrice,
    reloadIsCurrentEoaAMember,
    reload,
    clear,
    setDaoPanelView,
  };
}
