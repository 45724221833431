import { generatePath } from 'react-router-dom';
import styled from 'styled-components';
import AddressDisplay from 'components/AddressDisplay';
import Button from 'components/Button';
import InfoItemCard from 'components/InfoItemCard';
import Description from 'components/layout/Description';
import Rect from 'components/layout/Rect';
import StylelessLink from 'components/layout/StylelessLink';
import Subtitle from 'components/layout/SubTitle';
import Title from 'components/layout/Title';
import Loading from 'components/Loading';
import PATHS from 'constants/paths';
import { SupportedProposalType } from 'constants/proposalType';
import useMemberTokenInfo from 'hooks/useMemberTokenInfo';
import useWeb3 from 'hooks/useWeb3';
import { BudgetPresetType } from 'pages/CreateProposal/types/budgetPreset';

interface Props {
  daoAddress: string;
}

const StyledTitle = styled(Title)`
  margin-bottom: 4px;
`;

const TitleSection = styled.div`
  margin-bottom: 24px;
`;

const SymbolTag = styled(Rect)`
  display: inline-block;
  padding: 4px 12px;
  margin: 0 8px;
  font-size: 18px;
  font-weight: 500;
`;

const InfoSection = styled.div`
  display: grid;
  gap: 10px;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr 1fr;
`;

const RedirectSection = styled.div`
  margin-top: ${({ theme: { space } }) => `${space[4]}px`};
`;

const CreateProposalButton = styled(Button)`
  margin-top: ${({ theme: { space } }) => `${space[3]}px`};
`;

export default function MemberTokenTab({ daoAddress }: Props): JSX.Element | null {
  const { isConnected } = useWeb3();
  const { memberToken, hasMemberToken, totalSupply, balanceInDao, loading } = useMemberTokenInfo(daoAddress);
  const totalQuantity = !!totalSupply ? totalSupply.displayString : '';
  const balance = !!balanceInDao ? balanceInDao.displayString : '';

  if (loading) {
    return <Loading isCenter />;
  }

  if (!memberToken) {
    return null;
  }

  return (
    <>
      <TitleSection>
        <StyledTitle>
          {memberToken.name}
          <SymbolTag>{memberToken.symbol}</SymbolTag>
        </StyledTitle>
        <AddressDisplay
          address={memberToken.address}
          isAvatarShown={false}
          copiedMessage="The member token address is copied to clipboard!"
        />
      </TitleSection>
      {hasMemberToken && (
        <>
          <InfoSection>
            <InfoItemCard label="Total Quantity" value={totalQuantity} />
            <InfoItemCard label="Balance in Public Account" value={balance} />
          </InfoSection>
          {isConnected && (
            <RedirectSection>
              <Subtitle>Airdrop</Subtitle>
              <Description>Airdrop Vault tokens by creating a budget.</Description>
              <StylelessLink
                to={generatePath(PATHS.DAO.CREATE_PROPOSAL_SPECIFIC_DETAILS_TYPE, {
                  daoAddress,
                  proposalType: SupportedProposalType.MEMBER_TOKEN,
                  type: BudgetPresetType.TOKEN_TRANSFER,
                })}
              >
                <CreateProposalButton>Create Airdrop</CreateProposalButton>
              </StylelessLink>
            </RedirectSection>
          )}
        </>
      )}
    </>
  );
}
