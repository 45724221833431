import styled from 'styled-components';
import Button from 'components/Button';
import { rectCss } from 'components/layout/Rect';

export interface WalletButtonProps {
  logo: string;
  name: string;
  gaName?: string;
  onClick: () => void;
}

const WalletButtonWrapper = styled(Button)`
  ${rectCss}
  width: 100%;
  height: 80px;
  padding: 32px 28px;
  background-color: ${({ theme: { color } }) => color.walletButtonInModalBackground};

  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px;
  margin-bottom: 16px;
`;

const WalletLogo = styled.img`
  width: 48px;
  height: 48px;
`;

const WalletName = styled.div`
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
`;

export default function WalletButton({ logo, name, gaName, onClick }: WalletButtonProps): JSX.Element {
  return (
    <WalletButtonWrapper className={gaName && `ga-button__choose-wallet-${gaName}`} onClick={onClick}>
      {name && <WalletName>{name}</WalletName>}
      <WalletLogo src={logo} />
    </WalletButtonWrapper>
  );
}
