import styled from 'styled-components';
import Label, { Props as LabelProps } from './Label';

export interface Props extends LabelProps {}

const ErrorLabel = styled(Label)<Props>`
  color: ${({ theme: { color } }) => color.error};
`;

export default ErrorLabel;
