import { useState, useEffect } from 'react';
import TxStatus from 'constants/txStatus';

type TxError = {
  error?: {
    message: string;
  };
  message: string;
};

type ReturnType = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  submitTx: (txPromise: Promise<any>) => Promise<TxStatus>;
  clearTxRecord: () => void;
  txStatus: TxStatus;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  receipt: any;
  errorMessage: string;
  loading: boolean;
};

export default function useTxSender(): ReturnType {
  const [txStatus, setTxStatus] = useState(TxStatus.NO_TRANSACTION);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [receipt, setReceipt] = useState<any>(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const clearTxRecord = (): void => {
    setTxStatus(TxStatus.NO_TRANSACTION);
    setReceipt(null);
    setErrorMessage('');
  };

  const getErrorMessage = (err: TxError): string =>
    `Transaction Rejected (${(err.error?.message || err.message).replace(/execution reverted:./, '')})`;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const submitTx = async (txPromise: Promise<any>): Promise<TxStatus> => {
    setTxStatus(TxStatus.PENDING);
    setReceipt(null);
    setErrorMessage('');
    try {
      setLoading(true);
      const tx = await txPromise;
      if (!tx) {
        setLoading(false);
        setErrorMessage('No transaction is submitted');
        setTxStatus(TxStatus.ERROR);
        return TxStatus.ERROR;
      }

      // Default is 1, and sometime 1 block is not enough for subgraph to sync the block state and upate data
      const txReceipt = await tx.wait(2);
      setReceipt(txReceipt);
      setLoading(false);
      if (txReceipt.status) {
        setTxStatus(TxStatus.COMPLETED);
        return TxStatus.COMPLETED;
      }
      setTxStatus(TxStatus.ERROR);
      return TxStatus.ERROR;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('[integration] submit transaction error: ', { err });
      setErrorMessage(getErrorMessage(err as TxError));
      setReceipt(null);
      setLoading(false);
      setTxStatus(TxStatus.ERROR);
      return TxStatus.ERROR;
    }
  };

  useEffect(() => {
    if (txStatus === TxStatus.COMPLETED) {
      // eslint-disable-next-line no-console
      console.log('[integration] transaction completed', receipt);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [txStatus]);

  return { submitTx, clearTxRecord, txStatus, receipt, errorMessage, loading };
}
