const externalUrl = {
  ADAM_DOCS: 'https://docs.adamdao.io/',
  ADAM_WHITEPAPER: 'https://docs.google.com/document/d/1nWiaJN6LVxY_G8S44-8tCsGWWsncta65gFC50jCXF18/',
  ADAM_CONTRACT_VERSIONS: 'https://docs.adamdao.io/',

  TNC_CONNECT_WALLET: 'https://docs.adamvault.com/terms-and-conditions',
  TNC_CREATE_VAULT: 'https://docs.adamvault.com/terms-and-conditions',
  TNC_JOIN_VAULT: 'https://docs.adamvault.com/terms-and-conditions',
  TNC_REWARD: 'https://docs.adamvault.com/terms-and-conditions',
};

export default externalUrl;
