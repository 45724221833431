import styled from 'styled-components';
import DaoAvatar from 'components/DaoAvatar';
import Description from 'components/layout/Description';
import Title from 'components/layout/Title';
import Loading from 'components/Loading';
import { useAdamMemo } from 'hooks/useAdam';

export interface Props {
  daoAddress: string;
  name: string;
}

const Container = styled.div`
  display: flex;
  column-gap: 24px;
  margin-bottom: 24px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const DaoTitle = styled(Title)`
  font-size: 32px;
  line-height: 40px;
  font-weight: 700;
`;

const DaoTypeTag = styled(Description)`
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  font-size: 16px;
  color: ${({ theme: { color } }) => color.fontHighlight};
`;

export default function JoinDaoHeader(props: Props): JSX.Element {
  const { daoAddress, name } = props;
  const [daoLogoCID, { isLoading }] = useAdamMemo(
    async (adamEntity) => {
      const daoEntity = adamEntity.loadDao(!!daoAddress ? daoAddress : '');
      const logoCID = await daoEntity.logoCID();
      return !!logoCID ? logoCID : '';
    },
    [daoAddress],
    ''
  );
  return (
    <Container>
      {isLoading ? <Loading /> : <DaoAvatar daoAddress={daoAddress} logoCID={daoLogoCID} size="80px" />}
      <Content>
        <DaoTitle>{name}</DaoTitle>
        <DaoTypeTag>Public</DaoTypeTag>
      </Content>
    </Container>
  );
}
