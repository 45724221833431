import { BigNumber } from 'ethers';
import { useMemo } from 'react';
import styled from 'styled-components';
import Loading from 'components/Loading';
import Text from 'components/Text';
import { AssetSource } from 'constants/asset';
import { USDC_BASE_CURRENCY_DISPLAY } from 'constants/token';
import useTokenSettings from 'hooks/useTokenSettings';
import { toAmountString } from 'utils/numberUtils';
import PoolCard from './PoolCard';

interface Props {
  selectedPoolId: string | undefined;
  vaultPoolOptions: PoolOption[];
  personalPoolOptions: PoolOption[];
  onPoolCardClick: (id: string) => void;
  treasuryPriceBN: BigNumber | undefined;
  liquidPoolPriceBN: BigNumber | undefined;
  segregateAccountPriceBN: BigNumber | undefined;
}

export type PoolOption = {
  id: string;
  sourceType: AssetSource;
  label: string;
  icon: string;
  address?: string;
  priceInBaseCurrency: BigNumber | undefined;
};

const Wrapper = styled.div`
  padding: 64px 24px;
  background-color: ${({ theme: { color } }) => color.poolMenuBackground};
  display: flex;
  flex-direction: column;
  gap: 36px;

  border-left: 1px solid ${({ theme: { color } }): string => color.daoPanelPageContentSeparator};
`;

const PoolSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const SectionTitleBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SectionTitle = styled(Text).attrs({
  category: 'title-3',
  weight: 'bold',
})``;

interface SumInBaseCurrencyProps {
  sumBN: BigNumber | undefined;
}

function SumInBaseCurrency({ sumBN }: SumInBaseCurrencyProps): JSX.Element {
  const { defaultBaseCurrency } = useTokenSettings();

  const sumString = useMemo(() => {
    if (!defaultBaseCurrency || !sumBN) {
      return undefined;
    }

    return toAmountString(sumBN.toString(), {
      decimals: defaultBaseCurrency.decimals,
      maxDecimals: 2,
    });
  }, [defaultBaseCurrency, sumBN]);

  if (sumBN === undefined) {
    return <Loading />;
  }

  return (
    <Text category="body">
      {USDC_BASE_CURRENCY_DISPLAY} {sumString}
    </Text>
  );
}

interface PoolCardWrapperProps {
  opt: PoolOption;
  selectedPoolId: Props['selectedPoolId'];
  onPoolCardClick: Props['onPoolCardClick'];
}

const PoolCardWrapper = ({ opt, selectedPoolId, onPoolCardClick }: PoolCardWrapperProps): JSX.Element => {
  const { defaultBaseCurrency } = useTokenSettings();

  const priceInBaseCurrencyString = useMemo(() => {
    if (!opt.priceInBaseCurrency || !defaultBaseCurrency) {
      return undefined;
    }

    return toAmountString(opt.priceInBaseCurrency.toString(), {
      decimals: defaultBaseCurrency.decimals,
      maxDecimals: 2,
      shouldShowAlmostEqualTo: false,
    });
  }, [defaultBaseCurrency, opt.priceInBaseCurrency]);

  return (
    <PoolCard
      id={opt.id}
      title={opt.label}
      icon={opt.icon}
      priceInBaseCurrencyString={priceInBaseCurrencyString}
      address={opt.address}
      isSelected={opt.id === selectedPoolId}
      onClick={onPoolCardClick}
    />
  );
};

export default function PoolMenuBar({
  selectedPoolId,
  vaultPoolOptions,
  personalPoolOptions,
  onPoolCardClick,
  treasuryPriceBN,
  liquidPoolPriceBN,
  segregateAccountPriceBN,
}: Props): JSX.Element {
  const daoPoolsTotalPriceBN = useMemo(() => {
    if (!treasuryPriceBN || !liquidPoolPriceBN) {
      return undefined;
    }

    return treasuryPriceBN.add(liquidPoolPriceBN);
  }, [liquidPoolPriceBN, treasuryPriceBN]);

  return (
    <Wrapper>
      <PoolSection>
        <SectionTitleBar>
          <SectionTitle>Vault</SectionTitle>
          <SumInBaseCurrency sumBN={daoPoolsTotalPriceBN} />
        </SectionTitleBar>
        {vaultPoolOptions.map((opt: PoolOption) => (
          <PoolCardWrapper opt={opt} selectedPoolId={selectedPoolId} onPoolCardClick={onPoolCardClick} />
        ))}
      </PoolSection>

      <PoolSection>
        <SectionTitleBar>
          <SectionTitle>Personal</SectionTitle>
          <SumInBaseCurrency sumBN={segregateAccountPriceBN} />
        </SectionTitleBar>
        {personalPoolOptions.map((opt: PoolOption) => (
          <PoolCardWrapper opt={opt} selectedPoolId={selectedPoolId} onPoolCardClick={onPoolCardClick} />
        ))}
      </PoolSection>
    </Wrapper>
  );
}
