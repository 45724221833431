import { trimTrailingZero } from '@adam-vault/adam-sdk';
import { Web3 } from 'adam-frontend-shared';
import { BigNumber } from 'ethers';
import { useAdamMemo } from 'hooks/useAdam';
import useTokenSettings from 'hooks/useTokenSettings';
import { AssetPrices, Assets } from '../types';

type ReturnType = {
  assets: Assets;
  assetPrices: AssetPrices | undefined;
  isFetchingAssets: boolean;
  isFetchingPrices: boolean;
  refetch: () => void;
};

export default function useSegregateAccountAssets(eoaAddress: Web3['address'], daoAddress: string): ReturnType {
  const { defaultBaseCurrency } = useTokenSettings();

  const [assets, { isLoading: isFetchingAssets, refetch }] = useAdamMemo(
    async (adam) => {
      if (!eoaAddress) {
        return { erc20: [] };
      }
      const liquidPool = await adam.loadDao(daoAddress).loadLiquidPool();
      const memberToken = await adam.loadDao(daoAddress).memberToken();
      const result = await liquidPool.assetsOf(eoaAddress);
      return {
        erc20: result
          .filter((r) => +r.balance > 0 && r.address.toLowerCase() !== memberToken?.toLowerCase())
          .map((r) => ({
            address: r.address,
            balanceBN: r.balance,
            balance: trimTrailingZero(r.balanceStr),
            name: r.name,
            symbol: r.symbol,
          })),
      };
    },
    [daoAddress],
    { erc20: [] }
  );

  const [assetPrices, { isLoading: isFetchingPrices }] = useAdamMemo(
    async (adam) => {
      if (!defaultBaseCurrency) {
        return undefined;
      }
      const erc20Prices = await Promise.all(
        assets.erc20.map((tokenInfo) =>
          adam
            .loadFeedRegistry()
            .getPrice(tokenInfo.address, defaultBaseCurrency.address, tokenInfo.balanceBN)
            .catch(() => null)
        )
      );

      return {
        erc20: assets.erc20.reduce(
          (result, tokenInfo, index) => ({
            ...result,
            [tokenInfo.address]: erc20Prices[index],
          }),
          {}
        ),
        erc20TotalPriceBN: erc20Prices.reduce(
          (result, priceResult) => result.add(priceResult?.answer || BigNumber.from(0)),
          BigNumber.from(0)
        ),
      };
    },
    [assets],
    undefined
  );

  return { assets, assetPrices, isFetchingAssets, isFetchingPrices, refetch };
}
