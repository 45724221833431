import { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import Icon from 'components/Icon';
import StylelessButton from 'components/layout/StylelessButton';
import Modal from 'components/Modal';
import browserUtils from 'utils/browserUtils';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${({ theme: { color } }): string => color.jacarta};
  border-radius: 8px;
  padding: 10px 16px;
`;

const CopyButton = styled(StylelessButton)`
  display: flex;
  align-items: center;
  height: 20px;
  margin-right: 14px;
  font-weight: 700;
  margin-left: 1em;
  color: ${({ theme: { color } }): string => color.violetsBlue};
`;

const Text = styled.div`
  margin-left: 6px;
  font-weight: 700;
  font-size: 16px;
`;

type Props = {
  className?: string;
  text: string;
  modalContentLabel: string;
  modalContent: string;
};

export default function CopyableText({ className, text, modalContentLabel, modalContent }: Props): JSX.Element {
  const theme = useTheme();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const closeModal = (): void => setIsModalOpen(false);

  const clickCopyAddress = (): void => {
    browserUtils.copyToClipboard(text);
    setIsModalOpen(true);
  };

  return (
    <Container className={className}>
      {text}
      <CopyButton onClick={clickCopyAddress}>
        <Icon icon="copy" color={theme.color.violetsBlue} />
        <Text>COPY</Text>
      </CopyButton>
      <Modal isOpen={isModalOpen} contentLabel={modalContentLabel} onRequestClose={closeModal} onConfirm={closeModal}>
        {modalContent}
      </Modal>
    </Container>
  );
}
