import { InputHTMLAttributes } from 'react';
import { useController } from 'react-hook-form';
import styled from 'styled-components';
import HookFormFieldProps from 'types/HookFormFieldProps';

export interface Props extends HookFormFieldProps, InputHTMLAttributes<HTMLInputElement> {}

interface UncontrolledInputProps extends InputHTMLAttributes<HTMLInputElement> {
  setRef?: (el: React.ReactNode, name: string) => void;
}

export const Input = styled.input`
  width: 100%;
  margin: 0;
  color: ${({ theme: { color } }) => color.fontPrimary};
  position: relative;
  font-weight: 400;
  outline: none;
  border: none;
  line-height: 1.5;
  padding: 0;
  background-color: transparent;

  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  font-size: 14px;

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    margin: 0;
    -webkit-appearance: none;
  }

  ::placeholder {
    color: ${({ theme: { color } }) => color.fontSecondary};
  }
`;

function ControlledInput(props: Props) {
  const { name = 'input', control, setRef, ...baseProps } = props;

  const { field } = useController({ name, control });

  const onChange = (e: any) => {
    if (baseProps.onChange) {
      baseProps.onChange(e);
    }
    field.onChange(e);
  };

  const onBlur = (e: any) => {
    if (baseProps.onBlur) {
      baseProps.onBlur(e);
    }
    field.onBlur();
  };

  return (
    <Input
      {...baseProps}
      name={name}
      value={baseProps.value ?? field.value}
      onChange={onChange}
      onBlur={onBlur}
      ref={(el) => setRef && setRef(el, name)}
    />
  );
}

function UncontrolledInput(props: UncontrolledInputProps) {
  const { name, setRef, ...baseProps } = props;

  return <Input {...baseProps} name={name} value={baseProps.value} ref={(el) => setRef && setRef(el, name || '')} />;
}

export default function StylelessInput(props: Props) {
  const { control, ...baseProps } = props;
  return control ? <ControlledInput {...props} /> : <UncontrolledInput {...baseProps} />;
}
