import { Suspense } from 'react';
import Loading from 'components/Loading';

interface Props {
  children: React.ReactNode;
}

export default function PageWrapper(props: Props): JSX.Element {
  const { children } = props;
  return <Suspense fallback={<Loading isCenter />}>{children}</Suspense>;
}
