import styled from 'styled-components';
import GeneratedAvatar, { GeneratedAvatarVariant } from 'components/GeneratedAvatar';
import Avatar, { AvatarProps } from 'components/layout/Avatar';
import useIPFSGetUrl from 'hooks/useIPFSGetUrl';

export interface Props extends AvatarProps {
  daoAddress: string;
  logoCID?: string;
  variant?: GeneratedAvatarVariant;
}

interface DaoImageProps {
  size?: string | number;
}

const DaoImage = styled.img<DaoImageProps>`
  width: ${({ size }): string => (!!size?.toString() ? size.toString() : '40px')};
  height: ${({ size }): string => (!!size?.toString() ? size.toString() : '40px')};
  border-radius: 50%;
  object-fit: cover;
`;

export default function DaoAvatar(props: Props): JSX.Element {
  const { className, size, daoAddress, logoCID, variant, onClick } = props;
  // NOTE: This line confirm generated avatar is same in spite of lower case or upper case
  const avatarDaoAddress = daoAddress.toLowerCase();
  const imageUrl = useIPFSGetUrl(logoCID);

  return (
    <Avatar className={className} size={size} onClick={onClick}>
      {!!imageUrl ? (
        <DaoImage src={imageUrl} size={size} />
      ) : (
        <GeneratedAvatar seed={avatarDaoAddress} size={size} variant={variant} />
      )}
    </Avatar>
  );
}
