import { useAtom } from 'jotai';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import Flex from 'components/layout/Flex';
import useCurrentDao from 'hooks/useCurrentDao';
import { daoUrlAddressAtom } from 'store/daoAtom';
import MenuLinkItem from './MenuLinkItem';
import { CollapsedMenuProps } from './index';

interface Props extends CollapsedMenuProps {}

type MenuItem = {
  label: string;
  icon: string;
  toPage: string;
};

const Container = styled(Flex)<CollapsedMenuProps>`
  flex: 1;
  position: relative;
  margin-top: ${({ isCollapsed }) => (isCollapsed ? '50px' : '20px')};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const MENU_ITEMS: MenuItem[] = [
  {
    label: 'DASHBOARD',
    icon: 'dashboard',
    toPage: 'dashboard',
  },
  {
    label: 'VAULT',
    icon: 'vault',
    toPage: 'assets',
  },
  {
    label: 'BUDGET',
    icon: 'budgetplan',
    toPage: 'budget',
  },
  {
    label: 'VOTING',
    icon: 'voting',
    toPage: 'proposals',
  },
  {
    label: 'MEMBERS',
    icon: 'member',
    toPage: 'members',
  },
  {
    label: 'SETTINGS',
    icon: 'setting',
    toPage: 'settings',
  },
];

export default function NavigationMenu(props: Props): JSX.Element {
  const { isCollapsed } = props;
  const location = useLocation();

  const [daoAddress] = useAtom(daoUrlAddressAtom);
  const { doesDaoExist } = useCurrentDao();

  const selectedIndex = MENU_ITEMS.findIndex((x) => location.pathname.includes(`/dao/${daoAddress}/${x.toPage}`));

  return (
    <Container flexDirection="column" isCollapsed={isCollapsed}>
      {MENU_ITEMS.map(
        (item: MenuItem, index: number): JSX.Element => (
          <MenuLinkItem
            key={`nav-menu__item-${item.toPage}`}
            isSelected={selectedIndex === index}
            label={item.label}
            icon={item.icon}
            to={`/dao/${daoAddress}/${item.toPage}`}
            disabled={index > 0 && !doesDaoExist}
            isCollapsed={isCollapsed}
            index={index}
          />
        )
      )}
    </Container>
  );
}
