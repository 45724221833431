import { useState, useEffect } from 'react';
import usePollingQuery from 'hooks/usePollingQuery';

export type Dao = {
  id: string;
  name: string;
};

type Member = {
  dao: Dao;
};

type QueryData = {
  account: {
    id: string;
    members: Member[];
  };
};

type ReturnType = {
  joinedDaos?: Dao[];
  loading: boolean;
};

const QUERY = `
query DaoListQuery($eoaAddress: ID = "") {
  account(id: $eoaAddress) {
    id
    members {
      dao {
        id
        name
      }
    }
  }
}
`;

export default function useJoinedDaos(accountAddress: string): ReturnType {
  const {
    data,
    loading: queryLoading,
    error,
  } = usePollingQuery<QueryData>(QUERY, { variables: { eoaAddress: accountAddress.toLowerCase() } });
  const [joinedDaos, setJoinedDaos] = useState<Dao[]>();
  const [loading, setLoading] = useState<boolean>(queryLoading);

  useEffect(() => {
    setLoading(true);
    if (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      return;
    }

    if (!data) {
      return;
    }

    const daos = data.account?.members.map((member: Member) => member.dao) || [];
    setJoinedDaos(daos);
    setLoading(false);
  }, [data, error]);

  return { joinedDaos, loading };
}
