import { useAtom } from 'jotai';
import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { onOpenModalCountAtom } from 'store/modalAtom';

interface UseIsScrollableReturnType {
  isScrollable: boolean;
  setIsScrollable: Dispatch<SetStateAction<boolean>>;
}

export default function useIsScrollable(): UseIsScrollableReturnType {
  const [onOpenModalCount] = useAtom(onOpenModalCountAtom);
  const [isScrollable, setIsScrollable] = useState(true);

  useEffect(() => {
    setIsScrollable(onOpenModalCount === 0);
  }, [onOpenModalCount]);

  return { isScrollable, setIsScrollable };
}
