import React from 'react';
import styled from 'styled-components/macro';

import ConnectButton from './ConnectButton';

const Wrapper = styled.div`
  padding: 0 10px;
  margin-top: 16px;
  margin-bottom: 20px;

  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
  align-self: flex-end;
  z-index: 100;
`;

const Header: React.FC = () => (
  <Wrapper>
    <ConnectButton />
  </Wrapper>
);

export default Header;
