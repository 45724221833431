import styled from 'styled-components';
import { space, SpaceProps } from 'styled-system';

const Subtitle = styled.p<SpaceProps>`
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  color: ${({ theme: { color } }) => color.fontPrimary};
  ${space}
`;

Subtitle.defaultProps = {
  marginTop: 2,
  marginBottom: 2,
};

export default Subtitle;
