import { format as d3Format } from 'd3-format';
import { intervalToDuration, format as dateFormat } from 'date-fns';
import fromUnixTime from 'date-fns/fromUnixTime';

export const toEllipsisAddress = (address: string | null | undefined): string => {
  if (!address) {
    return '';
  }
  return address.slice(0, 5) + '...' + address.slice(-4);
};

export const toEllipsisName = (name: string): string => {
  if (!name) {
    return '';
  }
  return name.length > 9 ? name.slice(0, 5) + '...' + name.slice(-4) : name;
};

// Convert a token balance in decimal to a display format
export const toTokenBalanceDisplay = (value: number | string, decimals: number = 8): string => {
  let numStr = String(value);
  if (/\./.test(numStr)) {
    // eg. 0.123456789 -> 0.12345678
    numStr = numStr.substring(0, numStr.indexOf('.') + decimals + 1);
  }
  return d3Format(`,.${decimals}~f`)(Number(numStr));
};

export const formatPercentage = (value: number | string, digits = 2) => d3Format(`.${digits}~%`)(Number(value));

export const formatProgressNumber = (value: number | string) => d3Format('.2f')(Number(value)).replace(/.00$/, '');

export const getDuration = (value: string) => {
  if (!value) {
    return '';
  }

  const time = intervalToDuration({ start: 0, end: parseFloat(value) * 1000 });

  const format = (formatValue: number, wording: string, formatValue2: number | undefined, wording2: string) => {
    let output = '';
    const pluralWording = formatValue > 1 ? `${wording}s` : wording;
    output = `${formatValue} ${pluralWording}`;
    if (formatValue2 && formatValue2 > 0) {
      const pluralWording2 = formatValue2 > 1 ? `${wording2}s` : wording2;
      output = `${output} ${formatValue2} ${pluralWording2}`;
    }
    return output;
  };

  if (time.years && time.years > 0) {
    return format(time.years, 'Year', time.months, 'Month');
  }
  if (time.months && time.months > 0) {
    return format(time.months, 'Month', time.days, 'Day');
  }
  if (time.days && time.days > 0) {
    return format(time.days, 'Day', time.hours, 'Hour');
  }
  if (time.hours && time.hours > 0) {
    return format(time.hours, 'Hour', time.minutes, 'Minute');
  }
  if (time.minutes && time.minutes > 0) {
    return format(time.minutes, 'Minute', time.seconds, 'Second');
  }
  if (time.seconds && time.seconds > 0) {
    return format(time.seconds, 'Second', undefined, '');
  }
  return '0 Second';
};

export const toDurationDays = (value: string): string => {
  if (!value) {
    return '';
  }

  const days = Math.floor(Number(value) / 86400);

  return `${days} ${days > 1 ? 'Days' : 'Day'}`;
};

export const formatUnixTime = (unixTime: string, format: string = 'MMM dd, yyyy') =>
  dateFormat(fromUnixTime(Number(unixTime)), format);
