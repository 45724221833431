import styled from 'styled-components';
import InputLayout from 'components/form/InputLayout';
import StylelessInput, { Props as InputProps } from 'components/form/StylelessInput';
import Description from 'components/layout/Description';

export interface Props extends InputProps {
  className?: string;
  error?: string;
  helperText?: string;
}

const Container = styled.div`
  display: flex;
  height: 100%;
`;

const HelperDescription = styled(Description)`
  margin-left: 6px;
  min-width: 100px;
  text-align: right;
`;

export default function TextInput(props: Props): JSX.Element {
  const { error, className, ...inputProps } = props;

  if (props.hidden) {
    return <StylelessInput {...inputProps} />;
  }

  return (
    <InputLayout className={className} error={error}>
      <Container>
        <StylelessInput {...inputProps} />
        {props.helperText && <HelperDescription>{props.helperText}</HelperDescription>}
      </Container>
    </InputLayout>
  );
}
