import { toBigNumber, toDecimalString, ToDecimalStringOptions } from '@adam-vault/adam-sdk';
import { BigNumber } from 'ethers';
import UnicodeCharacter from 'constants/unicodeCharacter';
import { toNumberWithCommas } from './thousandSeparatorUtils';

export const isNumber = (value?: string): boolean => {
  if (!value) {
    return false;
  }
  return !Number.isNaN(parseFloat(value));
};

export const toAbbreviatedLargeNumber = (value: string | number, shouldShowAlmostEqualTo: boolean = true): string => {
  if (Number(value) < 1e6) {
    return toNumberWithCommas(`${value}`);
  }

  // NOTE: There was a discussion before which concludes the minimum conversion is M. There's no need for 1e3 (K).
  const abbreviations = [
    { value: 1e21, symbol: 's' },
    { value: 1e18, symbol: 'Q' },
    { value: 1e15, symbol: 'q' },
    { value: 1e12, symbol: 't' },
    { value: 1e9, symbol: 'B' },
    { value: 1e6, symbol: 'M' },
  ];

  const abbreviation = abbreviations.find((x) => value >= x.value);
  if (abbreviation) {
    const largeNumber = Math.floor((Number(value) / abbreviation.value) * 100) / 100;
    return `${shouldShowAlmostEqualTo ? UnicodeCharacter.ALMOST_EQUAL_TO : ''}${toNumberWithCommas(`${largeNumber}`)}${
      abbreviation.symbol
    }`;
  }
  return '0';
};

const isEqualZero = (value: string): boolean => /^0+[.]?0*$/.test(value);

/**
 * Returns an array that contains `value` as a base-10 string that can be used to construct a BigNumber and
 * conditionally a second item which indicates the input is a decimal number.
 * e.g. getBigNumberString('14.32') => ['1432', 2],
 *      getBigNumberString('14.8964') => ['148964', 4],
 *      getBigNumberString('1432') => ['1432', 0]
 * @param value
 */
export const getBigNumberString = (value: string | number): [string, number] => {
  if (!value) {
    return ['0', 0];
  }

  const valueString = value.toString();
  // "10.0000100".split('.') => ['10', '0000100']
  const tmp = valueString.split('.');

  if (tmp.length === 1) {
    return [tmp[0], 0];
  }

  if (tmp.length === 2) {
    const decimalLength = tmp[1].length;
    return [tmp[0] + tmp[1], decimalLength];
  }

  return [valueString, 0];
};

interface ToAmountStringOptions extends ToDecimalStringOptions {
  withCommas?: boolean;
  shouldShowAlmostEqualTo?: boolean;
}

/**
 *
 * @param value decimal number (12.532) or an integer/integer string (12532/'12532') representing a big number
 * @param options {@link ToDecimalStringOptions} in sdk with additional option(s) for display purposes
 * @returns
 */
export const toAmountString = (value: string | number, options?: ToAmountStringOptions): string => {
  const {
    decimals = 0,
    maxDecimals,
    withCommas = true,
    roundMode,
    shouldTrimTrailingZero = true,
    shouldShowAlmostEqualTo = true,
  } = options || {};

  const [numStr, nDecimalsInValue] = getBigNumberString(value);
  const isOriginallyZero = isEqualZero(numStr);
  const isMaxDecimalsSmaller = maxDecimals !== undefined && maxDecimals < decimals;

  let result;
  result = toDecimalString(toBigNumber(numStr, 0), {
    decimals: nDecimalsInValue || decimals,
    // if nDecimalsInValue is not null, maxDecimals will be min(decimals, maxDecimals)
    maxDecimals: !nDecimalsInValue || isMaxDecimalsSmaller ? maxDecimals : decimals,
    roundMode,
    shouldTrimTrailingZero,
  });

  const isZero = isEqualZero(result);
  if (withCommas) {
    result = toNumberWithCommas(result);
  }

  if (isZero && !isOriginallyZero) {
    result = `${shouldShowAlmostEqualTo ? UnicodeCharacter.ALMOST_EQUAL_TO : ''} ${result}`;
  }

  return result;
};

export const calPercentageFromBNs = (
  numeratorBN: BigNumber,
  denominatorBN: BigNumber,
  options?: { decimals?: number; maxDecimals?: number }
): string => {
  const { decimals = 18, maxDecimals = 2 } = options || {};
  /*
   * Multiply a bit the numerator to keep decimal results in the BigNumber.
   *   decimals: to cancel out the effect of token decimals
   *   + 6: combined with times 100 with 3 more decimals, the 3rd decimal is for rounding
   *        eg. 6 / 7 = 0.8571428... * 100 * 1000 = 85714.28... which should be BigNumber(85714)
   */
  const multiplierDecimals = decimals + 6;
  const multiplier = BigNumber.from('10').pow(multiplierDecimals);

  if (denominatorBN.isZero()) {
    return 'N/A';
  }

  return toAmountString(numeratorBN.mul(multiplier).div(denominatorBN).toString(), {
    decimals: multiplierDecimals - 2,
    maxDecimals,
  });
};

export const toPercentageStr = (value: string | number): string => {
  if (typeof value === 'string') {
    value = Number(value);
  }

  if (Number.isNaN(value)) {
    return '';
  }

  return (value * 100).toString();
};
