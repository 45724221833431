// NOTE: Please feel free to use this wrapper for your page if you are implementing old design
// Please note this component will be removed once we update all pages to new design

import { ReactNode } from 'react';
import styled from 'styled-components';
import Header from 'components/Header';

interface Props {
  children: ReactNode;
}

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0 10px;
  margin-bottom: 32px;
  max-width: 1200px;
`;

const Wrapper = styled.div`
  width: 100%;
  min-height: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
`;

const Inner = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 1200px;
`;

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  min-height: 600px;
  width: 100%;
  height: 100%;
  padding: 0 10px;
  padding: 0;
  overflow-x: hidden;
  margin-bottom: 32px;
`;

export function OldDesignWrapper(props: Props): JSX.Element {
  return (
    <Container>
      <Wrapper>
        <Inner>
          <Header />
          <Content>{props.children}</Content>
        </Inner>
      </Wrapper>
    </Container>
  );
}
