import styled, { useTheme } from 'styled-components';
import Icon from 'components/Icon';
import Description from 'components/layout/Description';
import { StyledComponentsTheme } from 'types/StyledComponentsTheme';

export interface Props {
  isFulfilled?: boolean;
  children: React.ReactNode;
}

const Container = styled.div`
  display: flex;
  align-items: center;
  column-gap: 12px;
`;

const StyledDescription = styled(Description)<{ isFulfilled?: Props['isFulfilled'] }>`
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: ${({ theme: { color }, isFulfilled }) => (isFulfilled ? color.fontPrimary : color.disabled)};

  & span {
    color: ${({ theme: { color }, isFulfilled }) => (isFulfilled ? color.fontHighlight : color.disabled)};
  }
`;

export default function Requirement(props: Props): JSX.Element {
  const { color } = useTheme() as StyledComponentsTheme;

  return (
    <Container>
      <Icon icon="field_verified" size="20px" color={props.isFulfilled ? color.approve : color.disabled} />
      <StyledDescription isFulfilled={props.isFulfilled}>{props.children}</StyledDescription>
    </Container>
  );
}
