import { useState, useEffect } from 'react';
import styled from 'styled-components';
import Icon from 'components/Icon';
import Description from 'components/layout/Description';
import Loading from 'components/Loading';
import DaoListItem from './DaoListItem';
import WelcomeCard from './WelcomeCard';
import useJoinedDaos, { Dao as DaoType } from '../hooks/useJoinedDaos';

const V_PADDING = '30px';
const H_PADDING = '24px';

interface Props {
  eoaAddress: string;
  currentDaoAddress: string;
  onDaoSelect: (dao?: DaoType) => void;
  onBack: () => void;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
`;

const Toolbar = styled.div`
  padding: ${V_PADDING} ${H_PADDING} 42px ${H_PADDING};
  height: 100px;
`;

const SectionTitle = styled(Description)`
  padding: 0 ${H_PADDING};
  padding-bottom: 16px;
`;

const CurrentDaoSection = styled.div`
  padding-bottom: 32px;
  color: ${({ theme: { color } }): string => color.secondary}; ;
`;

const DaoListSection = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
`;

export default function SwitchDaoPanel({ eoaAddress, currentDaoAddress, onDaoSelect, onBack }: Props): JSX.Element {
  const { joinedDaos, loading } = useJoinedDaos(eoaAddress);
  const [currentDao, setCurrentDao] = useState<DaoType | null | undefined>();
  const [switchableDaos, setSwitchableDaos] = useState<DaoType[]>([]);

  useEffect(() => {
    if (!joinedDaos) {
      return;
    }

    const activeDao = joinedDaos.find((dao: DaoType) => dao.id === currentDaoAddress);
    const otherDaos = joinedDaos.filter((dao: DaoType) => dao.id !== currentDaoAddress);

    if (!activeDao) {
      setCurrentDao(null);
    } else if (activeDao.id !== currentDao?.id) {
      setCurrentDao(activeDao);
      onDaoSelect(activeDao);
    }

    setSwitchableDaos(otherDaos);
  }, [currentDao?.id, currentDaoAddress, joinedDaos, onDaoSelect]);

  const hasJoinedDaos = !!joinedDaos?.length;

  return (
    <Wrapper>
      {loading && <Loading isCenter />}
      {!loading && hasJoinedDaos && <Toolbar>{currentDao && <Icon icon="back" onClick={onBack} />}</Toolbar>}
      {!loading && (
        <>
          {currentDao && (
            <CurrentDaoSection>
              <SectionTitle>Active</SectionTitle>
              <DaoListItem dao={currentDao} />
            </CurrentDaoSection>
          )}
          {hasJoinedDaos ? (
            <DaoListSection>
              <SectionTitle>Switch to</SectionTitle>
              {switchableDaos.map((dao) => (
                <DaoListItem key={`switch-dao-list__switchable-dao-${dao.id}`} dao={dao} />
              ))}
            </DaoListSection>
          ) : (
            <WelcomeCard />
          )}
        </>
      )}
    </Wrapper>
  );
}
