import { useState } from 'react';
import styled from 'styled-components';
import Loading from 'components/Loading';
import OnlyDaoMember from 'components/OnlyDaoMember';
import useTreasuryAssets from './hooks/useTreasuryAssets';
import ImportCard from './ImportCard';
import ImportNftModal from './ImportNftModal';
import NftCard from './NftCard';

export interface Props {
  daoAddress: string;
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

export default function NftTab({ daoAddress }: Props): JSX.Element {
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);

  const { assets: treasuryAssets, isFetchingAssets: isLoading, refetch } = useTreasuryAssets(daoAddress);
  const nfts = treasuryAssets?.erc721;

  const handleImportModalOpen = (): void => {
    setIsImportModalOpen(true);
  };

  const handleImportModalClose = (): void => setIsImportModalOpen(false);

  const handleOnImportComplete = (): void => {
    refetch();
    handleImportModalClose();
  };

  if (isLoading) {
    return <Loading isCenter isFullWidth />;
  }

  return (
    <>
      <Container>
        {nfts?.map((nft) => (
          <NftCard
            key={`${nft.address}-${nft.tokenId}`}
            imageUrl={nft.image}
            name={nft.name}
            tokenId={nft.tokenId}
            address={nft.address}
          />
        ))}
      </Container>

      {/* Import card and modal */}
      <OnlyDaoMember>
        <>
          <ImportCard onImportButtonClick={handleImportModalOpen} />
          <ImportNftModal
            isOpen={isImportModalOpen}
            handleImportModalClose={handleImportModalClose}
            daoAddress={daoAddress}
            onImportComplete={handleOnImportComplete}
          />
        </>
      </OnlyDaoMember>
    </>
  );
}
