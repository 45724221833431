import { transparentize } from 'polished';
import { createGlobalStyle } from 'styled-components';
import { StyledComponentsTheme } from 'types/StyledComponentsTheme';

const SCROLLBAR_HEIGHT = 6;

// TODO update ThemeType
const GlobalStyle = createGlobalStyle<{ theme: StyledComponentsTheme }>`
  *,
  ::after,
  ::before {
    box-sizing: border-box;
  }

  textarea,
  input[type='range'],
  input,
  input:matches([type='password'], [type='search']) {
    padding: 1px 2px; // HOTFIX: override safari user agent stylesheet
  }

  html {
    height: 100%;
  }

  body {
    margin: 0;
    font-weight: 300;
    font-size: 14px;
    height: 100%;
    font-family: ${({ theme: { fontFamily } }) => fontFamily.primary};
    color: ${({ theme: { color } }) => color.fontPrimary};
    line-height: 1.4;
    background-color: ${({ theme: { color } }) => color.black2};
    word-wrap: break-word;
    word-break: break-word;
  }

  #root {
    height: 100%
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }

  button {
    font-weight: 300;
    /* font-family: $primary-font-family; */
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;

    &:focus {
      outline: none;
    }

    &:disabled {
      /* background-color: $button-disabled-background-color; */
      cursor: default !important;
    }

    &[type='button']:not(:disabled),
    &[type='reset']:not(:disabled),
    &[type='submit']:not(:disabled),
    &:not(:disabled) {
      cursor: pointer;
    }
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    margin: 0;
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
  }

  button,
  input {
    overflow: visible;
  }

  a {
    text-decoration: none;
    color: ${({ theme: { color } }) => color.link};
  }

  /* Scrollbar */
  // Firefox styles
  scrollbar-color: ${({ theme: { color } }) => transparentize(0.6, color.scrollThumbColor)};
  scrollbar-width: thin;

  // Webkit supported browsers styles
  &::-webkit-scrollbar {
    width: 6px;
    height: ${SCROLLBAR_HEIGHT}px;
    scroll-behavior: smooth;
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: ${({ theme: { color } }) => transparentize(0.6, color.scrollThumbColor)};
      border-radius: ${SCROLLBAR_HEIGHT / 2}px;
    }
  }

  // override white background style on Chrome's user agent stylesheet when selecting auto-fill options
  // from past inputs
  input:-webkit-autofill {
    -webkit-text-fill-color: white;
    -webkit-box-shadow: 0 0 0 50px ${({ theme: { color } }): string => color.field} inset;
    caret-color: white;
  }
`;

export default GlobalStyle;
