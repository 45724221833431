import styled, { useTheme } from 'styled-components';
import Button from 'components/Button';
import Tabs, { Props as TabsProps } from 'components/Tabs';

interface Props {
  tabs: TabsProps['tabs'];
  focusedTabId: TabsProps['focusedTabId'];
  onTabChange: TabsProps['onTabChange'];
  onTransactionsButtonClick: () => void;
}

const TabsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

const StyledTabs = styled(Tabs)`
  width: fit-content;
  margin: 12px 0;
`;

export default function TabBar({ tabs, focusedTabId, onTabChange, onTransactionsButtonClick }: Props): JSX.Element {
  const { color } = useTheme();

  return (
    <TabsContainer>
      <StyledTabs variant="bubble" tabs={tabs} focusedTabId={focusedTabId} onTabChange={onTabChange} />
      <Button
        variant="text"
        icon="transaction_record"
        iconColor={color.actionPrimary}
        onClick={onTransactionsButtonClick}
      >
        Transactions
      </Button>
    </TabsContainer>
  );
}
