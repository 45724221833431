export enum SupportedProposalType {
  TREASURY_BUDGET = 'TreasuryBudget',
  BUDGET = 'BudgetApproval',
  MEMBER_TOKEN = 'MemberToken',
  DAO_SETTINGS = 'DaoSettings',
  TEAM = 'Team',
  GENERAL_VOTING = 'General',
  REVOKE_BUDGET = 'RevokeBudget',
}

export enum BudgetApprovalSubcategoryType {
  BUDGET_SWAP = 'budget_swap',
  BUDGET_OUTFLOW = 'budget_outflow',
  BUDGET_OUTFLOW_LIQUID = 'budget_outflow_liquid',
  MIX = 'mix',
  REVOKE_BUDGET_TREASURY = 'revoke_budget_treasury',
  REVOKE_BUDGET_LIQUID = 'revoke_budget_liquid',
}

export enum DaoSettingsSubcategoryType {
  CONTRACT_UPGRADE = 'contract_upgrade',
}

export enum GeneralSubcategoryType {
  GENERAL = 'general',
}
