import { Chain, Wallet as WalletConstants, Web3 } from 'adam-frontend-shared';
import { ethers } from 'ethers';
import { atom, Setter } from 'jotai';
import { atomWithStorage, RESET } from 'jotai/utils';
import isNil from 'lodash/isNil';

type NetworkInfo = {
  chainId: Chain.ChainId;
  ensAddress?: string;
  name: string;
};

export const DEFAULT_PROVIDER = window.ethereum
  ? new ethers.providers.Web3Provider(window.ethereum, 'any')
  : new ethers.providers.JsonRpcProvider(Chain.CHAIN_INFO[Chain.DEFAULT_CHAIN_ID].envParam.privateRpcUrl);

const isReadyAtom = atom<boolean>(false);

const ethersLibAtom = atom<typeof ethers>(ethers);

const isConnectedAtom = atom<boolean>(false);

const isConnectingAtom = atom<boolean>(false);

const connectorAtom = atom<WalletConstants.Connector | null>(null);

const providerAtom = atom<ethers.providers.Web3Provider | ethers.providers.Provider | null>(DEFAULT_PROVIDER);

const signerAtom = atom<ethers.Signer | null>(null);

const chainIdAtom = atom<number | null>(null);

const addressAtom = atom<string | null>(null);

const walletTypeAtom = atomWithStorage<WalletConstants.WalletType | null>('walletType', null, sessionStorage as any);

const errorAtom = atom<Error | null>(null);

const isEOAAddressUpdatedAtom = atom<boolean>(false);

// TODO find a way to reset
const resetSigner = (set: Setter): void => {
  set(addressAtom, null);
  set(chainIdAtom, null);
  set(isConnectingAtom, false);
  set(isConnectedAtom, false);
};

const onEthereumProviderAccountChanged =
  (address: string, set: Setter) =>
  (accounts: string[]): void => {
    if (accounts.length === 0) {
      return;
    }
    const newAddress = accounts[0];
    if (newAddress !== address) {
      set(isEOAAddressUpdatedAtom, true);
    } else {
      set(isEOAAddressUpdatedAtom, false);
    }
  };

// TODO clean up
const web3Atom = atom(
  (get) => ({
    isReady: get(isReadyAtom),
    isConnecting: get(isConnectingAtom),
    isConnected: get(isConnectedAtom),
    ethers: get(ethersLibAtom),
    connector: get(connectorAtom),
    provider: get(providerAtom),
    signer: get(signerAtom),
    chainId: get(chainIdAtom),
    address: get(addressAtom),
    walletType: get(walletTypeAtom),
    error: get(errorAtom),
    isEOAAddressUpdated: get(isEOAAddressUpdatedAtom),
  }),
  async (get, set, arg: Web3) => {
    const { isReady, address, connector, provider, signer, walletType, isConnecting, error } = arg;
    // Update connector
    if (connector) {
      set(connectorAtom, connector);
    }

    // Update provider
    if (provider) {
      set(providerAtom, provider);

      // TODO Improve it by changing to use wagmi
      provider.on('network', (newNetwork: NetworkInfo, oldNetwork: NetworkInfo | null) => {
        // When a Provider makes its initial connection, it emits a "network"
        // event with a null oldNetwork along with the newNetwork. So, if the
        // oldNetwork exists, it represents a changing network
        if (oldNetwork) {
          set(chainIdAtom, newNetwork.chainId);
        }
      });

      // FIXME: There should be type issue for the providers
      const p = provider as ethers.providers.Web3Provider;
      if (p.provider.isMetaMask) {
        window.ethereum.on('accountsChanged', onEthereumProviderAccountChanged(address ?? '', set));
      }
    } else if (!!window?.ethereum?.removeListener) {
      window.ethereum.removeListener('accountsChanged', onEthereumProviderAccountChanged(address ?? '', set));
    }

    // Update signer related atoms
    if (signer) {
      set(signerAtom, signer);
      try {
        const eoaAddress = await signer.getAddress();
        const chainId = await signer.getChainId();
        set(addressAtom, eoaAddress.toLowerCase());
        set(chainIdAtom, chainId);
        set(isConnectingAtom, false);
        set(isConnectedAtom, true);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('EOA not signed', e);
        resetSigner(set);
      }
    } else if (signer === null) {
      resetSigner(set);
    }

    // Update walletType
    if (walletType) {
      set(walletTypeAtom, walletType);
    } else if (walletType === null) {
      set(walletTypeAtom, RESET);
    }

    // Update error
    if (error !== undefined) {
      set(errorAtom, error);
    }

    // Update isConnecting
    if (!isNil(isConnecting)) {
      set(isConnectingAtom, isConnecting);
    }

    // Update isReady
    if (isReady !== undefined) {
      set(isReadyAtom, isReady);
    }
  }
);

export default web3Atom;
