import { useMemo } from 'react';
import { useController } from 'react-hook-form';
import styled, { css } from 'styled-components';
import DropdownMenu from 'components/DropdownMenu';
import HookFormFieldProps from 'types/HookFormFieldProps';

export type DropdownOptionItem = {
  text: string;
  title?: string;
  value: string;
};

export interface Props extends HookFormFieldProps {
  name: string;
  className?: string;
  error?: string;
  items: Array<DropdownOptionItem>;
  hidden?: boolean;
  marginBelowInput?: number;
  popoverZIndex?: number;
}

const StyledDropdownMenu = styled(DropdownMenu)<{ hidden: Props['hidden'] }>`
  width: 100%;

  .dropdown__menu {
    width: 100%;
  }

  /* Hide the input if hidden */
  ${({ hidden }) =>
    hidden &&
    css`
      display: none;
    `};
`;

export default function DropdownInput(props: Props): JSX.Element {
  const { field } = useController({ control: props.control, name: props.name });
  const dropdownItems = useMemo(
    () =>
      props.items.map((x) => ({
        key: x.value,
        content: x.text,
        title: x.title,
        onSelect: (): void => field.onChange(x.value),
      })),
    [field, props.items]
  );

  return (
    <StyledDropdownMenu
      className={props.className}
      buttonText={props.items.find((x) => x.value === field.value)?.text ?? field.value}
      items={dropdownItems}
      hidden={props.hidden}
      marginOfPopover={props.marginBelowInput}
      popoverZIndex={props.popoverZIndex}
    />
  );
}
