import styled from 'styled-components';
import AddressDisplay from 'components/AddressDisplay';

const IMG_HEIGHT = '250px';
const CARD_WIDTH = '250px';
const TOP_BORDER_CSS = 'border-radius: 12px 12px 0 0;';

const Container = styled.div`
  max-width: ${CARD_WIDTH};
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.color.yankeesBlue};
  ${TOP_BORDER_CSS}
`;

const NftImg = styled.img`
  min-height: ${IMG_HEIGHT};
  height: ${IMG_HEIGHT};
  width: ${CARD_WIDTH};
  object-fit: cover;
  ${TOP_BORDER_CSS}
`;

const InfoWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 16px;
`;

const NameIdWrapper = styled.div``;

const TokenName = styled.div`
  font-size: 16px;
  font-weight: 700;
  word-break: break-all;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
`;

const TokenId = styled.div`
  font-size: 13px;
  color: ${({ theme }) => theme.color.languidLavender};
  margin-bottom: 16px;
`;

type Props = {
  name: string;
  tokenId: string;
  imageUrl: string;
  address: string;
};

export default function NftCard(props: Props): JSX.Element {
  const { name, tokenId, imageUrl, address } = props;
  return (
    <Container>
      <NftImg src={imageUrl} />
      <InfoWrapper>
        <NameIdWrapper>
          <TokenName>{name}</TokenName>
          <TokenId>#{tokenId}</TokenId>
        </NameIdWrapper>
        <AddressDisplay address={address} isEllipsis isBorderShown />
      </InfoWrapper>
    </Container>
  );
}
