import { lazy } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import Home from 'components/Home';
import { OldDesignWrapper } from 'components/OldDesignWrapper';
import PageWrapper from 'components/PageWrapper';
import Error404Page from 'pages/Error/404';

const DaoDashboard = lazy(() => import('pages/DaoDashboard'));
const DaoActivities = lazy(() => import('pages/DaoActivities'));
const CreateDao = lazy(() => import('pages/CreateDao'));
const CreateProposal = lazy(() => import('pages/CreateProposal'));
const ProposalList = lazy(() => import('pages/ProposalList'));
const ProposalDetails = lazy(() => import('pages/ProposalDetails'));
const BudgetList = lazy(() => import('pages/BudgetList'));
const JoinDao = lazy(() => import('pages/Join'));
const BudgetExecution = lazy(() => import('pages/BudgetExecution'));
const BudgetApprovalDetails = lazy(() => import('pages/BudgetApprovalDetails'));
const DaoAssets = lazy(() => import('pages/DaoAssets'));
const TransactionLogs = lazy(() => import('pages/TransactionLogs'));
const MemberListing = lazy(() => import('pages/MemberListing'));
const CreateTeam = lazy(() => import('pages/CreateTeam'));
const Settings = lazy(() => import('pages/Settings'));
const DaoInfoSetting = lazy(() => import('pages/Settings/DaoInfo'));
const EntryRequirementsSetting = lazy(() => import('pages/Settings/EntryRequirements'));
const DepositSetting = lazy(() => import('pages/Settings/Deposit'));
const VotingSetting = lazy(() => import('pages/Settings/Voting'));
const ContractVersionsSetting = lazy(() => import('pages/Settings/ContractVersions'));
const Deposit = lazy(() => import('pages/Deposit'));
const UserAliasForm = lazy(() => import('pages/UserAliasForm'));
const Faucet = lazy(() => import('pages/Faucet'));
const Reward = lazy(() => import('pages/Reward'));

const PageContent = (): JSX.Element => (
  <Routes>
    <Route
      path="/"
      element={
        <OldDesignWrapper>
          <Home />
        </OldDesignWrapper>
      }
    />
    <Route
      path="/notFound"
      element={
        <OldDesignWrapper>
          <Error404Page />
        </OldDesignWrapper>
      }
    />
    <Route
      path="faucet"
      element={
        <PageWrapper>
          <OldDesignWrapper>
            <Faucet />
          </OldDesignWrapper>
        </PageWrapper>
      }
    />
    <Route
      path="reward"
      element={
        <PageWrapper>
          <OldDesignWrapper>
            <Reward />
          </OldDesignWrapper>
        </PageWrapper>
      }
    />
    <Route path="/dao/*">
      <Route
        path="add"
        element={
          <PageWrapper>
            <CreateDao />
          </PageWrapper>
        }
      />
      <Route
        path=":daoAddress/join"
        element={
          <PageWrapper>
            <OldDesignWrapper>
              <JoinDao />
            </OldDesignWrapper>
          </PageWrapper>
        }
      />
      <Route path=":daoAddress" element={<Navigate to="dashboard" replace />} />
      <Route
        path=":daoAddress/dashboard"
        element={
          <PageWrapper>
            <OldDesignWrapper>
              <DaoDashboard />
            </OldDesignWrapper>
          </PageWrapper>
        }
      />
      <Route
        path=":daoAddress/activities"
        element={
          <PageWrapper>
            <OldDesignWrapper>
              <DaoActivities />
            </OldDesignWrapper>
          </PageWrapper>
        }
      />
      <Route
        path=":daoAddress/assets"
        element={
          <PageWrapper>
            <DaoAssets />
          </PageWrapper>
        }
      />
      <Route
        path=":daoAddress/transaction-logs"
        element={
          <PageWrapper>
            <OldDesignWrapper>
              <TransactionLogs />
            </OldDesignWrapper>
          </PageWrapper>
        }
      />
      <Route
        path=":daoAddress/proposals"
        element={
          <PageWrapper>
            <OldDesignWrapper>
              <ProposalList />
            </OldDesignWrapper>
          </PageWrapper>
        }
      />
      <Route
        path=":daoAddress/proposals/add"
        element={
          <PageWrapper>
            <CreateProposal />
          </PageWrapper>
        }
      />
      <Route
        path=":daoAddress/proposals/:id"
        element={
          <PageWrapper>
            <OldDesignWrapper>
              <ProposalDetails />
            </OldDesignWrapper>
          </PageWrapper>
        }
      />
      <Route
        path=":daoAddress/budget"
        element={
          <PageWrapper>
            <OldDesignWrapper>
              <BudgetList />
            </OldDesignWrapper>
          </PageWrapper>
        }
      />
      <Route
        path=":daoAddress/budget/:budgetId"
        element={
          <PageWrapper>
            <OldDesignWrapper>
              <BudgetExecution />
            </OldDesignWrapper>
          </PageWrapper>
        }
      />
      <Route
        path=":daoAddress/budget-approval/:budgetId"
        element={
          <PageWrapper>
            <OldDesignWrapper>
              <BudgetApprovalDetails />
            </OldDesignWrapper>
          </PageWrapper>
        }
      />
      <Route
        path=":daoAddress/budget-approval/:budgetId/transaction/:transactionId"
        element={
          <PageWrapper>
            <OldDesignWrapper>
              <BudgetApprovalDetails />
            </OldDesignWrapper>
          </PageWrapper>
        }
      />
      <Route
        path=":daoAddress/members"
        element={
          <PageWrapper>
            <OldDesignWrapper>
              <MemberListing />
            </OldDesignWrapper>
          </PageWrapper>
        }
      />
      <Route
        path=":daoAddress/members/:memberType"
        element={
          <PageWrapper>
            <OldDesignWrapper>
              <MemberListing />
            </OldDesignWrapper>
          </PageWrapper>
        }
      />
      <Route
        path=":daoAddress/members/team/add"
        element={
          <PageWrapper>
            <OldDesignWrapper>
              <CreateTeam />
            </OldDesignWrapper>
          </PageWrapper>
        }
      />
      <Route path=":daoAddress/settings">
        <Route
          path=""
          element={
            <PageWrapper>
              <OldDesignWrapper>
                <Settings />
              </OldDesignWrapper>
            </PageWrapper>
          }
        />
        <Route
          path="daoInfo"
          element={
            <PageWrapper>
              <OldDesignWrapper>
                <DaoInfoSetting />
              </OldDesignWrapper>
            </PageWrapper>
          }
        />
        <Route
          path="entryRequirements"
          element={
            <PageWrapper>
              <OldDesignWrapper>
                <EntryRequirementsSetting />
              </OldDesignWrapper>
            </PageWrapper>
          }
        />
        <Route
          path="deposit"
          element={
            <PageWrapper>
              <OldDesignWrapper>
                <DepositSetting />
              </OldDesignWrapper>
            </PageWrapper>
          }
        />
        <Route
          path="voting"
          element={
            <PageWrapper>
              <OldDesignWrapper>
                <VotingSetting />
              </OldDesignWrapper>
            </PageWrapper>
          }
        />
        <Route
          path="contract-version"
          element={
            <PageWrapper>
              <OldDesignWrapper>
                <ContractVersionsSetting />
              </OldDesignWrapper>
            </PageWrapper>
          }
        />
      </Route>

      <Route
        path=":daoAddress/deposit"
        element={
          <PageWrapper>
            <OldDesignWrapper>
              <Deposit />
            </OldDesignWrapper>
          </PageWrapper>
        }
      />
      <Route
        path=":daoAddress/set-user-alias"
        element={
          <PageWrapper>
            <OldDesignWrapper>
              <UserAliasForm />
            </OldDesignWrapper>
          </PageWrapper>
        }
      />
    </Route>
  </Routes>
);

export default PageContent;
