import { MouseEventHandler } from 'react';
import styled, { useTheme } from 'styled-components';
import iconDefs from 'assets/icon/symbol-defs.svg';
import defaultTheme from 'theme/default';

export interface Props {
  className?: string;
  icon: string;
  size?: string;
  color?: string;
  isGradient?: boolean;
  title?: string;
  index?: number;
  onClick?: MouseEventHandler<HTMLDivElement>;
}

type WrapperProps = {
  className?: Props['className'];
  onClick?: Props['onClick'];
};

const Wrapper = styled.div<WrapperProps>`
  // For fixing the unexpected space at the bottom of the icon
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: ${({ onClick }): string => (!!onClick ? 'pointer' : 'unset')};
`;

const AnimatedSvg = styled.svg<{ size: string; color: string; isGradient: boolean; linearGradientId?: string }>`
  width: ${({ size }): string => size};
  height: ${({ size }): string => size};
  fill: ${({ isGradient, color, linearGradientId }): string => (isGradient ? `url(#${linearGradientId})` : color)};

  /* FIXME Support gradient fill color change */
  transition: fill 0.2s;
`;

export default function Icon(props: Props): JSX.Element {
  const theme = useTheme() as typeof defaultTheme;

  const {
    className,
    icon,
    size = '22px',
    color = theme.color.fontPrimary,
    isGradient = false,
    title,
    onClick,
    index,
  } = props;

  const linearGradientId = `gradientDef-${icon}-${index}`;

  return (
    <Wrapper className={className} onClick={onClick} title={title}>
      <svg width="0" height="0">
        <linearGradient id={linearGradientId} x1="100%" y1="100%" x2="0%" y2="100%">
          <stop className="stop-1" stopColor={theme.color.secondary} offset="0" />
          <stop stopColor={theme.color.primary} offset="102.23%" />
        </linearGradient>
      </svg>

      <AnimatedSvg
        className={`icon icon-${icon}`}
        size={size}
        color={color}
        isGradient={isGradient}
        linearGradientId={linearGradientId}
      >
        <use xlinkHref={`${iconDefs}#icon-${icon}`} />
      </AnimatedSvg>
    </Wrapper>
  );
}
