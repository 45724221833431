import { SourceType, TokenData } from '@adam-vault/adam-sdk';
import { Chain } from 'adam-frontend-shared';
import { useCallback, useMemo } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import ActionButton from 'components/ActionButton';
import Description from 'components/layout/Description';
import ExternalLink from 'components/layout/ExternalLink';
import TxStatusModal from 'components/TxStatusModal';
import externalUrl from 'constants/externalUrl';
import { GlobalModal } from 'constants/globalModal';
import PATHS from 'constants/paths';
import TxStatus from 'constants/txStatus';
import useAdam from 'hooks/useAdam';
import useCurrentDao from 'hooks/useCurrentDao';
import useGlobalModal from 'hooks/useGlobalModal';
import useTxSender from 'hooks/useTxSender';
import useWeb3 from 'hooks/useWeb3';
import { EntryFulfillments } from 'types/EntryRequirements';

export interface Props {
  fulfillments: EntryFulfillments;
  daoAddress: string;
  baseCurrency: TokenData;
  isOverMembersCountLimit: boolean;
}

const SubmitButton = styled(ActionButton)`
  height: 48px;
`;

const AgreementText = styled(Description)`
  margin-top: 16px;
  font-size: 13px;
  font-weight: 400;
  color: ${({ theme: { color } }): string => color.fontSecondary};
`;

export default function JoinDaoFooter(props: Props): JSX.Element | null {
  const {
    fulfillments: { admissionTokens, minDepositAmount },
    isOverMembersCountLimit,
  } = props;

  const navigate = useNavigate();
  const { isConnected, chainId } = useWeb3();
  const { reloadIsCurrentEoaAMember } = useCurrentDao();
  const { submitTx, txStatus, errorMessage } = useTxSender();
  const { openModal: openConnectModal } = useGlobalModal(GlobalModal.CONNECT);
  const { closeModal: closeJoinDaoModal } = useGlobalModal(GlobalModal.JOIN_DAO_AND_DEPOSIT);

  const adamEntity = useAdam();

  const unfulfilledAdmissionToken = useMemo(
    () => admissionTokens?.find((item) => !item.isFulfilled),
    [admissionTokens]
  );

  const navigateToEtherscan = useCallback(() => {
    if (!chainId || !unfulfilledAdmissionToken) {
      return;
    }
    window.open(`${Chain.CHAIN_INFO[chainId].blockExplorerUrl}address/${unfulfilledAdmissionToken.token?.address}`);
  }, [chainId, unfulfilledAdmissionToken]);

  const joinWithoutDeposit = useCallback(async () => {
    const daoEntity = adamEntity.loadDao(props.daoAddress);
    const result = await submitTx(
      daoEntity.deposit({
        destination: SourceType.LIQUID_POOL,
        token: props.baseCurrency.address,
        amount: '0',
      })
    );

    if (result === TxStatus.COMPLETED) {
      reloadIsCurrentEoaAMember();
    }
  }, [adamEntity, props.baseCurrency.address, props.daoAddress, submitTx, reloadIsCurrentEoaAMember]);

  const handleStatusModalConfirm = (): void => {
    if (txStatus !== TxStatus.COMPLETED) {
      return;
    }

    navigate(
      generatePath(PATHS.DAO.SET_USER_ALIAS, {
        daoAddress: props.daoAddress,
      }),
      {
        replace: true,
      }
    );
    closeJoinDaoModal();
  };

  if (!!unfulfilledAdmissionToken) {
    return (
      <SubmitButton
        onClick={navigateToEtherscan}
      >{`VIEW ${unfulfilledAdmissionToken.token?.symbol} tokens`}</SubmitButton>
    );
  }

  if (minDepositAmount?.isRequired) {
    return null;
  }

  if (!isConnected) {
    return <SubmitButton onClick={openConnectModal}>CONNECT WALLET TO JOIN</SubmitButton>;
  }

  return (
    <>
      <SubmitButton onClick={joinWithoutDeposit} disabled={isOverMembersCountLimit}>
        {isOverMembersCountLimit ? 'VAULT IS FULL' : 'Join'}
      </SubmitButton>
      <AgreementText>
        By clicking join, you acknowledge you have read this{' '}
        <ExternalLink href={externalUrl.TNC_JOIN_VAULT}>Terms and Conditions</ExternalLink>.
      </AgreementText>
      <TxStatusModal
        txStatus={txStatus}
        confirmBtnText={txStatus === TxStatus.COMPLETED ? 'Go To Dashboard' : 'Close'}
        onConfirm={handleStatusModalConfirm}
        errorMessage={errorMessage}
      />
    </>
  );
}
