import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import SettingsImg from 'assets/images/dashboard_setting.png';
import Button from 'components/Button';
import Rect from 'components/layout/Rect';
import Text from 'components/Text';
import { GlobalModal } from 'constants/globalModal';
import PATHS from 'constants/paths';
import useGlobalModal from 'hooks/useGlobalModal';

const Container = styled(Rect)`
  margin: 18px 12px;
  background: ${({ theme: { color } }): string => color.panel};
  display: flex;
  align-items: center;
`;

const PyramidImage = styled.img`
  width: 100%;
  margin: -20px 0 -72px;
  object-fit: cover;
`;

const SubHeadline = styled(Text)`
  text-align: center;
  margin: 12px 0;
`;

const CreateVaultButton = styled(Button)`
  padding: 8px 48px;
`;

const JoinButton = styled(Button)`
  color: ${({ theme: { color } }): string => color.secondaryText};
`;

export default function WelcomeCard(): JSX.Element {
  const { openModal: openJoinDaoModal } = useGlobalModal(GlobalModal.JOIN_DAO);
  const navigate = useNavigate();

  const navigateToCreateDaoPage = (): void => navigate(PATHS.DAO.CREATE_DAO);

  return (
    <Container>
      <PyramidImage src={SettingsImg} />
      <Text category="headline-1" weight="bold">
        Welcome!
      </Text>
      <SubHeadline category="sub-headline">
        Experience a safe,
        <br />
        decentralized token vault.
      </SubHeadline>
      <CreateVaultButton variant="secondary" onClick={navigateToCreateDaoPage}>
        <Text category="body" weight="bold">
          Create Vault
        </Text>
      </CreateVaultButton>
      <JoinButton variant="text" onClick={openJoinDaoModal}>
        <SubHeadline category="sub-headline">or join a Vault</SubHeadline>
      </JoinButton>
    </Container>
  );
}
