import { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import styled, { css } from 'styled-components';
import Loading from 'components/Loading';
import { DaoPanelViewState } from 'constants/daoParam';
import { DAO_SIDEBAR_WIDTH, COLLAPSE_DAO_SIDEBAR_WIDTH } from 'constants/dimension';
import useCurrentDao from 'hooks/useCurrentDao';
import useWeb3 from 'hooks/useWeb3';
// import ConnectWalletCard from './ConnectWalletCard';
import DepositSection from './DepositSection';
import InfoSection from './InfoSection';
import JoinDaoNotice from './JoinDaoNotice';
import NavigationMenu from './NavigationMenu';
import SidebarFooter from './SidebarFooter';
import SwitchDaoPanel from './SwitchDaoPanel';
import WelcomeCard from './WelcomeCard';

const PADDING = '24px';

const SUMMARY_AND_MENU_PAGE = 'summaryAndMenu';
const SWITCH_DAO_PAGE = 'switchDao';
type PageType = typeof SUMMARY_AND_MENU_PAGE | typeof SWITCH_DAO_PAGE;

enum DaoPanelState {
  HIDDEN,
  COLLAPSE,
  NOT_CONNECTED_INITIAL,
  NOT_CONNECTED_DAO,
  CONNECTED_DAO_LIST,
  CONNECTED_DAO,
}

const Container = styled.div<CollapsedMenuProps>`
  background-color: ${({ theme: { color } }): string => color.daoPanelBackground};
  height: 100%;
  overflow: hidden;
  position: absolute;
  width: ${({ isCollapsed }) => (isCollapsed ? `${COLLAPSE_DAO_SIDEBAR_WIDTH}px` : `${DAO_SIDEBAR_WIDTH}px`)};
  transition: width 0.5s ease-in;
`;

const OuterPageControl = styled.div<CollapsedMenuProps>`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: ${({ isCollapsed }) => (isCollapsed ? `${COLLAPSE_DAO_SIDEBAR_WIDTH}px` : `${DAO_SIDEBAR_WIDTH}px`)};
  transition: width 0.5s ease-in;
`;

const InnerPageControl = styled.div<{ page: PageType }>`
  display: flex;
  height: 100%;
  transition: transform 0.2s;
  transform: translateX(${({ page }): string => (page === SWITCH_DAO_PAGE ? '-100%' : '0%')});
`;

const DaoSummaryAndMenuWrapper = styled.div<CollapsedMenuProps>`
  padding: ${PADDING};
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;
  overflow-y: auto;
  overflow-x: hidden;
  ${({ isCollapsed }) =>
    isCollapsed &&
    css`
      align-items: center;
    `}
`;

const SwitchDaoPanelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;
`;

const FooterWrapper = styled.div`
  padding: ${PADDING};
`;

const NotConnectedSection = styled.div`
  height: 100%;
  width: ${DAO_SIDEBAR_WIDTH}px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export interface CollapsedMenuProps {
  isCollapsed?: boolean;
}

const CardSection = styled.div``;

interface DaoSummaryMenuProps extends CollapsedMenuProps {
  daoAddress: string;
  onSwitchDaoClick: () => void;
}

interface SwitchDaoPanelMenuProps {
  eoaAddress: string;
  daoAddress: string;
  onDaoSelect: () => void;
  onBack: () => void;
}

interface MenuProps extends DaoSummaryMenuProps, SwitchDaoPanelMenuProps {
  page: PageType;
  state: DaoPanelState;
}

function ConnectWalletMenu(): JSX.Element {
  return (
    <NotConnectedSection>
      <CardSection>
        {/* NOTE: Request to keep the code by Business /*}
        {/* <ConnectWalletCard /> */}
        <WelcomeCard />
      </CardSection>
      <FooterWrapper>
        <SidebarFooter />
      </FooterWrapper>
    </NotConnectedSection>
  );
}

function DaoSummaryMenu(props: DaoSummaryMenuProps): JSX.Element {
  const { daoAddress, isCollapsed, onSwitchDaoClick } = props;
  return (
    <DaoSummaryAndMenuWrapper isCollapsed={isCollapsed}>
      <InfoSection isCollapsed={isCollapsed} onSwitchDaoClick={onSwitchDaoClick} />
      <DepositSection isCollapsed={isCollapsed} />
      <NavigationMenu isCollapsed={isCollapsed} />
      <JoinDaoNotice isCollapsed={isCollapsed} daoAddress={daoAddress} />
      <SidebarFooter isCollapsed={isCollapsed} />
    </DaoSummaryAndMenuWrapper>
  );
}

function SwitchDaoPanelMenu(props: SwitchDaoPanelMenuProps): JSX.Element {
  const { eoaAddress, daoAddress, onDaoSelect, onBack } = props;
  return (
    <SwitchDaoPanelWrapper>
      <SwitchDaoPanel
        eoaAddress={eoaAddress}
        currentDaoAddress={daoAddress}
        onDaoSelect={onDaoSelect}
        onBack={onBack}
      />
      <FooterWrapper>
        <SidebarFooter />
      </FooterWrapper>
    </SwitchDaoPanelWrapper>
  );
}

function Menu(props: MenuProps): JSX.Element | null {
  const { state, page, daoAddress, eoaAddress, onSwitchDaoClick, onDaoSelect, onBack } = props;
  switch (state) {
    case DaoPanelState.NOT_CONNECTED_INITIAL:
      return <ConnectWalletMenu />;
    case DaoPanelState.NOT_CONNECTED_DAO:
    case DaoPanelState.CONNECTED_DAO:
    case DaoPanelState.CONNECTED_DAO_LIST:
    case DaoPanelState.COLLAPSE:
      const isCollapsed = state === DaoPanelState.COLLAPSE;
      return (
        <OuterPageControl isCollapsed={isCollapsed}>
          <InnerPageControl page={page}>
            <DaoSummaryMenu daoAddress={daoAddress} isCollapsed={isCollapsed} onSwitchDaoClick={onSwitchDaoClick} />
            {!!eoaAddress && (
              <SwitchDaoPanelMenu
                eoaAddress={eoaAddress}
                daoAddress={daoAddress}
                onDaoSelect={onDaoSelect}
                onBack={onBack}
              />
            )}
          </InnerPageControl>
        </OuterPageControl>
      );
    case DaoPanelState.HIDDEN:
      return null;
  }
}

export default function DaoPanel(): JSX.Element {
  const { isReady, isConnected, address } = useWeb3();
  const { daoUrlAddress: daoAddress, daoPanelViewState, setDaoPanelView } = useCurrentDao();
  const ref = useRef<HTMLDivElement>(null);
  const [page, setPage] = useState<PageType>(SUMMARY_AND_MENU_PAGE);

  const goToSwitchDaoMenu = useCallback((): void => {
    if (isConnected) {
      setPage(SWITCH_DAO_PAGE);
    }
  }, [isConnected]);

  const goToSummaryAndMenu = (): void => {
    setPage(SUMMARY_AND_MENU_PAGE);
  };

  const onClickDaoPanel = useCallback(() => {
    if (daoPanelViewState !== DaoPanelViewState.COLLAPSE) {
      return;
    }
    setDaoPanelView(DaoPanelViewState.INITIAL);
  }, [daoPanelViewState, setDaoPanelView]);

  useEffect(() => {
    if (!isConnected) {
      return;
    }

    switch (daoPanelViewState) {
      case DaoPanelViewState.HIDDEN:
      case DaoPanelViewState.COLLAPSE:
        return;
      case DaoPanelViewState.LIST:
        goToSwitchDaoMenu();
        return;
      case DaoPanelViewState.INITIAL:
        goToSummaryAndMenu();
    }
  }, [daoAddress, daoPanelViewState, goToSwitchDaoMenu, isConnected]);

  const state = useMemo((): DaoPanelState => {
    switch (daoPanelViewState) {
      case DaoPanelViewState.HIDDEN:
        return DaoPanelState.HIDDEN;
      case DaoPanelViewState.LIST:
        if (isConnected) {
          return DaoPanelState.CONNECTED_DAO_LIST;
        }
        return DaoPanelState.NOT_CONNECTED_INITIAL;
      case DaoPanelViewState.COLLAPSE:
        if (isConnected) {
          return DaoPanelState.COLLAPSE;
        }
        return DaoPanelState.NOT_CONNECTED_INITIAL;
      case DaoPanelViewState.INITIAL:
        if (isConnected) {
          return DaoPanelState.CONNECTED_DAO;
        }
        if (daoAddress) {
          return DaoPanelState.NOT_CONNECTED_DAO;
        }
        return DaoPanelState.NOT_CONNECTED_INITIAL;
    }
  }, [daoPanelViewState, isConnected, daoAddress]);

  const isCollapsed = state === DaoPanelState.COLLAPSE;

  const onSwitchDaoClick = useCallback(() => {
    setDaoPanelView(DaoPanelViewState.LIST);
  }, [setDaoPanelView]);

  const onDaoSelect = useCallback(() => {
    setDaoPanelView(DaoPanelViewState.INITIAL);
  }, [setDaoPanelView]);

  return (
    <Container ref={ref} isCollapsed={isCollapsed} onClick={onClickDaoPanel}>
      {!isReady ? (
        <Loading isCenter />
      ) : (
        <Menu
          state={state}
          page={page}
          eoaAddress={address ?? ''}
          daoAddress={daoAddress}
          onSwitchDaoClick={onSwitchDaoClick}
          onDaoSelect={onDaoSelect}
          onBack={onDaoSelect}
        />
      )}
    </Container>
  );
}
