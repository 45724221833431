import styled from 'styled-components';
import { space, flexbox, layout, SpaceProps, FlexboxProps, LayoutProps } from 'styled-system';

export type Props = SpaceProps & FlexboxProps & LayoutProps;

const Form = styled.form<Props>`
  ${space}
  ${flexbox}
  ${layout}
`;

export default Form;
