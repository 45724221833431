import { TokenData } from '@adam-vault/adam-sdk';
import styled from 'styled-components';
import CollapsibleDescription from 'components/CollapsibleDescription';
import { EntryFulfillments } from 'types/EntryRequirements';
import JoinDaoFooter from './JoinDaoFooter';
import JoinDaoHeader from './JoinDaoHeader';
import JoinInfoRects from './JoinInfoRects';
import JoinRequirements from './JoinRequirements';

export interface Props {
  daoAddress: string;
  fulfillments: EntryFulfillments;
  hasEnoughDepositAmount?: boolean;
  name: string;
  description: string;
  baseCurrencyData?: TokenData;
  membersCount: string;
  createdOn: string;
  isOverMembersCountLimit: boolean;
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 52px;
  background-color: ${({ theme: { color } }) => color.textField};
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: auto;
`;

const StyledDescription = styled(CollapsibleDescription)`
  margin-bottom: 24px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
`;

export default function JoinDaoFormSection(props: Props): JSX.Element | null {
  const {
    fulfillments,
    daoAddress,
    hasEnoughDepositAmount,
    createdOn,
    membersCount,
    name,
    description,
    baseCurrencyData,
    isOverMembersCountLimit,
  } = props;

  if (!baseCurrencyData) {
    return null;
  }

  return (
    <Container>
      <Content>
        <JoinDaoHeader daoAddress={daoAddress} name={name} />
        <StyledDescription>{description}</StyledDescription>
        <JoinInfoRects createdOn={createdOn} membersCount={membersCount} />
        <JoinRequirements fulfillments={fulfillments} hasEnoughDepositAmount={hasEnoughDepositAmount} />
      </Content>
      <JoinDaoFooter
        daoAddress={daoAddress}
        fulfillments={fulfillments}
        baseCurrency={baseCurrencyData}
        isOverMembersCountLimit={isOverMembersCountLimit}
      />
    </Container>
  );
}
