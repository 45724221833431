import { useMemo } from 'react';
import styled from 'styled-components';
import EmptyState from 'components/EmptyState';
import Loading from 'components/Loading';
import ContentRow from './ContentRow';
import HeaderRow from './HeaderRow';
import TableRow from './TableRow';
import { ActionField, DataModel, FieldArray } from './types';

export interface Props {
  fields: FieldArray;
  data: Array<DataModel>;
  actionField?: ActionField;
  onRowClick?: (data: DataModel, index: number) => void;
  padding?: string;
  className?: string;
  isLoading?: boolean;
  emptyStatement?: string;
  renderLeftPanel?: () => React.ReactNode;
  renderLastRow?: () => React.ReactNode;
}

const Container = styled.div`
  background-color: ${({ theme: { color } }): string => color.table};
  border-radius: 8px;
  display: flex;
  flex: 1;
`;

const LeftPanel = styled.div`
  min-width: 200px;
  border-right: 2px solid ${({ theme: { color } }): string => color.field};
`;

const TableBody = styled.div`
  display: flex;
  flex: 1;
  overflow-y: scroll;
`;

const Rows = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Footer = styled.div`
  height: 56px;
  width: 100%;
  border-top: 2px solid ${({ theme: { color } }): string => color.field};
`;

/**
 * @deprecated Please use Table component for new design
 */

export default function RowTable(props: Props): JSX.Element {
  const { isLoading = false, emptyStatement } = props;

  const tableContent = useMemo(() => {
    if (!!emptyStatement) {
      return <EmptyState statement={emptyStatement} />;
    }

    if (isLoading) {
      return <Loading isCenter />;
    }

    return (
      <Rows>
        <HeaderRow fields={props.fields} actionField={props.actionField} padding={props.padding} />
        {props.data.map((x, index) => (
          <ContentRow
            key={`row-table__row-${index}`}
            fields={props.fields}
            actionField={props.actionField}
            onClick={props.onRowClick}
            data={x}
            index={index}
            padding={props.padding}
          />
        ))}
        {props.renderLastRow && <TableRow padding={props.padding}>{props.renderLastRow()}</TableRow>}
      </Rows>
    );
  }, [isLoading, props, emptyStatement]);

  return (
    <Container className={props.className ?? ''}>
      <TableBody>
        {props.renderLeftPanel && <LeftPanel>{props.renderLeftPanel()}</LeftPanel>}
        {tableContent}
      </TableBody>
      {/* For pagination section  */}
      {/* <Footer></Footer> */}
    </Container>
  );
}
