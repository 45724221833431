import styled from 'styled-components';
import AddressDisplay from 'components/AddressDisplay';
import Button from 'components/Button';
import Text from 'components/Text';

interface Props {
  title: string;
  address?: string;
  description: string | JSX.Element;
  renderButtons?: () => JSX.Element;
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 28px;
`;

const TitleDescriptionWrapper = styled.div``;

const TitleBar = styled.div`
  display: flex;
  align-items: center;
  gap: 19px;
  margin-bottom: 10px;
`;

const StyledAddressDisplay = styled(AddressDisplay)`
  padding: 4px 12px;

  /* To prevent from auto expanding to 100% height of a flex parent */
  height: 100%;
  min-height: 0;
`;

export const TopBarButton = styled(Button)`
  padding: 12px 24px;
`;

export default function TopBar({ title, address, description, renderButtons }: Props): JSX.Element {
  return (
    <Wrapper>
      <TitleDescriptionWrapper>
        <TitleBar>
          <Text category="title-1" weight="bold">
            {title}
          </Text>
          {address && <StyledAddressDisplay address={address} isEllipsis isBorderShown />}
        </TitleBar>
        <Text category="body">{description}</Text>
      </TitleDescriptionWrapper>
      {renderButtons && renderButtons()}
    </Wrapper>
  );
}
