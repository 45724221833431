import { Chain } from 'adam-frontend-shared';
import isNil from 'lodash/isNil';
import { useState } from 'react';
import styled, { css } from 'styled-components';

import CopyIcon from 'assets/images/copy_icon.png';
import RedirectIcon from 'assets/images/redirect_icon.png';
import GeneratedAvatar from 'components/GeneratedAvatar';
import AddressText from 'components/layout/AddressText';
import ExternalLink from 'components/layout/ExternalLink';
import Img from 'components/layout/Img';
import StylelessButton from 'components/layout/StylelessButton';
import Loading from 'components/Loading';
import Modal from 'components/Modal';
import useWeb3 from 'hooks/useWeb3';
import browserUtils from 'utils/browserUtils';
import useAddressDisplay from './hooks/useAddressDisplay';

export interface Props {
  address: string;
  isEllipsis?: boolean;
  isAvatarShown?: boolean;
  isRedirectShown?: boolean;
  isBorderShown?: boolean;
  isAliasShown?: boolean;
  copiedMessage?: string;
  className?: string;
}

interface WrapperProps {
  isBorderShown: boolean;
}

const borderCss = css`
  background-color: ${({ theme }): string => theme.color.field};
  border-radius: 6px;
  padding: 6px 10px;
`;

const Wrapper = styled.div<WrapperProps>`
  max-width: 100%;
  width: fit-content;
  display: inline-flex;
  align-items: center;
  ${(props) => (props.isBorderShown ? borderCss : '')}
`;

const Avatar = styled(GeneratedAvatar)`
  margin-right: ${({ theme: { space } }): string => `${space[2]}px`};
`;

const AliasText = styled.span`
  font-size: 14px;
  font-family: Lato;
  margin-right: 1em;
`;

const StyledAddressText = styled(AddressText)`
  margin-right: 14px;
  overflow-x: auto;
  white-space: nowrap;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none;
  }
`;

const CopyButton = styled(StylelessButton)`
  height: 16px;
  margin-right: 14px;
`;

const RedirectLink = styled(ExternalLink)`
  height: 16px;
`;

const IconImg = styled(Img)`
  height: 100%;
`;

export default function AddressDisplay(props: Props): JSX.Element {
  const {
    className,
    address,
    isEllipsis = false,
    isAvatarShown = true,
    isRedirectShown = true,
    isBorderShown = false,
    isAliasShown = true,
    copiedMessage = '',
  } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { alias, addressOrName, isLoading } = useAddressDisplay({ address, isEllipsis, isAliasShown });

  const clickCopyAddress = (): void => {
    browserUtils.copyToClipboard(address);
    setIsModalOpen(true);
  };

  const closeModal = (): void => setIsModalOpen(false);

  const { chainId } = useWeb3();

  return (
    <Wrapper className={className} isBorderShown={isBorderShown}>
      {isAvatarShown && <Avatar variant="pixel" size="20px" seed={address} />}
      {isLoading ? (
        <Loading height={20} isCenter />
      ) : (
        <StyledAddressText>
          {isAliasShown && alias ? <AliasText>{alias}</AliasText> : ''}
          {addressOrName}
        </StyledAddressText>
      )}
      <CopyButton onClick={clickCopyAddress}>
        <IconImg src={CopyIcon} />
      </CopyButton>
      {isRedirectShown && (
        <RedirectLink href={!isNil(chainId) ? `${Chain.CHAIN_INFO[chainId].blockExplorerUrl}address/${address}` : ''}>
          <IconImg src={RedirectIcon} />
        </RedirectLink>
      )}
      <Modal isOpen={isModalOpen} contentLabel="Address Copied" onRequestClose={closeModal} onConfirm={closeModal}>
        {copiedMessage || 'The address is copied to clipboard!'}
      </Modal>
    </Wrapper>
  );
}
