import styled from 'styled-components';
import { space, SpaceProps } from 'styled-system';

export interface Props extends React.HTMLAttributes<HTMLParagraphElement>, SpaceProps {}

const Description = styled.div<SpaceProps>`
  margin: 0;
  font-size: 14px;
  white-space: pre-line;
  color: ${({ theme: { color } }): string => color.fontSecondaryLight};
  ${space}
`;

export default Description;
