import styled from 'styled-components';
import * as yup from 'yup';
import Button from 'components/Button';
import Form from 'components/form/Form';
import TextInput from 'components/form/TextInput';
import Flex from 'components/layout/Flex';
import Modal from 'components/Modal';
import { useAdamMemo } from 'hooks/useAdam';
import useForm from 'hooks/useForm';
import useTokenSettings from 'hooks/useTokenSettings';
import { supportedToken } from 'utils/yup/yupUtils';
import { ImportTokenModalProps } from './types';

interface FormData {
  tokenAddress: string;
}

const StyledForm = styled(Form)`
  width: 350px;
`;

export default function ImportErc20Modal(props: ImportTokenModalProps): JSX.Element {
  const [dao] = useAdamMemo(
    async (adam) => {
      const daoEntity = adam.loadDao(props.daoAddress);
      return daoEntity;
    },
    [props.daoAddress],
    undefined
  );
  const { supportedTokens } = useTokenSettings();

  const schema = yup.object({
    tokenAddress: supportedToken({ supportedTokens, not: false }).required(),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    schema,
  });

  const onImport = async (data: FormData): Promise<void> => {
    await dao?.upsertTreasuryAssets([data.tokenAddress]);
    reset();
    if (props.onImportComplete) {
      props.onImportComplete();
    }
  };

  const handleImportModalClose = (): void => {
    reset();
    props.handleImportModalClose();
  };

  return (
    <Modal {...props} contentLabel="Import token" onRequestClose={handleImportModalClose}>
      <StyledForm flexDirection="column" onSubmit={handleSubmit(onImport)}>
        <TextInput
          control={control}
          name="tokenAddress"
          placeholder="ERC-20 token address"
          error={errors.tokenAddress?.message}
        />
        <Flex justifyContent="center" marginTop={4}>
          <Button type="submit" variant="primary">
            IMPORT
          </Button>
        </Flex>
      </StyledForm>
    </Modal>
  );
}
