import styled from 'styled-components';
import Description from 'components/layout/Description';

const AddressText = styled(Description)`
  font-family: ${({ theme: { fontFamily } }): string => fontFamily.address};
  font-size: 14px;
  color: ${({ theme: { color } }): string => color.fontPrimary};
  word-break: break-word;
`;

export default AddressText;
