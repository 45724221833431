import { useAdamMemo } from './useAdam';
import useWeb3 from './useWeb3';

type ReturnType = {
  canByPassVoting?: boolean;
  loading: boolean;
  error?: Error | null;
};

export default function useCanByPassVoting(daoAddress?: string): ReturnType {
  const { address } = useWeb3();
  const [canByPassGovern, { isLoading, error }] = useAdamMemo(
    async (adam) => {
      if (!daoAddress || !address) {
        return;
      }

      const daoEntity = adam.loadDao(daoAddress);
      return daoEntity.canByPassGovern(address);
    },
    [daoAddress, address],
    undefined
  );

  if (isLoading) {
    return {
      canByPassVoting: undefined,
      loading: isLoading,
      error,
    };
  }

  return {
    canByPassVoting: canByPassGovern,
    loading: isLoading,
    error,
  };
}
