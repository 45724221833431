import { Chain } from 'adam-frontend-shared';
import { useAtom } from 'jotai';
import { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import Button from 'components/Button';
import DropdownMenu from 'components/DropdownMenu';
import Icon from 'components/Icon';
import WalletButton from 'components/WalletButton';
import { useSessionPreferences } from 'hooks/useSessionPreferences';
import useWeb3 from 'hooks/useWeb3';
import connectModalAtom from 'store/connectModalAtom';
import defaultTheme from 'theme/default';

import EoaInfoModal from './EoaInfoModal';

const Container = styled.div`
  display: flex;
`;

const StyledButton = styled(Button)`
  margin: 0;
`;

const StyledIcon = styled(Icon)`
  width: 12px;
  height: 12px;
`;

const ChainName = styled(StyledButton)`
  min-width: auto;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme: { color } }) => color.dropdownBackground};
`;

const NetworkStatusIcon = styled.span`
  font-size: 0.8em;
  color: ${({ color }) => color};
`;

const rightSideCss = `
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
`;

const ConnectWalletButton = styled(StyledButton)`
  ${rightSideCss}
  background: linear-gradient(90deg, #2FC3FC 0%, #7E3AD9 101.46%);
`;

const EOAButton = styled(WalletButton)`
  ${rightSideCss}
  background-color: ${({ theme: { color } }) => color.black1};
`;

export default function ConnectButton(): JSX.Element {
  const { isConnected, isConnecting, address, walletType } = useWeb3();
  const { platformSelectedChainId } = useSessionPreferences();
  const { color } = useTheme() as typeof defaultTheme;
  const [, setConnectModal] = useAtom(connectModalAtom);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onConnectButtonClick = (): void => {
    setConnectModal({ isModalOpen: true });
  };

  const showEoaInfoModal = (): void => {
    setIsModalOpen(true);
  };

  const closeEoaInfoModal = (): void => {
    setIsModalOpen(false);
  };

  const platformChain = Chain.CHAIN_INFO[platformSelectedChainId];

  const chainOptions = Chain.SUPPORTED_CHAINS.map((chain) => ({
    key: `${chain.chainId}`,
    content: chain.name,
    onSelect: () => {
      if (chain.chainId !== platformSelectedChainId) {
        window.location.assign(chain.appUrl || '');
      }
    },
  }));

  return (
    <>
      <Container>
        <DropdownMenu
          renderCustomButtonContent={() => (
            <ChainName>
              <NetworkStatusIcon
                color={platformChain.isTestnet ? color.networkButtonCircleTestnet : color.networkButtonCircleMainnet}
              >
                {'\u2B24'}
              </NetworkStatusIcon>
              {platformChain.name}
              <StyledIcon icon="dropdown" color={color.dropdownArrow} />
            </ChainName>
          )}
          items={chainOptions}
        />
        {isConnected && walletType ? (
          <EOAButton address={address} walletType={walletType} onClick={showEoaInfoModal} />
        ) : (
          <ConnectWalletButton
            className="ga-button__connect-wallet"
            onClick={onConnectButtonClick}
            disabled={isConnecting}
          >
            {isConnecting ? 'Connecting...' : 'Connect Wallet'}
          </ConnectWalletButton>
        )}
      </Container>
      <EoaInfoModal isOpen={isModalOpen} onRequestClose={closeEoaInfoModal} />
    </>
  );
}
