import { Adam, Env } from '@adam-vault/adam-sdk';
import { useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';
import FaucetSvg from 'assets/images/faucet.svg';
import CreateDaoImg from 'assets/images/home-create.png';
import DemoDaoImg from 'assets/images/home-demo.png';
import DocImg from 'assets/images/home-doc.png';
import JoinDaoImg from 'assets/images/home-join.png';
import RewardSvg from 'assets/images/reward.svg';
import ExternalLink from 'components/layout/ExternalLink';
import Rect, { rectCss } from 'components/layout/Rect';
import Loading from 'components/Loading';
import { DaoPanelViewState } from 'constants/daoParam';
import externalUrl from 'constants/externalUrl';
import { GlobalModal } from 'constants/globalModal';
import PATHS from 'constants/paths';
import { getAdamSupportedFeatures } from 'constants/supportedFeature';
import { useAdamMemo } from 'hooks/useAdam';
import useCurrentDao from 'hooks/useCurrentDao';
import useGlobalModal from 'hooks/useGlobalModal';

const cardCss = css`
  position: relative;
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 10%;
  cursor: pointer;
`;

const CardBackgroundImage = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin: -25px;

  pointer-events: none;
`;

const CardImage = styled.img`
  margin: auto;
`;

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Grid = styled.div<{ isCreateDaoReferralSupported: boolean }>`
  width: 950px;

  display: grid;
  gap: 22px;
  grid-template-columns: repeat(3, 1fr);

  ${({ isCreateDaoReferralSupported }) =>
    isCreateDaoReferralSupported
      ? css`
          height: 550px;
          /* Refer to the design and calculated the template row heights */
          grid-template-rows: 228px 121px 157px;
          grid-template-areas:
            'create-dao join-dao whitepaper'
            'create-dao join-dao doc'
            'reward reward doc';
        `
      : css`
          height: 480px;
          grid-template-rows: repeat(2, 1fr);
          grid-template-areas:
            'create-dao join-dao whitepaper'
            'create-dao join-dao doc';
        `}

  a:link,
  a:visited {
    text-decoration: inherit;
    color: inherit;
  }
`;

const CreateDAOCard = styled.button`
  ${rectCss}
  ${cardCss}
  grid-area: create-dao;
  background: ${({ theme: { color } }): string => color.dashboardTokenTransferSectionGradient};
`;

const JoinDAOCard = styled(Rect)`
  ${cardCss}
  grid-area: join-dao;
  background: ${({ theme: { color } }): string => color.dashboardSettingsSectionGradient};
`;

const RewardCard = styled(Rect)`
  ${cardCss}
  width: 100%;
  padding: 16px;
  grid-area: reward;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
  background: ${({ theme: { color } }): string => color.dashboardRewardSectionGradient};
`;

const RewardCardContent = styled.div`
  flex: 1;
`;

const RewardCardImageContainer = styled(RewardCardContent)`
  height: 100%;
`;

const RewardCardImage = styled(CardImage)`
  width: 100%;
  height: 100%;
`;

const FaucetCard = styled(Rect)`
  ${cardCss}
  grid-area: whitepaper;
  background: ${({ theme: { color } }): string => color.dashboardSwapSectionGradient};
`;

const WhitepaperCard = styled(ExternalLink)`
  ${rectCss}
  ${cardCss}
  grid-area: whitepaper;
  background: ${({ theme: { color } }): string => color.dashboardSwapSectionGradient};
`;

const DocCard = styled(ExternalLink)`
  ${rectCss}
  ${cardCss}
  grid-area: doc;
  background: ${({ theme: { color } }): string => color.dashboardDepositSectionGradient};
`;

const CardTitle = styled.div`
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 12px;
`;

const CardSubtitle = styled.div`
  font-size: 16px;
  text-align: left;
`;

const Home = (): JSX.Element => {
  const { openModal: openJoinDaoModal } = useGlobalModal(GlobalModal.JOIN_DAO);
  const { daoPanelViewState, setDaoPanelView, clear } = useCurrentDao();
  const [adamSupportedFeature, { isLoading: isFetchingAdamSupportedFeature }] = useAdamMemo(
    async (adam: Adam) => getAdamSupportedFeatures(adam),
    [],
    undefined
  );
  const navigate = useNavigate();

  const isShowFaucet = process.env.REACT_APP_ENV !== Env.PROD;
  const isCreateDaoReferralSupported = adamSupportedFeature?.CREATE_DAO_REFERRAL || false;

  useEffect(() => {
    clear();

    if (daoPanelViewState === DaoPanelViewState.COLLAPSE) {
      return;
    }
    setDaoPanelView(DaoPanelViewState.LIST);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickFaucetCard = useCallback(() => {
    navigate(PATHS.FAUCET);
  }, [navigate]);

  const onClickCreateDao = useCallback(() => {
    navigate(PATHS.DAO.CREATE_DAO);
  }, [navigate]);

  const onClickRewardCard = useCallback(() => {
    navigate(PATHS.REWARD);
  }, [navigate]);

  if (isFetchingAdamSupportedFeature) {
    return <Loading isCenter />;
  }

  return (
    <Container>
      <Grid isCreateDaoReferralSupported={isCreateDaoReferralSupported}>
        <CreateDAOCard className="ga-button__create-vault-on-home-page" onClick={onClickCreateDao}>
          <CardBackgroundImage src={CreateDaoImg} />
          <CardTitle>Create</CardTitle>
          <CardSubtitle>Create a Vault, set the rules and get live in 3 minutes.</CardSubtitle>
        </CreateDAOCard>
        <JoinDAOCard onClick={openJoinDaoModal}>
          <CardBackgroundImage src={JoinDaoImg} />
          <CardTitle>Join</CardTitle>
          <CardSubtitle>Join a Vault and embark on your Web3 journey in seconds.</CardSubtitle>
        </JoinDAOCard>
        {isCreateDaoReferralSupported && (
          <RewardCard onClick={onClickRewardCard}>
            <RewardCardImageContainer>
              <RewardCardImage src={RewardSvg} />
            </RewardCardImageContainer>
            <RewardCardContent>
              <CardTitle>Earn ADAM tokens</CardTitle>
              <CardSubtitle>Invite your friends now to earn ADAM tokens!</CardSubtitle>
            </RewardCardContent>
          </RewardCard>
        )}
        {isShowFaucet ? (
          <FaucetCard onClick={onClickFaucetCard}>
            <CardImage src={FaucetSvg} />
            <CardTitle>ADAM Faucet</CardTitle>
            <CardSubtitle>Claim your GoerliETH here</CardSubtitle>
          </FaucetCard>
        ) : (
          <WhitepaperCard href={externalUrl.ADAM_WHITEPAPER}>
            <CardImage src={DemoDaoImg} />
            <CardTitle>Whitepaper</CardTitle>
            <CardSubtitle>See what ADAM Vault can do</CardSubtitle>
          </WhitepaperCard>
        )}
        <DocCard href={externalUrl.ADAM_DOCS}>
          <CardBackgroundImage src={DocImg} />
          <CardTitle>Documentation</CardTitle>
          <CardSubtitle>About ADAM Vault</CardSubtitle>
        </DocCard>
      </Grid>
    </Container>
  );
};

export default Home;
