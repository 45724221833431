import ReactLoading, { LoadingProps } from 'react-loading';
import styled, { useTheme } from 'styled-components';

export interface Props extends LoadingProps {
  isCenter?: boolean;
  isFullWidth?: boolean;
}

const CenteredWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const FullWidthWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const StyledReactLoading = styled(ReactLoading)`
  /* The svg is a square based on width, styles is applied to centered the loading and let parent component to control the wrapper dimensions */
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    position: absolute;
  }
`;

function CustomLoading({ type = 'bubbles', width = 50, height = 50, ...otherProps }: LoadingProps): JSX.Element {
  // TODO update type
  const theme = useTheme() as any;

  return <StyledReactLoading type={type} color={theme.color.loading} height={height} width={width} {...otherProps} />;
}

export default function Loading({ isCenter, isFullWidth, ...loadingProps }: Props): JSX.Element {
  if (isCenter) {
    return (
      <CenteredWrapper>
        <CustomLoading {...loadingProps} />
      </CenteredWrapper>
    );
  }

  if (isFullWidth) {
    return (
      <FullWidthWrapper>
        <CustomLoading {...loadingProps} />
      </FullWidthWrapper>
    );
  }

  return <CustomLoading {...loadingProps} />;
}
