import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { space, SpaceProps } from 'styled-system';

const StylelessLink = styled(Link)<SpaceProps>`
  text-decoration: none;
  color: inherit;

  ${space}
`;

export default StylelessLink;
