export type PresetField = {
  field: string;
  value: any;
  readOnly?: boolean;
};

export type PresetItem = {
  name: string;
  description: string;
  icon?: string;
  type: AllBudgetPresetType;
  hidden?: boolean;
  presetFields: PresetField[];
};

export type BudgetPreset = {
  [key: string]: PresetItem;
};

export enum BudgetPresetType {
  TOKEN_TRANSFER = 'token-transfer',
  VIRTUAL_CARD = 'virtual-card',
  UNISWAP = 'uniswap',
  CUSTOM = 'custom',
}

export enum MemberTokenPresetType {
  AIRDROP = 'airdrop',
}

export enum TreasuryOutflowPresetType {
  TOKEN_TRANSFER = 'token-transfer',
  NFT_TRANSFER = 'nft-transfer',
  AIRDROP = 'airdrop',
  VIRTUAL_CARD = 'virtual-card',
  UNISWAP = 'uniswap',
  CUSTOM = 'custom',
}

export type AllBudgetPresetType = BudgetPresetType | MemberTokenPresetType | TreasuryOutflowPresetType;

export type TreasuryOutflowPreset = BudgetPreset;
export type MemberTokenPreset = BudgetPreset;
