import { SourceType } from '@adam-vault/adam-sdk';
import { useCallback, useMemo, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import Loading from 'components/Loading';
import { AssetSource } from 'constants/asset';
import PATHS from 'constants/paths';
import TabBar from './TabBar';
import TokensTable from './TokensTable';
import TopBar, { TopBarButton } from './TopBar';
import { AssetPrices, Assets } from './types';

export interface Props {
  sourceType?: AssetSource;
  daoAddress: string;
  lpAddress: string;

  // assets related
  isFetchingAssets: boolean;
  assets: Assets;
  assetPrices: AssetPrices | undefined;
  refetchAssets: () => void;
}

enum AssetType {
  TOKENS = 'tokens',
}

export default function MyAccountSection({
  sourceType,
  daoAddress,
  lpAddress,
  isFetchingAssets,
  assets,
  assetPrices,
}: Props): JSX.Element {
  const navigate = useNavigate();
  const [currentAssetType, setCurrentAssetType] = useState(AssetType.TOKENS);
  const data = useMemo(() => {
    if (!assets?.erc20) {
      return [];
    }

    return assets?.erc20.map((tokenInfo) => {
      const price = assetPrices?.erc20?.[tokenInfo.address];

      return {
        ...tokenInfo,
        priceBN: price === null ? null : price?.answer,
        formattedPrice: price === null ? null : price?.answerStr,
      };
    });
  }, [assets?.erc20, assetPrices?.erc20]);

  const assetTypeTabs = useMemo(
    () => [
      {
        id: AssetType.TOKENS,
        label: 'Tokens',
      },
    ],
    []
  );

  const redirectToDepositPage = useCallback((): void => {
    navigate(generatePath(PATHS.DAO.DEPOSIT_SPECIFIC, { daoAddress, to: SourceType.LIQUID_POOL }));
  }, [daoAddress, navigate]);

  const renderTopBarButtons = useCallback(
    () => (
      <TopBarButton icon="deposit_activity" onClick={redirectToDepositPage}>
        DEPOSIT
      </TopBarButton>
    ),
    [redirectToDepositPage]
  );

  const handleAssetTypeChange = useCallback((_, tabId) => setCurrentAssetType(tabId), [setCurrentAssetType]);

  const navigateToTransactionLogsPage = (): void => {
    navigate(generatePath(PATHS.DAO.TRANSACTION_LOGS, { daoAddress, source: sourceType }));
  };

  if (isFetchingAssets) {
    return <Loading isCenter />;
  }

  return (
    <>
      <TopBar
        title="My Assets"
        description="Segregated account owned solely by you. Assets here can be managed by Vault budgets."
        address={lpAddress}
        renderButtons={renderTopBarButtons}
      />
      <TabBar
        tabs={assetTypeTabs}
        focusedTabId={currentAssetType}
        onTabChange={handleAssetTypeChange}
        onTransactionsButtonClick={navigateToTransactionLogsPage}
      />

      {currentAssetType === AssetType.TOKENS && (
        <TokensTable
          data={data}
          totalPriceBN={assetPrices?.erc20TotalPriceBN}
          daoAddress={daoAddress}
          emptyStateDescription="Start depositing tokens to your account now!"
          onDepositButtonClick={redirectToDepositPage}
        />
      )}
    </>
  );
}
