import { Chain } from 'adam-frontend-shared';
import { GraphQLClient } from 'graphql-hooks';
import { useMemo } from 'react';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { useSessionPreferences } from 'hooks/useSessionPreferences';

export default function useSubgraphClient(): GraphQLClient {
  const { platformSelectedChainId } = useSessionPreferences();
  const baseUrl = platformSelectedChainId ? Chain.CHAIN_INFO[platformSelectedChainId].envParam.subgraphBaseUrl : '';
  const httpsUrl = `https://${baseUrl}`;
  const wsUrl = `wss://${baseUrl}`;

  // Setup GraphQL client for Subgraph
  const client = useMemo(
    () =>
      new GraphQLClient({
        url: httpsUrl,
        subscriptionClient: () =>
          new SubscriptionClient(wsUrl, {
            /* additional config options here if any */
          }),
      }),
    [httpsUrl, wsUrl]
  );

  return client;
}
