import { useAtom } from 'jotai';
import styled from 'styled-components';
import JoinDaoAndDepositFormSection from 'components/JoinDaoAndDepositFormSection';
import Modal from 'components/Modal';
import { GlobalModal } from 'constants/globalModal';
import useGlobalModal from 'hooks/useGlobalModal';
import { daoUrlAddressAtom } from 'store/daoAtom';

const Content = styled.div`
  display: flex;
  min-height: 660px;
  max-height: 740px;
  width: fit-content;
  min-width: 526px;
  max-width: 1052px;
  flex-direction: column;
`;

export default function JoinDaoAndDepositModal(): JSX.Element {
  const [daoAddress] = useAtom(daoUrlAddressAtom);
  const { isModalOpen, closeModal } = useGlobalModal(GlobalModal.JOIN_DAO_AND_DEPOSIT);

  return (
    <Modal displayMode="absolute" isOpen={isModalOpen} onRequestClose={closeModal}>
      <Content>
        <JoinDaoAndDepositFormSection daoAddress={daoAddress} />
      </Content>
    </Modal>
  );
}
