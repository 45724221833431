import { TokenInfoResponse } from '@adam-vault/adam-sdk';
import { BigNumber } from 'ethers';
import isNil from 'lodash/isNil';
import * as yup from 'yup';
import { BooleanSchema, NumberSchema } from 'yup';
import { MixedSchema } from 'yup/lib/mixed';
import { fromStringWithComma } from 'utils/thousandSeparatorUtils';
import { ethereumAddress } from './address';
import { isNumber } from '../numberUtils';
import { isSupportedToken } from '../tokenUtils';
import web3Utils from '../web3Utils';

type AmountParams = { min?: number; max?: number; message?: string; allowZero?: boolean; allowEmpty?: boolean };

type TokenAmountParams = AmountParams & {
  supportedTokens?: TokenInfoResponse[] | undefined;
  supportedTokenAddress?: string;
  decimals?: number;
};

type EnoughTokenParams = {
  supportedTokens: TokenInfoResponse[] | undefined;
  tokenAddress?: string;
  min: number;
  tokenBalance: number | string;
  message: string;
};

type OneOfValuesParams = {
  message?: string;
  values: string[];
};

const isNilOrEmpty = (value?: string): boolean => [undefined, null, ''].indexOf(value) !== -1;

export const amount = ({ min, max, message, allowZero }: AmountParams = {}): MixedSchema => {
  let validation = yup
    .string()
    .test('type', 'It must be a number', (value) => isNilOrEmpty(value) || isNumber(fromStringWithComma(value)))
    .test(
      'number',
      'It must be a positive number',
      (value) => isNilOrEmpty(value) || /^\d*\.?\d*$/.test(fromStringWithComma(value))
    );

  if (!isNil(min)) {
    validation = validation.test(
      'minAmount',
      message || `It must be at least ${min}.`,
      (value) => isNumber(value) && parseFloat(value || '0') >= min
    );
  }

  if (!isNil(max)) {
    validation = validation.test(
      'maxAmount',
      message || `It must be at most ${max}.`,
      (value) => isNumber(value) && parseFloat(value || '0') <= max
    );
  }

  if (!allowZero) {
    validation = validation.test(
      'minAmount',
      message || 'It must be greater than 0',
      (value) => isNilOrEmpty(value) || (isNumber(value) && parseFloat(fromStringWithComma(value)) > 0)
    );
  }

  return validation;
};

export const positiveInteger = (amountParams: AmountParams = {}): MixedSchema =>
  amount({ ...amountParams }).test(
    'integer',
    'It must be an integer',
    (value) => isNilOrEmpty(value) || /^\d*$/.test(fromStringWithComma(value))
  );

/**
 * Check if the amount is large enough to be represented by the token's decimals
 */
export const tokenAmount = (params: TokenAmountParams): MixedSchema => {
  const { supportedTokens, supportedTokenAddress, decimals: decimalsFromParams } = params;
  const tokenInfo = supportedTokens?.find(
    (token: TokenInfoResponse) => token.address.toLowerCase() === supportedTokenAddress?.toLowerCase()
  );
  const decimals = decimalsFromParams ?? (tokenInfo?.decimals || 18);

  return amount(params).test('minTokenAmount', 'Invalid amount', (value) => {
    if (params.allowEmpty && !value) {
      return true;
    }
    const valueWithoutComma = fromStringWithComma(value);
    if (!valueWithoutComma) {
      return false;
    }
    if (+valueWithoutComma === 0 && params.allowZero) {
      return true;
    }
    const unit = web3Utils.toUnitBN(valueWithoutComma, decimals);
    return !!unit && unit.gte(BigNumber.from(1));
  });
};

export const enoughToken = (params: EnoughTokenParams): MixedSchema => {
  const tokenBalance = Number(params.tokenBalance);
  return tokenAmount({ supportedTokens: params.supportedTokens, supportedTokenAddress: params.tokenAddress })
    .test('insufficientBalance', 'insufficient balance', (value) => Number(value) <= tokenBalance)
    .test('minAmount', params.message, (value) => value >= params.min);
};

export const percentage = (params: { message?: string; min?: number; max?: number } = {}): MixedSchema => {
  const { message, min = 0, max = 100 } = params;
  return yup
    .mixed()
    .test('type', 'Percentage must be a number', (value) => !Number.isNaN(fromStringWithComma(value)))
    .test('number', 'Percentage must be a number', (value) => /^-?\d*\.?\d*$/.test(fromStringWithComma(value)))
    .test(
      'noDuplicateDotAndNoStartingZero',
      'Percentage must be a number',
      // The regex check is the number have more than one dot and have zero at the beginning
      // eg. 1234.213213.1231
      // eg. 00001
      (value) => !/(^0+(?!\.).)|(.*(\..*\.))/.test(fromStringWithComma(value))
    )
    .test(
      'minAmount',
      message || `It must be a percentage from ${min} - ${max}`,
      (value) => isNumber(value) && parseFloat(fromStringWithComma(value)) >= min
    )
    .test(
      'maxAmount',
      message || `It must be a percentage from ${min} - ${max}`,
      (value) => isNumber(value) && parseFloat(fromStringWithComma(value)) <= max
    );
};

export const checked = (message?: string): BooleanSchema => yup.boolean().oneOf([true], message);

export const dateTime = (): NumberSchema =>
  yup.number().typeError('It must be valid date time format, eg. mm/dd/yyyy hh:mm (a|p)m');

export const supportedToken = (
  params: { supportedTokens: TokenInfoResponse[] | undefined; message?: string; not?: boolean } = {
    supportedTokens: undefined,
  }
): MixedSchema => {
  const { supportedTokens, message, not } = params;
  return ethereumAddress().test(
    'isSupportedToken',
    message || (!!not ? 'It must be one of the supported tokens' : 'It must not be one of the supported tokens'),
    (value) => (not ? isSupportedToken(supportedTokens, value || '') : !isSupportedToken(supportedTokens, value || ''))
  );
};

export const oneOfValues = (params: OneOfValuesParams): MixedSchema => {
  const { message, values = [] } = params;
  return yup
    .mixed()
    .test('oneOfValues', message || `It must be one of [${values.join(', ')}]`, (value: string) =>
      values.includes(value)
    );
};
