import { Chain } from 'adam-frontend-shared';
import { generatePath as generatePathFromRouter } from 'react-router-dom';
import PATHS from 'constants/paths';
import { useSessionPreferences } from 'hooks/useSessionPreferences';

type Param = {
  [key: string]: string | undefined;
};

export default function useShareUrl(path: string, param?: Param): string {
  const { platformSelectedChainId } = useSessionPreferences();
  const { network } = Chain.CHAIN_INFO[platformSelectedChainId];
  const pathFromRouter = generatePathFromRouter(path, param);
  const hasQueryInPath = pathFromRouter.includes('?');

  return `${PATHS.BASE_PATH}${pathFromRouter}${hasQueryInPath ? '&' : '?'}network=${network}`;
}
