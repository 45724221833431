import styled from 'styled-components';
import Flex, { Props as FlexProps } from 'components/layout/Flex';

export interface Props extends FlexProps {
  padding?: string;
}

const TableRow = styled(Flex)<Props>`
  height: auto;
  min-height: 50px;
  padding: ${(props) => props.padding ?? '16px 48px'};
`;

export default TableRow;
