import { Profile } from '@adam-vault/adam-sdk';
import { useAdamMemo } from 'hooks/useAdam';

type ReturnType = {
  memberProfiles: Profile[];
  isLoading: boolean;
};

export default function useMemberProfiles(
  daoAddress: string,
  publicAddresses?: string[],
  shouldQuery: boolean = true
): ReturnType {
  const [memberProfiles, { isLoading }] = useAdamMemo(
    async (adam) => {
      if (!shouldQuery) {
        return [];
      }
      const daoEntity = adam.loadDao(daoAddress);
      const profiles = await daoEntity.userProfiles({
        publicAddresses,
      });
      return profiles.data;
    },
    [daoAddress],
    []
  );
  return { memberProfiles, isLoading };
}
