import { useEffect } from 'react';
import { DaoPanelViewState } from 'constants/daoParam';
import useCurrentDao from 'hooks/useCurrentDao';

export default function Error404Page(): JSX.Element {
  const { setDaoPanelView } = useCurrentDao();

  useEffect(() => {
    setDaoPanelView(DaoPanelViewState.LIST);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div />;
}
