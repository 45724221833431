import { toAmountString } from '@adam-vault/adam-sdk';
import { BigNumber } from 'ethers';
import { useAdamMemo } from 'hooks/useAdam';
import { MemberToken } from 'types/MemberToken';

type TotalSupply = {
  valueBN: BigNumber;
  displayString: string;
};

type BalanceInDao = {
  valueBN: BigNumber;
  displayString: string;
};

type ReturnType = {
  hasMemberToken: boolean;
  memberToken?: MemberToken | null;
  totalSupply?: TotalSupply | null;
  balanceInDao?: BalanceInDao | null;
  loading: boolean;
};

export default function useMemberTokenInfo(daoAddress: string | undefined): ReturnType {
  const [{ memberToken, totalSupply, balanceInDao }, { isLoading: loading }] = useAdamMemo(
    async (adam) => {
      if (!daoAddress) {
        return {};
      }

      const mtEntity = await adam.loadDao(daoAddress).loadMemberToken();

      if (!mtEntity) {
        return {};
      }

      const data = await mtEntity.fetch();

      return {
        memberToken: {
          id: mtEntity.address,
          address: mtEntity.address,
          name: data.name,
          decimals: data.decimals,
          symbol: data.symbol,
        },
        totalSupply: {
          valueBN: data.totalSupply,
          displayString: toAmountString(await mtEntity.formatTotalSupply(8), data.symbol),
        },
        balanceInDao: {
          valueBN: await mtEntity.balanceOf(daoAddress),
          displayString: toAmountString(await mtEntity.formatBalanceOf(daoAddress, 8), data.symbol),
        },
      };
    },
    [daoAddress],
    {}
  );

  return { hasMemberToken: !!memberToken, memberToken, totalSupply, balanceInDao, loading };
}
