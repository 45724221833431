import { PriceResult, Adam } from '@adam-vault/adam-sdk';
import { BigNumber } from 'ethers';
import web3Utils from 'utils/web3Utils';
import { useAdamMemo } from './useAdam';

type ReturnType = {
  priceData: PriceResult | null;
  isLoading: boolean;
  error?: Error;
};

export const getTokenPrice = async (adam: Adam, base: string, quote: string, amount: string): Promise<PriceResult> => {
  if (!base || !quote || amount === undefined) {
    return Promise.reject();
  }

  if (base.toLowerCase() === quote.toLowerCase()) {
    const tokenEntity = adam.loadToken(base);
    try {
      const decimals = await tokenEntity.decimals();

      return {
        answerStr: amount,
        answer: web3Utils.toUnitBN(amount, decimals) || BigNumber.from('0'),
        decimals,
      };
    } catch (e) {
      return Promise.reject(e);
    }
  }

  return adam.loadFeedRegistry().getPrice(base, quote, amount);
};

export default function useTokenPrice(base: string, quote: string, amount: string): ReturnType {
  const [priceData, { isLoading }] = useAdamMemo(
    async (adam) => {
      const tokenPrice = await getTokenPrice(adam, base, quote, amount);
      return tokenPrice;
    },
    [base, quote, amount],
    null
  );

  return {
    priceData,
    isLoading,
  };
}
