import { Wallet as WalletConstants } from 'adam-frontend-shared';
import styled from 'styled-components';
import Description from 'components/layout/Description';
import ExternalLink from 'components/layout/ExternalLink';
import { rectCss } from 'components/layout/Rect';
import externalUrl from 'constants/externalUrl';
import WalletButton from './WalletButton';

export interface Props {
  beginnerWallets: Array<WalletConstants.WalletItem>;
  advancedWallets: Array<WalletConstants.WalletItem>;
  onSelect: (walletType: WalletConstants.WalletType) => void;
}

const { Wallet, WalletType } = WalletConstants;

const ModalContent = styled.div`
  width: 472px;
  display: flex;
  flex-direction: column;
`;

const NewToBlockChainDescription = styled(Description)`
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme: { color } }) => color.fontSecondaryLight};
  margin-bottom: 16px;
  line-height: 22px;
`;

const HaveWalletDescription = styled(Description)`
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme: { color } }) => color.fontSecondaryLight};
  margin-bottom: 16px;
  line-height: 22px;
`;

const TncSection = styled.div`
  ${rectCss}
  width: 100%;
  height: 80px;
  padding: 21px 32px;
  color: ${({ theme: { color } }) => color.fontSecondaryLight};
  background-color: transparent;
  align-items: center;
  display: -webkit-inline-box;
  justify-content: space-between;
  font-size: 13px;
  line-height: 19px;
  font-weight: 400;
`;

export default function SelectWallet({ beginnerWallets, advancedWallets, onSelect }: Props): JSX.Element {
  return (
    <ModalContent>
      <NewToBlockChainDescription>
        New to Blockchain? Create a <ExternalLink href={Wallet[WalletType.TORUS].url}>Torus</ExternalLink> wallet now!
      </NewToBlockChainDescription>
      {beginnerWallets.map((wallet) => (
        <WalletButton
          key={`beginner-wallets-button__${wallet.name}`}
          logo={wallet.logo}
          name={wallet.name}
          gaName={wallet.gaName}
          onClick={() => onSelect(wallet.type)}
        />
      ))}
      <HaveWalletDescription>Already got a wallet? Select an option below.</HaveWalletDescription>
      {advancedWallets.map((wallet) => (
        <WalletButton
          key={`all-wallets-button__${wallet.name}`}
          logo={wallet.logo}
          name={wallet.isInstalled ? wallet.name : `Install ${wallet.name}`}
          gaName={wallet.gaName}
          onClick={() => onSelect(wallet.type)}
        />
      ))}
      <TncSection>
        By connecting a wallet, you acknowdelege you have read and accept our{' '}
        <ExternalLink href={externalUrl.TNC_CONNECT_WALLET}>Terms of Service</ExternalLink>.
      </TncSection>
    </ModalContent>
  );
}
