import styled, { useTheme } from 'styled-components';
import Icon from 'components/Icon';
import Description from 'components/layout/Description';
import Rect from 'components/layout/Rect';
import Title from 'components/layout/Title';
import { StyledComponentsTheme } from 'types/StyledComponentsTheme';

export interface Props {
  createdOn: string;
  membersCount: string;
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  column-gap: 14px;
`;

const StyledRect = styled(Rect)`
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 20px;
  padding: 16px 20px;
  background-color: ${({ theme: { color } }) => color.koamaru};
`;

const StyledIcon = styled(Icon)``;

const Content = styled.div`
  flex: 1;
`;

const Label = styled(Description)`
  font-size: 16px;
  line-height: 19px;
  font-weight: 400;
`;

const ValueText = styled(Title)`
  font-size: 20px;
  line-height: 28px;
  margin-top: 4px;
`;

export default function JoinInfoRects(props: Props): JSX.Element {
  const { color } = useTheme() as StyledComponentsTheme;

  return (
    <Container>
      <StyledRect>
        <StyledIcon icon="calendar" size="30px" color={color.fontSecondary} />
        <Content>
          <Label>Created On</Label>
          <ValueText>{props.createdOn}</ValueText>
        </Content>
      </StyledRect>
      <StyledRect>
        <StyledIcon icon="team" size="30px" color={color.fontSecondary} />
        <Content>
          <Label>Members</Label>
          <ValueText>{props.membersCount}</ValueText>
        </Content>
      </StyledRect>
    </Container>
  );
}
