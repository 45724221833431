import { trimTrailingZero, SourceType } from '@adam-vault/adam-sdk';
import { BigNumber } from 'ethers';
import { useAdamMemo } from 'hooks/useAdam';
import useTokenSettings from 'hooks/useTokenSettings';
import { Assets, Erc721Asset, AssetPrices } from '../types';

type ReturnType = {
  assets: Assets;
  assetPrices: AssetPrices | undefined;
  refetch: () => void;
  isFetchingAssets: boolean;
  isFetchingPrices: boolean;
};

export default function useTreasuryAssets(daoAddress: string): ReturnType {
  const { nativeToken, defaultBaseCurrency } = useTokenSettings();

  const [assets, { isLoading: isFetchingAssets, refetch }] = useAdamMemo(
    async (adam) => {
      if (!nativeToken) {
        return { erc20: [], erc721: [] };
      }

      const dao = adam.loadDao(daoAddress);
      const memberToken = await dao.memberToken();

      const depositTokenAssets = await dao.assets(SourceType.TREASURY);
      const importedAssets = await dao.importedAssets();
      const erc20Assets = depositTokenAssets
        .filter(({ address, balance }) => address.toLowerCase() !== nativeToken.address && !balance.isZero())
        .concat(importedAssets.ERC20.filter(({ balance }) => !balance.isZero()));

      const filteredErc20Assets = erc20Assets
        .filter((r) => r.address.toLowerCase() !== memberToken?.toLowerCase())
        .map((r) => ({
          address: r.address,
          balanceBN: r.balance,
          balance: trimTrailingZero(r.balanceStr),
          name: r.name,
          symbol: r.symbol,
        }));

      const erc721Assets: Erc721Asset[] = [];
      importedAssets.ERC721.forEach((erc721) => {
        const erc721Tokens = erc721.tokens.map((token) => ({
          name: token.name,
          image: token.image,
          tokenId: token.tokenId,
          address: erc721.address,
        }));
        // append all the tokens from a particular erc721 contract
        erc721Assets.push(...erc721Tokens);
      });
      return { erc20: filteredErc20Assets, erc721: erc721Assets };
    },
    [daoAddress, nativeToken],
    { erc20: [], erc721: [] }
  );

  const [assetPrices, { isLoading: isFetchingPrices }] = useAdamMemo(
    async (adam) => {
      if (!defaultBaseCurrency) {
        return undefined;
      }
      const erc20Prices = await Promise.all(
        assets.erc20.map((tokenInfo) =>
          adam
            .loadFeedRegistry()
            .getPrice(tokenInfo.address, defaultBaseCurrency.address, tokenInfo.balanceBN)
            .catch(() => null)
        )
      );

      return {
        erc20: assets.erc20.reduce(
          (result, tokenInfo, index) => ({
            ...result,
            [tokenInfo.address]: erc20Prices[index],
          }),
          {}
        ),
        erc20TotalPriceBN: erc20Prices.reduce(
          (result, priceResult) => result.add(priceResult?.answer || BigNumber.from(0)),
          BigNumber.from(0)
        ),

        // To get ERC721 price in the future
        erc721: {},
        erc721TotalPriceBN: undefined,
      };
    },
    [assets],
    undefined
  );

  return {
    assets,
    assetPrices,
    isFetchingAssets,
    isFetchingPrices,
    refetch,
  };
}
