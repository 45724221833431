import { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import Button from 'components/Button';
import Description from 'components/layout/Description';

export interface Props {
  className?: string;
  characterLimit?: number;
  children: string;
}

const CHARACTER_LIMIT = 160;

const ToggleButton = styled(Button)`
  display: inline-block;
  margin: 0;
  padding: 0;
  width: fit-content;
  min-width: 0;
  height: auto;
  color: ${({ theme: { color } }): string => color.fontHighlight};
`;

const Dots = styled.span`
  color: ${({ theme: { color } }): string => color.fontHighlight};
`;

export default function CollapsibleDescription(props: Props): JSX.Element {
  const { characterLimit = CHARACTER_LIMIT } = props;

  const [isCollapsed, setIsCollapsed] = useState(true);

  const isCharacterCountExceed = props.children.length > characterLimit;

  const description = useMemo(() => {
    if (isCharacterCountExceed && isCollapsed) {
      return props.children.slice(0, characterLimit);
    }

    return props.children;
  }, [isCharacterCountExceed, isCollapsed, props.children, characterLimit]);

  const toggleCollapsible = useCallback(() => setIsCollapsed(!isCollapsed), [isCollapsed]);

  return (
    <Description className={props.className}>
      {description}
      {isCharacterCountExceed && (
        <>
          {isCollapsed && <Dots>...</Dots>}{' '}
          <ToggleButton variant="plain" onClick={toggleCollapsible}>
            {isCollapsed ? 'Show more' : 'Show less'}
          </ToggleButton>
        </>
      )}
    </Description>
  );
}
