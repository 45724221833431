import { Wallet as WalletConstants } from 'adam-frontend-shared';
import { useAtom } from 'jotai';
import styled from 'styled-components';
import Button from 'components/Button';
import AddressText from 'components/layout/AddressText';
import Description from 'components/layout/Description';
import Rect from 'components/layout/Rect';
import useWeb3 from 'hooks/useWeb3';
import connectModalAtom from 'store/connectModalAtom';

const { Wallet } = WalletConstants;

const ConnectButton = styled(Button)`
  width: 100%;
  height: 72px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
`;

const Container = styled(Rect)`
  min-height: 72px;
  background-color: ${({ theme: { color } }): string => color.textField};
  padding: 12px 16px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const LogoImg = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 8px;
`;

const Label = styled(Description)`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: ${({ theme: { color } }): string => color.fontPrimary};
  margin-bottom: 4px;
`;

const StyledAddressText = styled(AddressText)`
  color: ${({ theme: { color } }): string => color.fontSecondary};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export default function EoaDisplay(): JSX.Element {
  const { isConnected, walletType, address: eoaAddress } = useWeb3();
  const [, setConnectModal] = useAtom(connectModalAtom);

  const openConnectModal = (): void => {
    setConnectModal({ isModalOpen: true });
  };

  if (!isConnected || !walletType) {
    return (
      <ConnectButton variant="secondary" onClick={openConnectModal}>
        CONNECT WALLET
      </ConnectButton>
    );
  }

  return (
    <Container>
      <Row>
        <LogoImg src={Wallet[walletType].logo} />
        <Label>{Wallet[walletType].name}</Label>
      </Row>
      <StyledAddressText>{eoaAddress}</StyledAddressText>
    </Container>
  );
}
