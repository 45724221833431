import { useAtom } from 'jotai';
import React, { useEffect } from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import { HIGHEST_Z_INDEX, MODAL_Z_INDEX } from 'constants/dimension';
import useIsFirstRender from 'hooks/useIsFirstRender';
import { onOpenModalCountAtom } from 'store/modalAtom';

export interface Props extends ReactModal.Props {
  ContentWrapper: React.FC;
  isHighestLayer?: boolean;
}

const Overlay = styled.div`
  z-index: ${MODAL_Z_INDEX};
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);

  display: flex;
  align-items: center;
  justify-content: center;

  opacity: 0;
  transition: opacity 200ms ease-in-out;

  &.modal__overlay.modal__overlay--highest-layer {
    z-index: ${HIGHEST_Z_INDEX};
  }

  &.ReactModal__Overlay--after-open {
    opacity: 1;
  }

  &.ReactModal__Overlay--before-close {
    opacity: 0;
  }
`;

const ModalWrapper = (props: Props): JSX.Element => {
  const { isOpen, ContentWrapper, children, isHighestLayer, ...otherProps } = props;

  const [, setOnOpenModalCount] = useAtom(onOpenModalCountAtom);
  const isFirstRender = useIsFirstRender();

  useEffect(() => {
    if (isFirstRender) {
      return;
    }
    if (!isOpen) {
      setOnOpenModalCount((prevOnOpenModalCount) => prevOnOpenModalCount - 1);
      return;
    }
    setOnOpenModalCount((prevOnOpenModalCount) => prevOnOpenModalCount + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const renderOverlayElement = (
    overlayProps: React.ComponentPropsWithRef<'div'>,
    contentElement: React.ReactElement
  ): JSX.Element => <Overlay {...overlayProps}>{contentElement}</Overlay>;

  const renderContentElement = (
    contentProps: React.ComponentPropsWithRef<'div'>,
    contentChildren: React.ReactNode
  ): JSX.Element => <ContentWrapper {...contentProps}>{contentChildren}</ContentWrapper>;

  const overlayClassName = isHighestLayer ? 'modal__overlay modal__overlay--highest-layer' : 'modal__overlay';

  return (
    <ReactModal
      isOpen={isOpen}
      ariaHideApp={false}
      className="_"
      overlayClassName={overlayClassName}
      overlayElement={renderOverlayElement}
      contentElement={renderContentElement}
      closeTimeoutMS={200}
      appElement={document.getElementById('app') as HTMLElement}
      shouldCloseOnOverlayClick={false}
      {...otherProps}
    >
      {children}
    </ReactModal>
  );
};

export default ModalWrapper;
