import styled, { css } from 'styled-components';
import Flex, { Props as RectProps } from './Flex';

export interface Props extends RectProps {}

export const rectCss = css`
  background-color: ${({ theme: { color } }) => color.oldDarkGray1};
  border-radius: 10px;
  padding: 16px;
  overflow: hidden;
`;

const Rect = styled(Flex)`
  ${rectCss}
`;

Rect.defaultProps = {
  flexDirection: 'column',
};

export default Rect;
