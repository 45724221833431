import _get from 'lodash/get';
import { useEffect, useRef } from 'react';
import useScrolling from './useScrolling';

export default function useHandlingFormFocus(errors: any) {
  const inputRefs = useRef<any>([]);
  const fieldRefs = useRef<any>({});

  const { scrollToView } = useScrolling();

  const setRef = (el: React.ReactNode, name: string) => {
    if (!fieldRefs.current[name]) {
      fieldRefs.current[name] = name;
      inputRefs.current.push({ name, el });
    }
  };

  const scrollToInput = (name: string) => {
    const input = inputRefs.current.find((x: any) => x.name === name);
    if (input?.el) {
      scrollToView(input.el);
    }
  };

  useEffect(() => {
    if (errors) {
      inputRefs.current.some((x: any) => {
        const error = _get(errors, x.name);
        if (error) {
          scrollToView(x.el);
        }
        return error;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  useEffect(
    () => () => {
      inputRefs.current = [];
      fieldRefs.current = {};
    },
    []
  );

  return { inputRefs, setRef, scrollToInput };
}
