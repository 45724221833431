import styled from 'styled-components';
import ErrorLabel from 'components/layout/ErrorLabel';

import Rect from '../layout/Rect';

export interface Props {
  className?: string;
  error?: string;
  children: React.ReactNode;
}

const Container = styled(Rect)`
  border-radius: 8px;
  background-color: ${({ theme: { color } }): string => color.field};
  padding: 6px 10px;
`;

export default function InputLayout(props: Props): JSX.Element {
  return (
    <Container className={`${props.className} form__input-layout`}>
      {props.children}
      {props.error && <ErrorLabel>{props.error}</ErrorLabel>}
    </Container>
  );
}
