import { BigNumber } from 'ethers';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import EmptyState from 'components/EmptyState';
import Header from 'components/Header';
import Loading from 'components/Loading';
import { AssetSource } from 'constants/asset';
import PATHS from 'constants/paths';
import useCurrentDao from 'hooks/useCurrentDao';
import useRouterQuery from 'hooks/useRouterQuery';
import useTokenSettings from 'hooks/useTokenSettings';
import useWeb3 from 'hooks/useWeb3';
import useLiquidPoolAssets from './hooks/useLiquidPoolAssets';
import useSegregateAccountAssets from './hooks/useSegregateAccountAssets';
import useTreasuryAssets from './hooks/useTreasuryAssets';
import MemberAggregateSection from './MemberAggregateSection';
import MyAccountSection from './MyAccountSection';
import PoolMenuBar, { PoolOption } from './PoolMenuBar';
import PublicAccountSection from './PublicAccountSection';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
`;

const PageContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
`;

const Content = styled.div`
  padding: 0 84px;
  flex: 1;
  overflow-y: auto;
`;

/*
 * For generating pool ID including opt-in pool in the future.
 */
export const generatePoolId = (sourceType: AssetSource): string => {
  switch (sourceType) {
    case AssetSource.TREASURY:
      return 'treasury';
    case AssetSource.LIQUID_POOL:
      return 'liquid-pool';
    case AssetSource.SEGREGATE:
      return 'segregate';
  }
};

export default function DaoAssets(): JSX.Element {
  const navigate = useNavigate();
  const query = useRouterQuery();
  const querySourceId = query.source ?? 'treasury';
  const { address: eoaAddress } = useWeb3();
  const { isLoading, currentDao, daoUrlAddress } = useCurrentDao();
  const { defaultBaseCurrency } = useTokenSettings();
  const [selectedPoolId, setSelectedPoolId] = useState<string>(generatePoolId(AssetSource.TREASURY));

  // Fetch assets for all pools, for caching and calculating total prices
  const {
    assets: treasuryAssets,
    assetPrices: treasuryAssetPrices,
    isFetchingAssets: isFetchingTreasuryAssets,
    refetch: refetchTreasuryAssets,
  } = useTreasuryAssets(daoUrlAddress);

  const {
    assets: liquidPoolAssets,
    assetPrices: liquidPoolAssetPrices,
    isFetchingAssets: isFetchingLiquidPoolAssets,
    refetch: refetchLiquidPoolAssets,
  } = useLiquidPoolAssets(daoUrlAddress);

  const {
    assets: segregateAccountAssets,
    assetPrices: segregateAccountAssetPrices,
    isFetchingAssets: isFetchingSegregateAccountAssets,
    refetch: refetchSegregateAccountAssets,
  } = useSegregateAccountAssets(eoaAddress, daoUrlAddress);

  const treasuryPriceBN = useMemo(() => {
    if (!treasuryAssetPrices?.erc20 || !defaultBaseCurrency) {
      return undefined;
    }

    const priceBN = Object.keys(treasuryAssetPrices.erc20).reduce(
      (result: BigNumber, tokenAddress: string) =>
        treasuryAssetPrices.erc20
          ? result.add(treasuryAssetPrices.erc20[tokenAddress]?.answer || BigNumber.from(0))
          : result,
      BigNumber.from(0)
    );

    return priceBN;
  }, [defaultBaseCurrency, treasuryAssetPrices?.erc20]);

  const liquidPoolPriceBN = useMemo(() => {
    if (!liquidPoolAssetPrices?.erc20 || !defaultBaseCurrency) {
      return undefined;
    }

    const priceBN = Object.keys(liquidPoolAssetPrices.erc20).reduce(
      (result: BigNumber, tokenAddress: string) =>
        liquidPoolAssetPrices.erc20
          ? result.add(liquidPoolAssetPrices.erc20[tokenAddress]?.answer || BigNumber.from(0))
          : result,
      BigNumber.from(0)
    );

    return priceBN;
  }, [defaultBaseCurrency, liquidPoolAssetPrices?.erc20]);

  const segregateAccountPriceBN = useMemo(() => {
    if (!segregateAccountAssetPrices?.erc20 || !defaultBaseCurrency) {
      return undefined;
    }

    const priceBN = Object.keys(segregateAccountAssetPrices.erc20).reduce(
      (result: BigNumber, tokenAddress: string) =>
        segregateAccountAssetPrices.erc20
          ? result.add(segregateAccountAssetPrices.erc20[tokenAddress]?.answer || BigNumber.from(0))
          : result,
      BigNumber.from(0)
    );

    return priceBN;
  }, [defaultBaseCurrency, segregateAccountAssetPrices?.erc20]);

  const vaultPoolOptions: PoolOption[] = useMemo(
    () => [
      {
        id: generatePoolId(AssetSource.TREASURY),
        sourceType: AssetSource.TREASURY,
        label: 'Public Account',
        icon: 'vault',
        address: daoUrlAddress,
        priceInBaseCurrency: treasuryPriceBN,
      },
      {
        id: generatePoolId(AssetSource.LIQUID_POOL),
        sourceType: AssetSource.LIQUID_POOL,
        label: 'Member Aggregate',
        icon: 'team1',
        priceInBaseCurrency: liquidPoolPriceBN,
      },
    ],
    [daoUrlAddress, liquidPoolPriceBN, treasuryPriceBN]
  );

  const personalPoolOptions: PoolOption[] = useMemo(
    () => [
      {
        id: generatePoolId(AssetSource.SEGREGATE),
        sourceType: AssetSource.SEGREGATE,
        label: 'My Account',
        icon: 'member1',
        priceInBaseCurrency: segregateAccountPriceBN,
      },
    ],
    [segregateAccountPriceBN]
  );

  const selectedPoolOption = useMemo(
    () =>
      vaultPoolOptions.find((opt) => opt.id === selectedPoolId) ||
      personalPoolOptions.find((opt) => opt.id === selectedPoolId),
    [personalPoolOptions, selectedPoolId, vaultPoolOptions]
  );

  const handlePoolCardClick = useCallback(
    (id: string): void => {
      navigate(`${generatePath(PATHS.DAO.ASSETS, { daoAddress: daoUrlAddress })}?source=${id}`);
      setSelectedPoolId(id);
    },
    [daoUrlAddress, navigate]
  );

  useEffect(() => {
    if (selectedPoolId === querySourceId) {
      return;
    }

    setSelectedPoolId(querySourceId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [querySourceId]);

  if (!currentDao || isLoading) {
    return <Loading isCenter />;
  }

  if (!isLoading && !currentDao) {
    return <EmptyState statement="Vault does not exist" />;
  }

  return (
    <Wrapper>
      <PoolMenuBar
        selectedPoolId={selectedPoolId}
        vaultPoolOptions={vaultPoolOptions}
        personalPoolOptions={personalPoolOptions}
        onPoolCardClick={handlePoolCardClick}
        treasuryPriceBN={treasuryPriceBN}
        liquidPoolPriceBN={liquidPoolPriceBN}
        segregateAccountPriceBN={segregateAccountPriceBN}
      />
      <PageContent>
        <Header />
        <Content>
          {selectedPoolOption?.sourceType === AssetSource.TREASURY && (
            <PublicAccountSection
              sourceType={selectedPoolOption?.sourceType}
              daoAddress={daoUrlAddress}
              lpAddress={currentDao.data.liquidPool}
              isFetchingAssets={isFetchingTreasuryAssets}
              assets={treasuryAssets}
              assetPrices={treasuryAssetPrices}
              refetchAssets={refetchTreasuryAssets}
            />
          )}
          {selectedPoolOption?.sourceType === AssetSource.LIQUID_POOL && (
            <MemberAggregateSection
              sourceType={selectedPoolOption?.sourceType}
              daoAddress={daoUrlAddress}
              lpAddress={currentDao.data.liquidPool}
              isFetchingAssets={isFetchingLiquidPoolAssets}
              assets={liquidPoolAssets}
              assetPrices={liquidPoolAssetPrices}
              refetchAssets={refetchLiquidPoolAssets}
            />
          )}
          {selectedPoolOption?.sourceType === AssetSource.SEGREGATE && (
            <MyAccountSection
              sourceType={selectedPoolOption?.sourceType}
              daoAddress={daoUrlAddress}
              lpAddress={currentDao.data.liquidPool}
              isFetchingAssets={isFetchingSegregateAccountAssets}
              assets={segregateAccountAssets}
              assetPrices={segregateAccountAssetPrices}
              refetchAssets={refetchSegregateAccountAssets}
            />
          )}
        </Content>
      </PageContent>
    </Wrapper>
  );
}
