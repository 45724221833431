import { useState, MouseEventHandler, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import Icon from 'components/Icon';
import Box from 'components/layout/Box';
import { FieldArray, ActionField, DataModel } from 'components/RowTable/types';
import Text from 'components/Text';
import TableRow from './TableRow';
import { FieldModel } from './types';

interface ContentRowContainerProps {
  isClickable: boolean;
}
const ContentRowContainer = styled.div<ContentRowContainerProps>`
  cursor: ${({ isClickable }): string => (isClickable ? 'pointer' : 'default')};
  width: 100%;
`;

const RowContent = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 10px;
`;

const Cell = styled(Box)`
  display: flex;
  gap: 16px;
  align-items: center;
`;

const ValueWrapper = styled.div`
  flex: 1;
  overflow: hidden;
`;

export const Value = styled(Text).attrs({ category: 'body', weight: 'bold' })`
  display: flex;
  word-wrap: break-word;
`;

export const SubValue = styled(Text).attrs({ category: 'sub-headline' })`
  color: ${({ theme: { color } }) => color.fontSecondary};
`;

const ActionButtons = styled(Box)`
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  align-items: center;
`;

const ActionButton = styled.button`
  background-color: ${({ theme: { color } }): string => color.actionPrimary};
  width: 34px;
  height: 34px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ExpandIcon = styled(Icon)<{ rotateDegree: number }>`
  transform: ${(props): string => `rotate(${props.rotateDegree}deg)`};
`;

const ACTION_ICON_SIZE = '20px';

export default function ContentRow(props: {
  fields: FieldArray;
  actionField?: ActionField;
  onClick?: (data: DataModel, index: number) => void;
  data: DataModel;
  index: number;
  padding?: string;
}): JSX.Element {
  const { actionField, padding } = props;
  const hasActionField = !!actionField;

  const [isExpand, setIsExpand] = useState(false);
  const [valueArray, setValueArray] = useState<React.ReactNode[]>([]);

  const rotateDegree = isExpand ? 180 : 0;

  const onExpandClick: MouseEventHandler<HTMLButtonElement> = (e): void => {
    e.stopPropagation();
    setIsExpand((prevState) => !prevState);
  };

  const onRowClick: MouseEventHandler<HTMLDivElement> = (e): void => {
    e.stopPropagation();
    if (!!props.onClick) {
      props.onClick(props.data, props.index);
    }
  };

  const onEdit: MouseEventHandler<HTMLButtonElement> = (e): void => {
    e.stopPropagation();
    if (!actionField?.onEdit) {
      return;
    }
    actionField.onEdit(props.data, props.index);
  };

  const onRemove: MouseEventHandler<HTMLButtonElement> = (e): void => {
    e.stopPropagation();
    if (!actionField?.onRemove) {
      return;
    }
    actionField.onRemove(props.data, props.index);
  };

  const renderValue = useCallback(
    async (field: FieldModel) => {
      if (!!field.renderValue) {
        const v = await field.renderValue(props.data, props.index);
        return v;
      }
      return props.data[field.key];
    },
    [props.data, props.index]
  );

  const renderValueArray = useCallback(async () => {
    const arr = await Promise.all(
      props.fields.map(async (field) => {
        const v = await renderValue(field);
        return v;
      })
    );
    setValueArray(arr);
  }, [props.fields, renderValue]);

  useEffect(() => {
    renderValueArray();
  }, [props.fields, renderValueArray]);

  return (
    <ContentRowContainer isClickable={!!props.onClick} onClick={onRowClick}>
      <TableRow flexDirection="column" alignItems="center" padding={padding}>
        <RowContent>
          {props.fields.map((field, fieldIdx) => (
            <Cell key={`row-table__field-${props.index}-${fieldIdx}`} width={field.width}>
              {field.renderPreValue && field.renderPreValue(props.data, props.index)}
              <ValueWrapper>
                <Value>{valueArray[fieldIdx]}</Value>
                <SubValue>
                  {field.renderSubValue && <div>{field.renderSubValue(props.data, props.index)}</div>}
                </SubValue>
              </ValueWrapper>
            </Cell>
          ))}
          {hasActionField && (
            <ActionButtons width={actionField.width}>
              {actionField.onEdit && (
                <ActionButton onClick={onEdit}>
                  <Icon icon="edit2" size={ACTION_ICON_SIZE} />
                </ActionButton>
              )}
              {actionField.onRemove && (
                <ActionButton onClick={onRemove}>
                  <Icon icon="delete" size={ACTION_ICON_SIZE} />
                </ActionButton>
              )}
              {actionField.renderAdditionalButtons && actionField.renderAdditionalButtons(props.data, props.index)}
              {actionField.renderExpand && !!actionField.renderExpand(props.data, props.index) && (
                <ActionButton onClick={onExpandClick}>
                  <ExpandIcon icon="dropdown" size={ACTION_ICON_SIZE} rotateDegree={rotateDegree} />
                </ActionButton>
              )}
            </ActionButtons>
          )}
        </RowContent>
        {isExpand && actionField?.renderExpand && actionField?.renderExpand(props.data, props.index)}
      </TableRow>
    </ContentRowContainer>
  );
}
