import { useCallback } from 'react';
import styled from 'styled-components';
import Title from 'components/layout/Title';
import useTokenSettings from 'hooks/useTokenSettings';
import { AdmissionTokenFulfillment, EntryFulfillments } from 'types/EntryRequirements';
import { truncate } from 'utils/stringUtils';
import Requirement from './Requirement';

export interface Props {
  fulfillments: EntryFulfillments;
  hasEnoughDepositAmount?: boolean;
}

const StyledTitle = styled(Title)`
  font-size: 20px;
  line-height: 28px;
  margin-top: 48px;
  margin-bottom: 16px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

export default function JoinRequirements(props: Props): JSX.Element | null {
  const { admissionTokens, minDepositAmount } = props.fulfillments;
  const { getBaseCurrencyDisplaySymbol } = useTokenSettings();

  const getAdmissionTokenDisplayName = useCallback((fulfillment: AdmissionTokenFulfillment) => {
    let name = '';
    if (fulfillment.token?.symbol) {
      name = fulfillment.token?.symbol;
    } else if (fulfillment.token?.name) {
      name = truncate(fulfillment.token?.name, 20);
    }
    if (!!fulfillment.tokenId && Number(fulfillment.tokenId) !== 0) {
      name = `${name} (Token ID: ${fulfillment.tokenId})`;
    }
    return name;
  }, []);

  const hasMinDepositAmountRequirement = minDepositAmount?.isRequired;
  const depositTokens = hasMinDepositAmountRequirement ? minDepositAmount?.depositTokens : [];
  const baseCurrencyAddress = hasMinDepositAmountRequirement ? minDepositAmount.token?.address : '';
  const depositTokenSymbol = getBaseCurrencyDisplaySymbol(baseCurrencyAddress, depositTokens);

  if ((!admissionTokens || admissionTokens.length === 0) && !minDepositAmount?.isRequired) {
    return null;
  }

  return (
    <>
      <StyledTitle>Join Requirements</StyledTitle>
      <Container>
        {admissionTokens?.map((token) => (
          <Requirement key={token.token?.address || ''} isFulfilled={token.isFulfilled}>
            Hold{' '}
            <span>
              {token.formattedAmount} {getAdmissionTokenDisplayName(token)}
            </span>{' '}
            tokens
          </Requirement>
        ))}
        {minDepositAmount?.isRequired && (
          <Requirement isFulfilled={props.hasEnoughDepositAmount}>
            Deposit at least{' '}
            <span>
              {depositTokens.length > 1
                ? `USD ${minDepositAmount.formattedAmount} worth of tokens`
                : `${minDepositAmount.formattedAmount} ${depositTokenSymbol}`}
            </span>
          </Requirement>
        )}
      </Container>
    </>
  );
}
