import { useCallback } from 'react';
import styled from 'styled-components';
import AddressDisplay from 'components/AddressDisplay';
import Icon, { Props as IconProps } from 'components/Icon';
import Loading from 'components/Loading';
import Text from 'components/Text';
import { USDC_BASE_CURRENCY_DISPLAY } from 'constants/token';
import UnicodeCharacter from 'constants/unicodeCharacter';

interface Props {
  isSelected?: boolean;
  id: string;
  title: string;
  icon: IconProps['icon'];
  priceInBaseCurrencyString: string | undefined;
  address?: string;
  onClick: (id: string) => void;
}

const Wrapper = styled.div<{ isSelected: Props['isSelected'] }>`
  border-radius: 8px;
  min-width: 232px;
  min-height: 106px;
  padding: 16px 20px;
  background: ${({ isSelected, theme: { color } }) => (isSelected ? color.backgroundGradient : color.surface)};
  cursor: pointer;
`;

const TitleBar = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

function PriceInUSD({ priceString }: { priceString: Props['priceInBaseCurrencyString'] }): JSX.Element {
  if (priceString === undefined) {
    return <Loading width={30} height={20} />;
  }

  return (
    <Text category="sub-headline" weight="bold">
      {UnicodeCharacter.ALMOST_EQUAL_TO}
      {USDC_BASE_CURRENCY_DISPLAY} {priceString}
    </Text>
  );
}

export default function PoolCard({
  isSelected,
  id,
  icon,
  title,
  priceInBaseCurrencyString,
  address,
  onClick,
}: Props): JSX.Element {
  const onCardClick = useCallback(() => onClick(id), [id, onClick]);

  return (
    <Wrapper isSelected={isSelected} onClick={onCardClick}>
      <TitleBar>
        <Text category="headline-2" weight="bold">
          {title}
        </Text>
        <Icon icon={icon} />
      </TitleBar>
      {/* TODO Update price in usd logic to show the correct value */}
      {priceInBaseCurrencyString && <PriceInUSD priceString={priceInBaseCurrencyString} />}
      {address && <AddressDisplay address={address} isEllipsis isAvatarShown={false} isBorderShown={false} />}
    </Wrapper>
  );
}
