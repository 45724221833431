import styled from 'styled-components';
import { space, flexbox, layout, SpaceProps, FlexboxProps, LayoutProps } from 'styled-system';

export interface Props extends React.HTMLAttributes<HTMLDivElement>, SpaceProps, FlexboxProps, LayoutProps {}

// FIXME: This component should be removed but we are using styled system in ContentRow
// just leave it first until reamp ContentRow
const Box = styled.div<Props>`
  ${space}
  ${flexbox}
  ${layout}
  word-wrap: break-word;
`;

export default Box;
