import { yupResolver } from '@hookform/resolvers/yup';
import { useForm as useHookForm, UseFormProps, FieldValues } from 'react-hook-form';
import { AnyObjectSchema } from 'yup';
import useHandlingFormFocus from './useHandlingFormFocus';

interface UseHookFormProps<TFieldValues> extends UseFormProps<TFieldValues, any> {
  schema?: AnyObjectSchema;
}

export default function useForm<FormType extends FieldValues>(config: UseHookFormProps<FormType> = {}) {
  const { schema, resolver, ...others } = config;
  const form = useHookForm<FormType>({
    resolver: schema ? yupResolver(schema, {}) : resolver,
    mode: 'onBlur',
    ...others,
  });

  const { setRef, scrollToInput } = useHandlingFormFocus(form.formState.errors);

  return { ...form, setRef, scrollToInput };
}
