import { Adam, TokenInfoResponse } from '@adam-vault/adam-sdk';
import { SupportedOutflowTokensType, OUTFLOW_TOKENS_TYPE } from 'constants/budgetParam';

export interface FromTokensToTokenStringParams {
  budgetLimit?: string;
  outflowTokens: SupportedOutflowTokensType | string;
  hasAdditionalTokens: boolean;
  additionalTokens: string;
  ERC20MemberTokenSymbol?: string;
}

const MAX_OUTFLOW_TOKENS_DISPLAY_NUMBER = 3;

export const fromTokensToTokenString = (params: FromTokensToTokenStringParams): string => {
  const { budgetLimit, outflowTokens, hasAdditionalTokens, additionalTokens, ERC20MemberTokenSymbol } = params;
  // For tokens, convert the outflowTokens string to symbols separated by ', '
  const tokens =
    outflowTokens === SupportedOutflowTokensType.MEMBER_TOKEN
      ? `${OUTFLOW_TOKENS_TYPE[SupportedOutflowTokensType.MEMBER_TOKEN].label} (${ERC20MemberTokenSymbol})`
      : outflowTokens.replaceAll(/(,)(([^ ])| +)/g, ', $3');
  const allTokens = hasAdditionalTokens ? [tokens, additionalTokens].join(', ') : tokens;
  if (!budgetLimit) {
    return allTokens;
  }
  return `${budgetLimit} ${allTokens}`;
};

export const fromTokenAddressToSymbol = async (
  adam: Adam,
  supportedTokens: TokenInfoResponse[] | undefined,
  tokenAddress?: string
): Promise<string> => {
  if (!tokenAddress || !supportedTokens) {
    return '';
  }

  const mappedToken = supportedTokens.find((token) => tokenAddress.toLowerCase() === token.address.toLowerCase());
  if (mappedToken) {
    return mappedToken.symbol;
  }

  const token = adam.loadToken(tokenAddress);
  const symbol = await token.symbol();

  return symbol || tokenAddress;
};

export const fromTokenAddressesToSymbols = async (
  adam: Adam,
  supportedTokens: TokenInfoResponse[] | undefined,
  tokenAddresses?: string[]
): Promise<string[]> => {
  if (!tokenAddresses) {
    return [];
  }

  return Promise.all(
    tokenAddresses.map(async (tokenAddress) => fromTokenAddressToSymbol(adam, supportedTokens, tokenAddress))
  );
};

export const generateSymbol = (name: string): string => {
  const symbolTokens = (name || '').split(' ').slice(0, 6);
  const symbol =
    symbolTokens.length === 1
      ? name?.slice(0, 6).toUpperCase()
      : symbolTokens.reduce((sym, nameToken) => {
          const trimmedText = nameToken.trim();
          return sym + (trimmedText ? trimmedText[0].toUpperCase() : '');
        }, '');
  return symbol;
};

export const isSupportedToken = (supportedTokens: TokenInfoResponse[] | undefined, address: string): boolean => {
  if (!supportedTokens) {
    return false;
  }

  return (
    supportedTokens.findIndex((token: TokenInfoResponse) => token.address.toLowerCase() === address.toLowerCase()) > -1
  );
};

export const fromTransferTokensToTransferTokenDisplay = (
  transferTokens: string,
  fullDisplayMaxLimit: number = MAX_OUTFLOW_TOKENS_DISPLAY_NUMBER
): string => {
  const tokensArray = transferTokens.split(',');
  if (tokensArray.length > fullDisplayMaxLimit) {
    return `${tokensArray.slice(0, fullDisplayMaxLimit).join(', ')}...${tokensArray.length - fullDisplayMaxLimit}+`;
  }
  return tokensArray.join(', ');
};
