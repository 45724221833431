import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import adamLogo from 'assets/images/adam_logo.svg';
import adamNameLogo from 'assets/images/adam_name.svg';

import Icon from 'components/Icon';
import ExternalLink from 'components/layout/ExternalLink';
import Img from 'components/layout/Img';
import externalUrl from 'constants/externalUrl';
import PATHS from 'constants/paths';

export interface CollapsedMenuProps {
  isCollapsed?: boolean;
}

export const collapseMenuCss = css<CollapsedMenuProps>`
  ${({ isCollapsed }) =>
    isCollapsed
      ? css`
          height: 0;
          width: 0;
          opacity: 0;
        `
      : css`
          height: auto;
          width: auto;
          opacity: 1;
        `}
  overflow: hidden;
  transition: opacity 0.5s ease, width 0.5s ease, height 0.5s ease;
`;

export interface Props extends CollapsedMenuProps {}

const Wrapper = styled.div<CollapsedMenuProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  justify-self: flex-end;

  margin-top: auto;
  flex-direction: ${({ isCollapsed }) => (isCollapsed ? 'column' : 'row')};
`;

const AdamNameLogoWrapper = styled.div<CollapsedMenuProps>`
  ${collapseMenuCss}
`;

const LinkWrapper = styled.div<CollapsedMenuProps>`
  display: flex;
  column-gap: ${({ isCollapsed }) => (isCollapsed ? '0' : '12px')};
  margin-bottom: ${({ isCollapsed }) => (isCollapsed ? '20px' : '0')};
  align-items: center;
  justify-content: center;
`;

export default function SidebarFooter(props: Props): JSX.Element {
  const { isCollapsed } = props;
  return (
    <Wrapper isCollapsed={isCollapsed}>
      <Link to={PATHS.HOME}>
        <LinkWrapper isCollapsed={isCollapsed}>
          <Img src={adamLogo} />
          <AdamNameLogoWrapper isCollapsed={isCollapsed}>
            <Img src={adamNameLogo} />
          </AdamNameLogoWrapper>
        </LinkWrapper>
      </Link>

      <ExternalLink href={externalUrl.ADAM_DOCS}>
        <Icon icon="docs" />
      </ExternalLink>
    </Wrapper>
  );
}
