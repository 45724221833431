import { SourceType, TokenData } from '@adam-vault/adam-sdk';
import { useCallback, useMemo, useState, useEffect } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Loading from 'components/Loading';
import TxStatusModal from 'components/TxStatusModal';
import { AssetSource } from 'constants/asset';
import { GlobalModal } from 'constants/globalModal';
import PATHS from 'constants/paths';
import TxStatus from 'constants/txStatus';
import useAdam, { useAdamMemo } from 'hooks/useAdam';
import useCurrentDao from 'hooks/useCurrentDao';
import useDaoDepositTokens from 'hooks/useDaoDepositTokens';
import useGlobalModal from 'hooks/useGlobalModal';
import useTxSender from 'hooks/useTxSender';
import useWeb3 from 'hooks/useWeb3';
import { generatePoolId } from 'pages/DaoAssets';
import { fromSecondsToFullDHMS, TimeUnitsEnum } from 'utils/datetimeUtils';
import web3Utils from 'utils/web3Utils';
import DepositInfoForm from './DepositInfoForm';
import ReviewForm from './ReviewForm';
import { DepositFormMode, DepositInfoFormData } from './types';

export interface Props {
  daoAddress: string;
  mode?: DepositFormMode;
  onHasEnoughDepositAmountChange?: (hasEnoughDepositAmount: boolean) => void;
  isOverMembersCountLimit?: boolean;
}

enum DepositFormStep {
  DEPOSIT_INFO,
  REVIEW,
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 48px 52px;
  background-color: ${({ theme: { color } }): string => color.panel};
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function DepositFormSection(props: Props): JSX.Element {
  const {
    isMemberStateLoading: isDaoMemberLoading,
    isCurrentEoaAMember: isDaoMember,
    reloadTotalPrice,
    reloadIsCurrentEoaAMember,
  } = useCurrentDao();
  const [currentStep, setCurrentStep] = useState<DepositFormStep>(DepositFormStep.DEPOSIT_INFO);
  const [formData, setFormData] = useState<DepositInfoFormData | undefined>(undefined);
  const [onConfirmTransactionSuccess, setOnConfirmTransactionSuccess] = useState(() => () => {});
  const navigate = useNavigate();
  const { mode = DepositFormMode.DEPOSIT } = props;

  const [[lockDays, baseCurrency, minDepositAmount, liquidPoolAddress], { isLoading: isDaoDataLoading }] = useAdamMemo<
    [number, TokenData | undefined, string, string]
  >(
    async (adam) => {
      const daoEntity = adam.loadDao(props.daoAddress);
      const baseCurrencyEntity = await daoEntity.loadBaseCurrency();
      const loadedMinDepositAmount = web3Utils.fromUnit(
        await daoEntity.minDepositAmount(),
        await baseCurrencyEntity.decimals()
      );
      const liquidPool = await daoEntity.liquidPool();
      return Promise.all([
        fromSecondsToFullDHMS((await daoEntity.locktime()).toString(), TimeUnitsEnum.day),
        baseCurrencyEntity.fetch(),
        loadedMinDepositAmount,
        liquidPool,
      ]);
    },
    [props.daoAddress],
    [0, undefined, '0', '']
  );

  const assetPools = useMemo(
    () => ({
      [SourceType.LIQUID_POOL]: {
        name: 'Member Aggregate',
        address: liquidPoolAddress,
        type: SourceType.LIQUID_POOL,
      },
      [SourceType.TREASURY]: {
        name: 'Vault Public Account',
        address: props.daoAddress,
        type: SourceType.TREASURY,
      },
    }),
    [liquidPoolAddress, props.daoAddress]
  );

  const { isConnecting, signer } = useWeb3();

  const { depositTokens, loading: isDepositTokensLoading } = useDaoDepositTokens(props.daoAddress);

  const isLoading = isDaoMemberLoading || isConnecting || isDepositTokensLoading || isDaoDataLoading;

  const generateDepositParams = useCallback(
    (data?: DepositInfoFormData) => {
      if (!data || !assetPools) {
        return null;
      }

      const destination = Object.values(assetPools).find((item) => item.address === data.toAddress)?.type;

      if (!destination) {
        return null;
      }

      return {
        destination,
        token: data.token,
        amount: data.amount,
      };
    },
    [assetPools]
  );

  const adamEntity = useAdam();

  const [gasFee, { refetch }] = useAdamMemo(
    async (adam) => {
      const params = generateDepositParams(formData);
      if (!params || !signer) {
        return '';
      }
      const daoEntity = await adam.loadDao(props.daoAddress);
      const estGasPrice = await daoEntity.deposit(params, { customData: { estimateGas: true } });
      return web3Utils.fromWei(estGasPrice);
    },
    [generateDepositParams, props.daoAddress, signer, formData],
    ''
  );

  const { submitTx, txStatus, errorMessage, loading: isSubmittingDeposit } = useTxSender();

  const { closeModal: closeJoinDaoAndDepositModal } = useGlobalModal(GlobalModal.JOIN_DAO_AND_DEPOSIT);

  useEffect(() => {
    if (txStatus === TxStatus.COMPLETED) {
      reloadTotalPrice();
    }
  }, [reloadTotalPrice, txStatus]);

  const onDepositInfoFormNext = useCallback(
    (data: DepositInfoFormData) => {
      setFormData(data);
      refetch();
      setCurrentStep(DepositFormStep.REVIEW);
    },
    [refetch, setFormData]
  );

  const onApproveErc20Token = useCallback(
    async (data: DepositInfoFormData) => {
      setFormData(data);
      const tokenEntity = adamEntity.loadToken(data.token);
      setOnConfirmTransactionSuccess(() => () => {});
      const approvalTxStatus = await submitTx(
        tokenEntity.approve({
          executor: data.toAddress,
          amount: data.amount,
        })
      );
      if (approvalTxStatus === TxStatus.COMPLETED) {
        refetch();
        setCurrentStep(DepositFormStep.REVIEW);
      }
    },
    [adamEntity, submitTx, refetch, setFormData]
  );

  const onReviewFormBack = useCallback(() => {
    setCurrentStep(DepositFormStep.DEPOSIT_INFO);
  }, []);

  const onConfirmDeposit = useCallback(async () => {
    const params = generateDepositParams(formData);

    if (!params) {
      return;
    }

    const daoEntity = adamEntity.loadDao(props.daoAddress);

    setOnConfirmTransactionSuccess(() => () => {
      switch (mode) {
        case DepositFormMode.DEPOSIT:
          const assetPath = `${generatePath(PATHS.DAO.ASSETS, { daoAddress: props.daoAddress })}?source=${
            formData?.toAddress === assetPools[SourceType.LIQUID_POOL].address
              ? generatePoolId(AssetSource.LIQUID_POOL)
              : generatePoolId(AssetSource.TREASURY)
          }`;
          navigate(assetPath);
          break;
        case DepositFormMode.JOIN_AND_DEPOSIT:
          const userAliasFormPath = generatePath(PATHS.DAO.SET_USER_ALIAS, { daoAddress: props.daoAddress });
          navigate(userAliasFormPath);
          closeJoinDaoAndDepositModal();
          break;
      }
    });

    const result = await submitTx(daoEntity.deposit(params));

    if (result === TxStatus.COMPLETED) {
      reloadIsCurrentEoaAMember();
    }
  }, [
    adamEntity,
    assetPools,
    closeJoinDaoAndDepositModal,
    formData,
    generateDepositParams,
    mode,
    navigate,
    props.daoAddress,
    submitTx,
    reloadIsCurrentEoaAMember,
  ]);

  const currentForm = useMemo(() => {
    if (!assetPools) {
      return null;
    }

    switch (currentStep) {
      case DepositFormStep.DEPOSIT_INFO:
        return (
          <DepositInfoForm
            formData={formData}
            baseCurrency={baseCurrency}
            minDepositAmount={minDepositAmount}
            depositTokens={depositTokens}
            isDaoMember={Boolean(isDaoMember)}
            assetPools={assetPools}
            lockDays={lockDays}
            onNext={onDepositInfoFormNext}
            onApproveErc20={onApproveErc20Token}
            daoAddress={props.daoAddress}
            isLoading={isSubmittingDeposit}
            // for join dao form
            mode={mode}
            isOverMembersCountLimit={props.isOverMembersCountLimit}
            onHasEnoughDepositAmountChange={props.onHasEnoughDepositAmountChange}
          />
        );
      case DepositFormStep.REVIEW:
        return (
          <ReviewForm
            formData={formData}
            baseCurrency={baseCurrency}
            depositTokens={depositTokens}
            assetPools={assetPools}
            lockDays={lockDays}
            gasFee={gasFee}
            isLoading={isSubmittingDeposit}
            onBack={onReviewFormBack}
            onDeposit={onConfirmDeposit}
            // for join dao form
            mode={mode}
            isOverMembersCountLimit={props.isOverMembersCountLimit}
          />
        );
    }
  }, [
    depositTokens,
    assetPools,
    baseCurrency,
    currentStep,
    formData,
    isDaoMember,
    isSubmittingDeposit,
    lockDays,
    gasFee,
    minDepositAmount,
    mode,
    onApproveErc20Token,
    onConfirmDeposit,
    onDepositInfoFormNext,
    onReviewFormBack,
    props.daoAddress,
    props.isOverMembersCountLimit,
    props.onHasEnoughDepositAmountChange,
  ]);

  return (
    <Container>
      {isLoading ? (
        <LoadingWrapper>
          <Loading isCenter />
        </LoadingWrapper>
      ) : (
        currentForm
      )}
      <TxStatusModal txStatus={txStatus} errorMessage={errorMessage} onConfirm={onConfirmTransactionSuccess} />
    </Container>
  );
}
