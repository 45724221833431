import { SourceType } from '@adam-vault/adam-sdk';

export interface DepositInfoFormData {
  toAddress: string;
  token: string;
  amount: string;
  amountInBaseCurrency: string;
}

export type AssetPoolInfo = {
  type: SourceType;
  name: string;
  address: string;
};

export type AssetPools = {
  [K in SourceType]: AssetPoolInfo;
};

export enum DepositFormMode {
  DEPOSIT,
  JOIN_AND_DEPOSIT,
}
