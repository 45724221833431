import { useState, useEffect } from 'react';

interface Size {
  width: number | undefined;
  height: number | undefined;
}

// Hook
export default function useWindowSize(): Size {
  const [windowSize, setWindowSize] = useState<Size>({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    let raf: number | null = null;
    const calcWindowSize = (): void => {
      if (raf) {
        return;
      }
      raf = requestAnimationFrame(() => {
        raf = null;
        if (windowSize.width !== window.innerWidth || windowSize.height !== window.innerHeight) {
          setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
          });
        }
      });
    };
    window.addEventListener('resize', calcWindowSize);
    calcWindowSize();
    return () => window.removeEventListener('resize', calcWindowSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return windowSize;
}
