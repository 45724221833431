import { useMemo } from 'react';
import useQuery, { ReturnType as UseQueryReturnType, Options } from 'hooks/useQuery';

export type ReturnType<TQueryType> = UseQueryReturnType<TQueryType>;

const DEFAULT_POLL_INTERVAL = 10000;

export default function usePollingQuery<TQueryType = any>(
  query: string,
  optionsFromProps?: Options
): ReturnType<TQueryType> {
  const options = useMemo(() => {
    if (!optionsFromProps || !!optionsFromProps.pollInterval) {
      return optionsFromProps;
    }

    return {
      ...(optionsFromProps || {}),
      pollInterval: DEFAULT_POLL_INTERVAL,
    };
  }, [optionsFromProps]);

  return useQuery(query, options);
}
