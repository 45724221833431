export enum SupportedEntryTokenType {
  MEMBER_TOKEN = 'member',
  EXISTING_TOKEN = 'existing',
}

export const ENTRY_TOKEN_TYPE = {
  [SupportedEntryTokenType.MEMBER_TOKEN]: {
    type: SupportedEntryTokenType.MEMBER_TOKEN,
    label: 'Member Token',
  },
  [SupportedEntryTokenType.EXISTING_TOKEN]: {
    type: SupportedEntryTokenType.EXISTING_TOKEN,
    label: 'Existing Token',
  },
};

export enum TokenStandard {
  ERC20 = 'ERC20',
  ERC721 = 'ERC721',
  ERC1155 = 'ERC1155',
}

export enum StableCoinSymbolWithoutNative {
  USDC = 'USDC',
  USDT = 'USDT',
  DAI = 'DAI',
}

export enum ReapTokenSymbol {
  USDC = 'USDC',
  USDT = 'USDT',
  DAI = 'DAI',
}

export const DEFAULT_BASE_CURRENCY_SYMBOL = StableCoinSymbolWithoutNative.USDC;

export const USDC_BASE_CURRENCY_DISPLAY = 'USD';
