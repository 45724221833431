import { Adam, TokenType } from '@adam-vault/adam-sdk';
import { Web3 } from 'adam-frontend-shared';
import { ethers } from 'ethers';
import * as yup from 'yup';
import { StringSchema } from 'yup';
import { MixedSchema } from 'yup/lib/mixed';
import { checkIsEthereumAddress, isValidAddressInput } from 'utils/web3Utils';

export const ethereumAddress = (message?: string): MixedSchema =>
  yup
    .mixed()
    // unlike string.required, empty strings in mixed.required are not considered as 'missing' values
    // this transform ensures the validation works as intended when chaining .required()
    .transform((value) => (!value ? undefined : value))
    .test({
      test: checkIsEthereumAddress,
      message: message || 'It must be an address',
    });

export const ethereumAddressesString = (message?: string): StringSchema =>
  yup.string().test(
    'ethereumAddressesString',
    message || 'It must be an address or addresses separated by comma(,)',
    (value) =>
      value
        ?.split(',')
        .map((item: string) => item.trim())
        .every(checkIsEthereumAddress) || false
  );

export const ERC20Address = (adam: Adam, message?: string): MixedSchema =>
  ethereumAddress().test('check is 20', message ?? 'The token address is not ERC20', async (value) => {
    if (!value || !checkIsEthereumAddress(value)) {
      return true;
    }

    const tokenEntity = adam.loadToken(value);
    return (await tokenEntity.balanceOf(ethers.constants.AddressZero)).gte(0);
  });

export const ERC721Address = (adam: Adam, message?: string): MixedSchema =>
  ethereumAddress().test('check is 721', message ?? 'The token address is not ERC721', async (value) => {
    if (!value || !checkIsEthereumAddress(value)) {
      return true;
    }

    try {
      const tokenEntity = adam.loadToken(value);
      return (await tokenEntity.type()) === TokenType.ERC721;
    } catch (err) {
      return false;
    }
  });

export const votableToken = (adam: Adam, message?: string): MixedSchema =>
  ethereumAddress().test(
    'check is votable token',
    message ?? 'The token address is not an votable token',
    async (value) => {
      try {
        const tokenEntity = adam.loadToken(value);
        return await tokenEntity.isVotable();
      } catch (err) {
        return false;
      }
    }
  );

export const validAddressInput = (provider: Web3['provider'], message?: string): StringSchema =>
  yup
    .string()
    .test(
      'isValidAddressInput',
      message || 'It must be either an Ethereum address or a registered ENS domain name',
      async (value) => isValidAddressInput(value, provider)
    );

export const validAddressesInput = (provider: Web3['provider'], message?: string): StringSchema =>
  yup
    .string()
    .test(
      'isValidAddressInput',
      message || 'It must be address(es) / registered ENS domain name(s) separated by comma(,)',
      async (value) => {
        const inputs = value?.split(',').map((input: string) => input.trim()) || [];

        for await (const input of inputs) {
          const isValid = await isValidAddressInput(input, provider);
          if (!isValid) {
            return false;
          }
        }
        return true;
      }
    );
